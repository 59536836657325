<script setup lang="ts">
import {onMounted, onUnmounted, ref} from 'vue';
import {eventBus} from '@/Shared/eventBus';

const show = ref(false);
const processing = ref(false);

const showModal = () => {
    show.value = true;
};

const closeModal = () => {
    processing.value = true;
    setTimeout(() => {
        show.value = false;
        processing.value = false;
    }, 100);
};

onMounted(() => {
    eventBus.on('show-timeout-modal', showModal);
});

onUnmounted(() => {
    eventBus.off('show-timeout-modal', showModal);
});
</script>

<template>
    <div>
        <transition>
            <div v-if="show" class="fixed inset-0 top-0 left-0 right-0 bottom-0 z-100 w-full h-screen">
                <div class="fixed inset-0 top-0 left-0 right-0 bottom-0 z-75 w-full h-screen bg-black opacity-60"/>
                <div class="fixed z-100 flex justify-center items-center h-full md:left-1/2 md:ml-[-215px]">
                    <div class="bg-white py-8 px-6 mx-4 rounded-lg md:w-[400px]">
                        <h2 class="headline headline-xl">
                            Das hat zu lange gedauert! 🐌
                        </h2>

                        <p class="body body-m mb-6">
                            Es sieht so aus, als hätte die Anfrage etwas länger gedauert. Bitte überprüfe deine
                            Internetverbindung oder versuche es später noch einmal.
                        </p>

                        <div class="grid grid-cols-2 gap-2">
                            <a href="mailto:support@mi-boda.com" class="w-full button button-secondary">
                                Hilfe?
                            </a>

                            <button class="w-full button button-primary" type="button" @click="closeModal">
                                <svg v-if="processing"
                                     class="animate-spin h-3 w-3 text-white absolute left-auto right-auto"
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="#893e3e"></path>
                                </svg>
                                <span :class="{ 'invisible' : processing }">
                  Verstanden
                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
