<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useMixin} from "@/mixins";

const {isSameAppVersion, isMinimumAppVersion, __, address, getAddress, getDirections} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});
</script>

<template>
    <template
        v-if="isSameAppVersion(card, 1) || (isMinimumAppVersion(card, 2) && card.blocks.find(x => x.type === 'arrival'))"
    >
        <template v-if="card.user.id !== 7761">
            <div class="w-full py-12 px-4 mx-auto text-center" v-bind="$attrs">
                <h2 class="mb-2">{{ __('invitation.journey') }}</h2>

                <p class="mb-0" v-html="address(getAddress(card))"/>

                <div class="mt-6 mb-[40px]">
                    <a
                        :href="'http://maps.google.de/maps?f=q&hl=de&q=' + encodeURIComponent(getAddress(card))"
                        class="button button-secondary"
                        target="_blank"
                    >
                        {{ __('invitation.open_in_google_maps') }}
                    </a>
                </div>

                <template
                    v-if="card.blocks.find(x => x.type === 'directions') || card.blocks.find(x => x.type === 'arrival')?.data['description']"
                >
                    <div class="w-3/4 mx-auto text-center relative md:w-[325px]">
                        <h3>{{ __('invitation.directions') }}</h3>
                        <p v-html="getDirections(card)"/>
                    </div>
                </template>
            </div>
        </template>

        <template v-if="card.user.id === 7761">
            <div class="w-full py-12 px-4 mx-auto text-center" v-bind="$attrs">
                <h2 class="mb-2">{{ __('invitation.journey') }}</h2>

                <template v-for="schedule in card.wedding_schedules">
                    <h3>{{ __('invitation.wedding_schedule.' + schedule.type) }}</h3>
                    <p class="mb-0" v-html="address(schedule.location)"/>
                    <div class="mt-6 mb-[40px]">
                        <a
                            :href="'http://maps.google.de/maps?f=q&hl=de&q=' + encodeURIComponent(schedule.location)"
                            class="button button-secondary"
                            target="_blank"
                        >
                            {{ __('invitation.open_in_google_maps') }}
                        </a>
                    </div>
                </template>

                <template
                    v-if="card.blocks.find(x => x.type === 'directions') || card.blocks.find(x => x.type === 'arrival')?.data['description']"
                >
                    <div class="w-3/4 mx-auto text-center relative md:w-[325px]">
                        <h3>{{ __('invitation.directions') }}</h3>
                        <p v-html="getDirections(card)"/>
                    </div>
                </template>
            </div>
        </template>
    </template>
</template>

<style scoped lang="scss">
div {
    @apply mx-auto text-center;
}
</style>
