<script setup lang="ts">
import {ref} from 'vue';
import vClickOutside from 'click-outside-vue3';

const visible = ref(false);

function onClickOutside() {
    visible.value = false;
}

function toggle() {
    visible.value = !visible.value;
    setTimeout(() => {
        visible.value = false;
    }, 10000);
}
</script>

<template>
    <div v-click-outside="onClickOutside" class="relative">
        <img class="block ml-1 cursor-pointer" src="../../images/icons/info.svg" @click="toggle"/>

        <transition name="fade">
            <div
                v-show="visible"
                class="triangle absolute z-50 shadow-lg rounded-lg p-3 pb-0 bg-white w-[260px] ml-[-65px] bottom-8">
                <slot/>
            </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.triangle {
    @apply block;

    :after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #fff;
        bottom: -8px;
        left: 68px;
    }
}
</style>
