<script setup lang="ts">
import {defineProps} from 'vue';
import IconLink from '@/Shared/IconLink.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <div class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center justify-between">
        <div class="w-10/12">
            <ul class="m-0 w-full">
                <li
                    v-for="guest in invitation.guests"
                    :key="guest.id"
                    class="headline headline-s w-full inline-flex justify-between items-center pb-2 mb-2 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0"
                >
                    <span class="w-3/8">{{ guest.name }}</span>
                    <div v-if="guest.selected_food" class="body body-s text-raven-700 flex gap-2">
                        {{ guest.selected_food }}
                        <img src="../../../../images/icons/alert.svg" alt="alert.svg" v-if="guest.food_notice"/>
                    </div>

                    <div>
                        <img
                            v-if="guest.feedback === true"
                            class="w-[24px]"
                            src="../../../../images/feedback-yes.svg"
                            alt="feedback-yes.svg"
                        />
                        <img
                            v-if="guest.feedback === false"
                            class="w-[24px]"
                            src="../../../../images/feedback-no.svg"
                            alt="feedback-no.svg"
                        />
                        <img
                            v-if="guest.feedback === null"
                            class="w-[24px]"
                            src="../../../../images/feedback-unknown.svg"
                            alt="feedback-unknown.svg"
                        />
                    </div>
                </li>
            </ul>

            <div v-if="invitation.comment" class="mt-2 pt-2 border-t-2 border-flower-100">
                <span class="headline headline-s">Kommentar</span>
                <p class="body body-s" v-html="invitation.comment"></p>
            </div>
        </div>

        <div class="inline-flex items-center ml-8">
            <IconLink :href="route('app.feedback.show', { card: card.slug, invitation: invitation.slug })"
                      class="w-[24px] h-[24px]">
                <template v-slot:icon>
                    <img src="../../../../images/icons/eye-red.svg"/>
                </template>
            </IconLink>
        </div>
    </div>
</template>
