<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {useModal} from "momentum-modal";

const {show, close, redirect} = useModal();

const props = defineProps({
    disableClose: {
        type: Boolean,
    }
});
</script>

<template>
    <TransitionRoot appear as="template" :show="show">
        <Dialog as="div" class="relative z-100" @close="close">
            <TransitionChild
                @after-leave="redirect"
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 max-w-[519px] mx-auto">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel class="relative w-full bg-[#F2F1F0] rounded-lg transition-all">

                            <img src="../../../../../images/feedback/close.svg" alt="close.png"
                                 class="absolute left-4 top-[20px] cursor-pointer" @click="close"
                                 v-if="!disableClose"/>

                            <DialogTitle as="h3"
                                         class="font-montserrat font-semibold text-[#303C3D] text-sm text-center py-[22px]">
                                <slot name="title"/>
                            </DialogTitle>
                            <slot/>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
