<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import Card from '@/Shared/Card.vue';
import DesignCard from '@/Pages/App/Settings/Design/DesignCard.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    designs: {
        type: Array as PropType<Array<App.Models.Design>>,
        required: true,
    },
    selectedDesign: {
        type: String,
        required: false,
    },
    selectedTheme: {
        type: String,
        required: false,
    },
});

const form = useForm({
    designs: props.designs,
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Welches Design wählt Ihr?"/>
    <Layout class="md:bg-flower-50">
        <Card>
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s mb-6 md:hero-m">Welches Design wählt Ihr?</h1>

                <div class="grid lg:grid-cols-3 lg:gap-6">
                    <DesignCard
                        v-for="design in designs"
                        :key="design.id"
                        :card="card"
                        :design="design"
                        :card-type="card.type"
                        :selected-design="selectedDesign"
                        :selected-theme="selectedTheme"
                    />
                </div>
            </template>
        </Card>
    </Layout>
</template>
