<script setup lang="ts">
import {defineProps} from 'vue';
import IconLink from '@/Shared/IconLink.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <div class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center">
        <ul class="m-0 w-full">
            <div
                v-for="guest in invitation.guests.filter(x => !x.is_additional_guest)"
                :key="guest.id"
                class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0"
            >
                {{ guest.name }}
            </div>

            <div
                v-if="invitation.additional_guests"
                class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0"
            >
                ggf. Begleitung
            </div>
        </ul>

        <div class="inline-flex ml-8">
            <IconLink :href="route('app.guest_list.edit', { card: card.slug, invitation: invitation.slug })"
                      class="w-[24px]">
                <template v-slot:icon>
                    <img src="../../../../images/icons/edit-2.svg"/>
                </template>
            </IconLink>
        </div>
    </div>
</template>
