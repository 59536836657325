<script setup lang="ts">
import {computed, defineProps, ref} from 'vue';
import {Link, useForm} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";

const {__, generateRoute, getColorByThemeAndOption} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({
    guests: props.invitation.guests,
});

const guests = ref(props.invitation.guests);

const accept = (guest: App.Models.Invitation.Guest, index: number) => {
    guest.feedback = true;
    form.guests[index].feedback = true;
};

const cancel = (guest: App.Models.Invitation.Guest, index: number) => {
    guest.feedback = false;
    form.guests[index].feedback = false;
};

const feedbackMissing = computed(() => {
    return guests.value.some(guest => guest.feedback === null);
});

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.personal.feedback', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    }), {
        preserveScroll: true,
    });
};

const generateEditGuestRoute = (key: number) => {
    return generateRoute('app.invitation.feedback.personal.edit_guest', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug,
        'number': key
    });
};

const generateAddGuestRoute = () => {
    return generateRoute('app.invitation.feedback.personal.add_guest', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    });
};
</script>

<template>
    <Modal>
        <template #title>
            {{ __('invitation.feedback.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4">
                    <h2 class="font-montserrat font-semibold text-base">
                        {{ __('invitation.feedback.answer') }}
                    </h2>

                    <div class="mt-8">
                        <template v-for="(guest, key) in guests" :key="key">
                            <div class="mb-8">
                                <div
                                    class="flex justify-between mb-3 font-montserrat font-semibold text-sm text-[#303C3D]">
                                    <div>
                                        {{ guest.name }}
                                    </div>

                                    <template v-if="guest.is_additional_guest">
                                        <Link :href="generateEditGuestRoute(key)" class="edit-guest-link">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                 viewBox="0 0 24 24" fill="none" stroke="#303C3D" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 class="feather feather-edit-2">
                                                <path
                                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                            </svg>
                                        </Link>
                                    </template>
                                </div>

                                <div class="grid grid-cols-2 justify-center border rounded-md"
                                     :style="{ borderColor: getColorByThemeAndOption(card.theme, 'feedback') }">
                                    <div :class="[
                                            'cursor-pointer',
                                            'font-semibold',
                                            'text-center',
                                            'border-r',
                                            'py-2',
                                            'hover:border-[#6A7171]',
                                            'hover:bg-[#6A7171]',
                                            'hover:text-[#FFFFFF]',
                                            { 'border-[#6A7171] bg-[#6A7171] text-[#FFFFFF]': guest.feedback === false }
                                        ]"
                                         :style="{ color: getColorByThemeAndOption(card.theme, 'feedback') }"
                                         @click="cancel(guest, key)">
                                        {{ __('invitation.feedback.answer.no') }}
                                    </div>

                                    <div :class="[
                                            'cursor-pointer',
                                            'font-semibold',
                                            'text-center',
                                            'py-2',
                                            'hover:border-[${getColorByThemeAndOption(card.theme, \'feedback\')}]',
                                            'hover:bg-[${getColorByThemeAndOption(card.theme, \'feedback\')}]',
                                            'hover:text-[#FFFFFF]',
                                            { 'border-[#6A7171] text-[#FFFFFF]': guest.feedback === true }
                                        ]"
                                         :style="{ color: getColorByThemeAndOption(card.theme, 'feedback'), backgroundColor: guest.feedback ? getColorByThemeAndOption(card.theme, 'feedback') : 'inherit' }"
                                         @click="accept(guest, key)">
                                        {{ __('invitation.feedback.answer.yes') }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="mt-6" v-if="invitation.additional_guests">
                            <Link :href="generateAddGuestRoute()"
                                  class="font-montserrat font-semibold text-sm underline md:no-underline hover:underline">
                                + {{ __('invitation.feedback.add_another_guest') }}
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="mt-12">
                    <div v-if="$page.props.progress" class="w-full h-[3px]"
                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'backgroundWide') }">
                        <div
                            :style="{ width: `${$page.props.progress}%`, backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5">
                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :disabled="feedbackMissing"
                                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                                         type="submit">
                            {{ __('invitation.feedback.button.next') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
