<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ConfirmationDialog from '@/Shared/ConfirmationDialog.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    foods: {
        type: Array,
        required: true,
    },
    exists: {
        type: Boolean,
        required: true,
    }
});

const form = useForm({
    foods: props.foods,
});

const dialogRef = ref(null);

const addFood = () => {
    form.foods.push('');
};

const removeFood = (index: number) => {
    form.foods.splice(index, 1);
};

const {route} = useMixin();

const submitForm = () => {
    form.post(route('app.design_invitation.edit.food', { card: props.card.slug }));
};

const deleteFood = () => {
    form.post(route('app.design_invitation.edit.food.delete', { card: props.card.slug }));
};
</script>

<template>
    <AppHead :title="'Menü ' + (exists ? 'bearbeiten' : 'hinzufügen')" />
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })" />
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Menü {{ exists ? 'bearbeiten' : 'hinzufügen' }}</h1>

                <p class="body body-m">
                    Erfragt hier, welche Speisen oder Gerichte Eure Gäste bei Eurer Hochzeit essen möchten.
                </p>

                <form class="mt-6" @submit.prevent="submitForm">
                    <div v-for="(food, index) in form.foods" class="flex align-items-center" :key="index">
                        <InputComponent
                            v-model:value="form.foods[index]"
                            :error="form.errors[`foods.${index}`]"
                            :name="`name_${index}`"
                            class="flex-grow"
                            placeholder="z.B. Fleisch, Fisch, Vegetarisch, ..."
                            type="text"
                        >
                            Auswahl {{ index + 1 }}
                        </InputComponent>

                        <button v-if="index !== 0" class="ml-4 mr-2" type="button" @click="removeFood(index)">
                            <img src="../../../../images/icons/trash-2.svg" />
                        </button>
                    </div>

                    <button class="add-guest" type="button" @click="addFood">
                        Weiteres Menü hinzufügen
                    </button>

                    <template v-if="exists">
                        <ConfirmationDialog ref="dialogRef">
                            <template v-slot:description>
                                <p class="body body-m mb-6">
                                    Falls Ihr Eure Meinung später ändern solltet, könnt Ihr dieses Element auch wieder aktivieren.
                                </p>
                            </template>
                            <template v-slot:button>
                                <form class="w-full" @submit.prevent="deleteFood">
                                    <ButtonComponent :processing="form.processing" class="button button-primary w-full h-full" type="submit">
                                        Bestätigen
                                    </ButtonComponent>
                                </form>
                            </template>
                        </ConfirmationDialog>

                        <button class="hide-block text-base font-bold text-flower-500 mt-8" type="button" @click="dialogRef.isVisible = true">
                            Element deaktivieren
                        </button>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full" type="submit">
                            {{ exists ? 'Speichern' : 'Hinzufügen' }}
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
