<script setup lang="ts">
import {defineProps, ref} from 'vue';
import {Link} from '@inertiajs/vue3';

defineProps({
    href: {
        type: String,
        required: true
    }
});

const processing = ref(false);
</script>

<template>
    <Link :href="href" @click="processing = true">
        <slot v-if="!processing" name="icon"/>

        <svg v-if="processing"
             class="animate-spin h-[24px] w-[24px]"
             fill="none" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b" stroke-width="4"></circle>
            <path class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="#893e3e"></path>
        </svg>
    </Link>
</template>
