<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {useMixin} from '@/mixins';
import Layout from '@/Layouts/Default.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import Card from '@/Shared/Card.vue';
import Share from '@/Shared/Sharing/Share.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Paywall from '@/Pages/App/Shipping/Paywall.vue';

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
    hasPaid: {
        type: Boolean,
        required: true,
    },
});

const form = useForm({});
const shareEnabled = ref(false);

const paywallRef = ref<InstanceType<typeof Paywall> | null>(null);
const openPaywallModal = () => {
    paywallRef.value.open();
};

const {route} = useMixin();

const link = computed(() => {
    return route('app.invitation.personal', {card: props.card.slug, invitation: props.invitation.slug});
});

const handleClick = (event: Event) => {
    if (!props.hasPaid) {
        event.preventDefault();
        openPaywallModal();
        return false;
    }
    return true;
};

onMounted(() => {
    shareEnabled.value = !!navigator.share;
});
</script>

<template>
    <AppHead :title="'Teilt Eure Einladung mit ' + props.invitation.concatenated_names"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <h1 class="hero hero-s">Teilt Eure Einladung mit {{ props.invitation.concatenated_names }}:</h1>
                <p class="body body-m mb-6">
                    Ihr könnt nun Eure Einladung per Link an Eure Gäste versenden.
                </p>

                <div class="flex justify-between">
                    <Share
                        :card="props.card"
                        :has-paid="props.hasPaid"
                        :link="link"
                        type="Mail"
                    />
                    <Share
                        :card="props.card"
                        :has-paid="props.hasPaid"
                        :link="link"
                        type="Link"
                    />
                    <Share
                        :card="props.card"
                        :has-paid="props.hasPaid"
                        :link="link"
                        type="WhatsApp"
                    />
                    <Share
                        :card="props.card"
                        :has-paid="props.hasPaid"
                        :link="link"
                        type="Share"
                        v-if="shareEnabled"
                    />
                </div>

                <div class="w-full bg-gray p-2 mt-6 rounded-lg text-sm text-raven-700 flex items-center">
                    <img class="mr-2" src="../../../../../images/icons/eye.svg"/>
                    <a
                        :class="{ 'blur-xs': !props.hasPaid }"
                        :href="link"
                        class="underline truncate"
                        target="_blank"
                        @click="handleClick"
                    >
                        {{ link }}
                    </a>
                </div>

                <ButtonGroup v-if="props.hasPaid" class="md:mt-10">
                    <div class="w-full">
                        <span class="body body-s flex justify-center mb-3">Einladung versendet?</span>
                        <form
                            class="grid grid-cols-2 gap-2"
                            @submit.prevent="form.post(route('app.shipping.invitation.mark_invitation_as_sent', { card: props.card.slug, invitation: props.invitation.slug }))"
                        >
                            <LinkComponent
                                :href="route('app.shipping', { card: props.card.slug })"
                                class="button button-secondary"
                            >
                                Abbrechen
                            </LinkComponent>
                            <ButtonComponent :processing="form.processing" class="button button-primary" type="submit">
                                Bestätigen
                            </ButtonComponent>
                        </form>
                    </div>
                </ButtonGroup>
            </template>
        </Card>

        <Paywall ref="paywallRef" :card="props.card"/>
    </Layout>
</template>
