<script setup lang="ts">
import Layout from '@/Layouts/Default.vue';
import Card from '@/Shared/Card.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();
</script>

<template>
    <AppHead title="Anmelden"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <h1 class="hero hero-s">Anmelden</h1>

                <p class="body body-m mb-6">
                    Wähle zwischen E-Mail, Apple, Google oder Facebook, gib deine Anmeldedaten ein und klicke auf
                    "Anmelden", um Zugriff auf dein Konto zu erhalten.
                </p>

                <LinkComponent :href="route('app.login.email')"
                               class="w-full button button-secondary">
                    <img src="../../../../images/login-with-mail.svg" alt="Anmelden mit E-Mail" class="mr-2"/>
                    <span>Anmelden mit E-Mail</span>
                </LinkComponent>

                <div class="relative w-full my-8 flex-grow-0 bg-gray h-[1px]">
                    <span
                        class="text-xs text-raven-500 absolute left-1/2 right-1/2 w-[41px] text-center bg-white top-[-8px] ml-[-20px]">oder</span>
                </div>

                <a :href="route('app.login.apple')"
                   class="w-full button button-secondary mb-2">
                    <img src="../../../../images/login-with-apple.svg" alt="Anmelden mit Apple" class="mr-2"/>
                    <span>Anmelden mit Apple</span>
                </a>

                <a :href="route('app.login.google')"
                   class="w-full button button-secondary mb-2">
                    <img src="../../../../images/login-with-google.svg" alt="Anmelden mit Google" class="mr-2"/>
                    <span>Anmelden mit Google</span>
                </a>

                <a :href="route('app.login.facebook')" class="w-full button button-secondary">
                    <img src="../../../../images/login-with-facebook.svg" alt="Anmelden mit Facebook" class="mr-2"/>
                    <span>Anmelden mit Facebook</span>
                </a>
            </template>
        </Card>
    </layout>
</template>
