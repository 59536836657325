<script setup lang="ts">
import {reactive} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    name1: {
        type: String,
    },
    name2: {
        type: String,
    }
});

// Form setup
const form = reactive(useForm({
    name1: props.name1,
    name2: props.name2
}));

const {route} = useMixin();
const submitForm = () => {
    form.post(route('app.onboarding.bridal_couple'));
};
</script>

<template>
    <AppHead title="Wie lauten Eure Vornamen?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.design')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wie lauten Eure Vornamen?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <InputComponent v-model:value="form.name1" :error="form.errors.name1" name="name1"
                                    placeholder="Vorname eintragen" type="text">
                        1. Vorname
                    </InputComponent>

                    <InputComponent v-model:value="form.name2" :error="form.errors.name2" name="name2"
                                    placeholder="Vorname eintragen" type="text">
                        2. Vorname
                    </InputComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Weiter
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
