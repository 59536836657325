<script setup lang="ts">
import {defineProps, ref} from 'vue';
import SupportDialog from '../Shared/SupportDialog.vue';
import LinkComponent from '../Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";
import {Link, usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from "@/Types/inertia";

const {route, mix} = useMixin();

defineProps({
    type: {
        type: String,
        default: 'app'
    },
    rimeless: {
        type: Boolean,
        default: false
    },
});

const openMenuLayer = ref(false);
const openCardNumber = ref((usePage<PageWithSharedProps>().props.card && usePage<PageWithSharedProps>().props.card.id) ?? 0);
const openSupportLayer = ref(false);

const mainNavigation = {
    website: [
        {title: 'Funktionen', url: route('web.functions')},
        {title: 'Preise', url: route('web.pricing')},
        {title: 'FAQ', url: route('web.faq')}
    ],
    app: [
        {title: 'Dashboard', url: route('app.dashboard')}
    ]
};

const toggleMenu = () => {
    openMenuLayer.value = !openMenuLayer.value;
};

const toggleSupport = () => {
    openSupportLayer.value = !openSupportLayer.value;
};

const toggleFlyout = () => {
    isFlyoutOpen.value = !isFlyoutOpen.value;
};

const openCard = (id: number) => {
    openCardNumber.value = id;
};

const isFlyoutOpen = ref(false);
</script>


<template>
    <header
        :class="{
            'mb-4 md:mb-8': !rimeless,
            'fixed top-0 z-75': openMenuLayer || openSupportLayer,
            'relative': !openMenuLayer && !openSupportLayer
        }"
        class="w-full bg-flower-50 p-4 lg:min-h-[88px] lg:flex"
    >
        <div class="flex justify-between items-center lg:container lg:mx-auto">
            <a :href="route('web.index')">
                <img :src="mix('resources/images/logo.svg')"
                     alt="Das Logo von Mi Boda. Der Name Mi Boda und daneben zwei Herzen." class="h-[25px]"/>
            </a>

            <nav class="hidden lg:flex lg:items-center">
                <ul class="lg:m-0 lg:p-0 lg:flex lg:gap-12">
                    <template v-if="usePage<PageWithSharedProps>().props.route.inWebsite">
                        <li v-for="item in mainNavigation.website"
                            :key="item.title"
                            class="lg:headline lg:headline-l lg:list-none lg:m-0 lg:flex lg:items-center lg:hover:text-flower-500"
                        >
                            <Link :href="item.url">
                                {{ item.title }}
                            </Link>
                        </li>
                    </template>

                    <template
                        v-if="usePage<PageWithSharedProps>().props.route.inApp && usePage<PageWithSharedProps>().props.auth.user">
                        <li v-for="item in mainNavigation.app"
                            :key="item.title"
                            class="lg:headline lg:headline-l lg:list-none lg:m-0 lg:flex lg:items-center lg:hover:text-flower-500"
                        >
                            <Link :href="item.url">
                                {{ item.title }}
                            </Link>
                        </li>
                    </template>

                    <li class="account lg:cursor-pointer lg:relative lg:headline lg:headline-l lg:list-none lg:m-0 lg:flex lg:items-center lg:hover:text-flower-500">
                        <a class="lg:flex lg:gap-3 lg:items-center" href="#" @click="toggleFlyout">
                            <img
                                :src="usePage<PageWithSharedProps>().props.auth.avatar ?? mix('resources/images/shared/header/avatar.svg')"
                                class="w-8 h-8 rounded-full"/>
                            <span>
                                Account
                            </span>
                        </a>

                        <nav class="flyout lg:hidden lg:absolute lg:right-0 lg:top-0 lg:pt-12"
                             :class="{ 'block': isFlyoutOpen }">
                            <ul class="lg:p-0 lg:m-0 lg:bg-white lg:rounded-md lg:shadow-md lg:min-w-[210px]">
                                <template v-if="!usePage<PageWithSharedProps>().props.auth.user">
                                    <li class="lg:flex lg:list-none lg:border-b-2 lg:border-light-gray">
                                        <Link :href="route('app.login')"
                                              class="lg:headline lg:headline-m lg:p-4 lg:m-0 lg:w-full lg:hover:text-flower-500">
                                            Anmelden
                                        </Link>
                                    </li>
                                </template>

                                <template v-if="usePage<PageWithSharedProps>().props.auth.user">
                                    <li class="lg:flex lg:list-none lg:border-b-2 lg:border-light-gray">
                                        <Link :href="route('app.user.settings')"
                                              class="lg:headline lg:headline-m lg:p-4 lg:m-0 lg:w-full lg:hover:text-flower-500">
                                            Einstellungen
                                        </Link>
                                    </li>

                                    <li class="lg:flex lg:list-none lg:border-b-2 lg:border-light-gray">
                                        <Link :href="route('app.logout')"
                                              class="lg:headline lg:headline-m lg:p-4 lg:m-0 lg:w-full lg:hover:text-flower-500">
                                            Abmelden
                                        </Link>
                                    </li>
                                </template>
                            </ul>
                        </nav>
                    </li>
                </ul>

                <LinkComponent
                    v-if="!usePage<PageWithSharedProps>().props.auth.user && usePage<PageWithSharedProps>().props.route.inWebsite"
                    :href="route('app.start')"
                    class="button button-primary lg:ml-12 lg:px-6 lg:py-4">
                    Einladung gestalten
                </LinkComponent>

                <LinkComponent
                    v-if="usePage<PageWithSharedProps>().props.auth.user && usePage<PageWithSharedProps>().props.route.inWebsite"
                    :href="route('app.dashboard')"
                    class="button button-primary lg:ml-12 lg:px-6 lg:py-4">
                    Dashboard öffnen
                </LinkComponent>
            </nav>

            <div class="inline-flex lg:hidden">
                <a v-if="!openMenuLayer" class="ml-2" href="#" @click="toggleSupport">
                    <img v-if="!openSupportLayer && usePage<PageWithSharedProps>().props.is_support_activated"
                         src="../../images/support-icon-whatsapp-green-status.svg"/>
                    <img v-if="!openSupportLayer && usePage<PageWithSharedProps>().props.is_support_activated === false"
                         src="../../images/support-icon-whatsapp-red-status.svg"/>

                    <img v-if="openSupportLayer" src="../../images/close.svg"/>
                </a>

                <a class="ml-6" href="#" @click="toggleMenu" v-if="!openSupportLayer">
                    <img v-if="!openMenuLayer" src="../../images/menu.svg"/>
                    <img v-if="openMenuLayer" src="../../images/close.svg"/>
                </a>
            </div>
        </div>

        <SupportDialog v-if="openSupportLayer"/>

        <div v-if="openMenuLayer"
             class="fixed top-[57px] left-0 right-0 bottom-0 w-full h-auto overflow-scroll bg-white">
            <nav>
                <ul class="p-0 m-0">
                    <template v-if="!usePage<PageWithSharedProps>().props.route.inApp">
                        <li v-for="item in mainNavigation.website" :key="item.title"
                            class="flex list-none border-b-2 border-light-gray">
                            <Link :href="item.url" class="headline headline-l p-4 m-0 w-full">
                                <div class="flex justify-between">
                                    <span>{{ item.title }}</span>
                                </div>
                            </Link>
                        </li>
                    </template>

                    <template v-if="usePage<PageWithSharedProps>().props.route.inApp">
                        <li v-for="item in mainNavigation.app" :key="item.title"
                            class="flex list-none border-b-2 border-light-gray">
                            <Link :href="item.url" class="headline headline-l p-4 m-0 w-full">
                                <div class="flex justify-between">
                                    <span>{{ item.title }}</span>
                                </div>
                            </Link>
                        </li>

                        <template v-for="(card, index) in usePage<PageWithSharedProps>().props.auth.user.cards"
                                  v-if="usePage<PageWithSharedProps>().props.auth.user">
                            <li class="flex list-none border-b-2 border-light-gray">
                                <div class="block headline headline-l p-4 m-0 w-full" @click="openCard(card.id)">
                                    <div class="flex justify-between">
                                        <span>{{ card.name }}</span>
                                        <img v-if="openCardNumber === card.id"
                                             :src="mix('resources/images/shared/header/icon-chevron-up.svg')"/>
                                        <img v-if="openCardNumber !== card.id"
                                             :src="mix('resources/images/shared/header/icon-chevron-down.svg')"/>
                                    </div>
                                </div>
                            </li>

                            <li v-if="openCardNumber === card.id" class="flex list-none border-b-2 border-light-gray">
                                <Link :href="route('app.design_invitation.preview', { card: card.slug })"
                                      class="headline headline-l p-4 pl-8 m-0 w-full text-[#6f7f9f]">
                                    <span>Bearbeiten</span>
                                </Link>
                            </li>

                            <li v-if="openCardNumber === card.id && card.is_personalized_invitation && card.is_an_invitation_card"
                                class="flex list-none border-b-2 border-light-gray">
                                <Link :href="route('app.guest_list', { card: card.slug })"
                                      class="headline headline-l p-4 pl-8 m-0 w-full text-[#6f7f9f]">
                                    <span>Gäste</span>
                                </Link>
                            </li>

                            <li v-if="openCardNumber === card.id" class="flex list-none border-b-2 border-light-gray">
                                <Link :href="route('app.shipping', { card: card.slug })"
                                      class="headline headline-l p-4 pl-8 m-0 w-full text-[#6f7f9f]">
                                    <span>Versand</span>
                                </Link>
                            </li>

                            <li v-if="openCardNumber === card.id && card.is_an_invitation_card"
                                class="flex list-none border-b-2 border-light-gray">
                                <Link :href="route('app.feedback', { card: card.slug })"
                                      class="headline headline-l p-4 pl-8 m-0 w-full text-[#6f7f9f]">
                                    <span>Rückmeldungen</span>
                                </Link>
                            </li>

                            <li v-if="openCardNumber === card.id" class="flex list-none border-b-2 border-light-gray">
                                <Link :href="route('app.design_invitation.edit', { card: card.slug })"
                                      class="headline headline-l p-4 pl-8 m-0 w-full text-[#6f7f9f]">
                                    <span>Einstellungen</span>
                                </Link>
                            </li>
                        </template>
                    </template>
                </ul>
            </nav>

            <nav class="mt-6">
                <div class="border-b-2 border-light-gray">
                    <div class="inline-flex items-center justify-start gap-2 pb-3 px-4">
                        <img
                            :src="usePage<PageWithSharedProps>().props.auth.avatar ?? mix('resources/images/shared/header/avatar.svg')"
                            class="w-8 h-8 rounded-full"/>
                        <div class="body body-m m-0">
                            {{ usePage().props.auth.user ? usePage().props.auth.user.concatenated_names : 'Account' }}
                        </div>
                    </div>
                </div>

                <ul class="p-0 m-0">
                    <template v-if="!usePage<PageWithSharedProps>().props.auth.user">
                        <li class="flex list-none border-b-2 border-light-gray">
                            <Link :href="route('app.login')" class="headline headline-l p-4 m-0 w-full">
                                <div class="flex justify-between">
                                    <span>Anmelden</span>
                                    <img :src="mix('resources/images/shared/header/icon-log-in.svg')"/>
                                </div>
                            </Link>
                        </li>
                    </template>

                    <template v-if="usePage<PageWithSharedProps>().props.auth.user">
                        <li class="flex list-none border-b-2 border-light-gray">
                            <Link :href="route('app.user.settings')" class="headline headline-l p-4 m-0 w-full">
                                <div class="flex justify-between">
                                    <span>Einstellungen</span>
                                    <img :src="mix('resources/images/shared/header/icon-settings.svg')"/>
                                </div>
                            </Link>
                        </li>

                        <li class="flex list-none border-b-2 border-light-gray">
                            <Link :href="route('app.logout')" class="headline headline-l p-4 m-0 w-full">
                                <div class="flex justify-between">
                                    <span>Abmelden</span>
                                    <img :src="mix('resources/images/shared/header/icon-log-in.svg')"/>
                                </div>
                            </Link>
                        </li>
                    </template>
                </ul>
            </nav>

            <LinkComponent
                v-if="!usePage<PageWithSharedProps>().props.auth.user && usePage<PageWithSharedProps>().props.route.inWebsite"
                :href="route('app.start')"
                class="button button-primary mt-6 p-4 bottom-4 left-4 right-4 w-auto"
                style="position: fixed!important;">
                Einladung gestalten
            </LinkComponent>

            <LinkComponent
                v-if="usePage<PageWithSharedProps>().props.auth.user && usePage<PageWithSharedProps>().props.route.inWebsite"
                :href="route('app.dashboard')"
                class="button button-primary mt-6 p-4 bottom-4 left-4 right-4 w-auto"
                style="position: fixed!important;">
                Dashboard öffnen
            </LinkComponent>
        </div>
    </header>

    <div v-if="usePage<PageWithSharedProps>().props.progress"
         class="w-full h-[3px] bg-flower-100 -mt-4 mb-4 md:-mt-8 md:mb-8">
        <div :style="{ 'width': usePage<PageWithSharedProps>().props.progress + '%' }"
             class="h-full bg-flower-300"></div>
    </div>
</template>

