<script setup lang="ts">
import {onMounted, ref, watchEffect} from 'vue';
import Notification from "@/Shared/Notification.vue";
import Header from "@/Shared/Header.vue";
import Footer from "@/Shared/Footer.vue";
import {Modal} from "momentum-modal";
import TimeoutModal from "@/Shared/TimeoutModal.vue";
import {usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from '@/Types/inertia';

defineProps({
    title: {
        type: String,
        required: false
    },
    rimeless: {
        type: Boolean,
        default: false
    },
    disableSpacer: {
        type: Boolean,
        default: false
    },
    spacerHeight: {
        type: String,
        default: '128px',
    }
});

const show = ref(false);
const spacer = ref(null);

watchEffect(() => {
    document.documentElement.style.setProperty("--primary-color", '#CB7373');
});

onMounted(() => {
    show.value = true;

    if (usePage<PageWithSharedProps>().props.auth.user && usePage<PageWithSharedProps>().props.auth.user.id) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'userData',
            user_id: usePage<PageWithSharedProps>().props.auth.user.id ?? null,
            user_email: usePage<PageWithSharedProps>().props.auth.user.email ?? null,
            user_created_at: usePage<PageWithSharedProps>().props.auth.user.created_at_timestamp ?? null,
            user_hash: usePage<PageWithSharedProps>().props.auth.intercom_hash ?? null,
        });
    }
});
</script>

<template>
    <main class="block w-full relative" v-bind="$attrs">
        <TimeoutModal/>
        <Modal/>

        <div class="background background-left"></div>
        <div class="background background-right"></div>

        <Header :rimeless="rimeless" type="app"></Header>

        <div v-if="show" :class="{ 'px-4 pb-4': !rimeless }" class="container mx-auto">
            <Notification v-for="(notification, key) in usePage<PageWithSharedProps>().props.flash.success" :key="key"
                          type="success">
                <span v-html="notification"></span>
            </Notification>

            <Notification v-for="(notification, key) in usePage<PageWithSharedProps>().props.flash.error" :key="key"
                          type="error">
                <span v-html="notification"></span>
            </Notification>

            <slot/>

            <div v-if="!disableSpacer" ref="spacer" class="spacer md:hidden" :style="`height: ${spacerHeight};`"></div>
        </div>

        <Footer :class="{ hidden: usePage<PageWithSharedProps>().props.route.inApp }"/>
    </main>
</template>

<style lang="scss">
#nprogress .bar {
    background: var(--primary-color) !important;
    height: 3px !important;
}

#nprogress .peg {
    box-shadow: none !important;
}
</style>
