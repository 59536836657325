<script setup lang="ts">
import {PropType, ref} from 'vue';
import {useMixin} from "@/mixins";
import AgendaBlock from '@/Designs/Legacy/Blocks/Agenda.vue';
import DresscodeBlock from '@/Designs/Legacy/Blocks/Dresscode.vue';
import FreeTextBlock from '@/Designs/Legacy/Blocks/FreeText.vue';
import GroomsmenBlock from '@/Designs/Legacy/Blocks/Groomsmen.vue';
import InvitationTextBlock from '@/Designs/Legacy/Blocks/InvitationText.vue';
import KidsBlock from '@/Designs/Legacy/Blocks/Kids.vue';
import WeddingGamesBlock from '@/Designs/Legacy/Blocks/WeddingGames.vue';
import ClosingTextBlock from '@/Designs/Legacy/Blocks/ClosingText.vue';
import GiftWishesBlock from '@/Designs/Legacy/Blocks/GiftWishes.vue';
import Foreword from '@/Designs/Legacy/Components/Foreword.vue';
import AccommodationBlock from '@/Designs/Legacy/Blocks/Accommodation.vue';
import Location from "@/Designs/Legacy/Components/Location.vue";
import Salutation from "@/Designs/Components/Salutation.vue";
import Footer from "@/Designs/Legacy/Footer.vue";
import {Link} from '@inertiajs/vue3';

const {
    route,
    getTime,
    getDate,
    address,
    generateRoute,
    __,
    getSalutation,
    formatGuestNames,
    getImagesFromCallery,
    getColorByThemeAndOption,
} = useMixin();

const loading = ref(false);

const loadingStart = () => {
    loading.value = true;

    setTimeout(() => {
        loading.value = false;
    }, 2000);
};

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    state: {
        type: String,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
    }
});
</script>

<template>
    <div class="h-full">
        <div v-if="state === 'invitation'" class="design design-evergreen w-full h-full bg-[#F9F7F1]">
            <div class="relative flex flex-wrap items-center justify-center md:h-[625px]">
                <img
                    class="hidden md:block absolute right-0 top-8 md:w-[575px] md:h-[605px] md:left-1/2 md:right-1/2 md:ml-[-287.5px]"
                    src="../../../../images/designs/evergreen/leaves-01.svg"/>
                <img class="absolute left-0 top-0 w-full md:hidden"
                     src="../../../../images/designs/evergreen/leaves-m-01.svg"/>

                <div class="w-full relative mt-[220px] md:mt-[100px] background-fix">
                    <h1 class="relative font-evergreen-body font-light text-black text-sm text-center uppercase mb-4">
                        <template v-if="card.is_personalized_invitation && invitation">
                            {{ getSalutation(invitation) }}
                            <br/><br/>
                            <div
                                class="font-evergreen-display font-normal text-[#303C3D] text-[5.5rem] leading-[6.5rem] block text-center normal-case"
                                v-html="formatGuestNames(invitation.guests_names_for_salutation)">
                            </div>
                        </template>

                        <template v-if="card.is_general_invitation">
                            {{ __('invitation.salutation.plural') }}
                            <br/><br/>
                            <span
                                class="font-evergreen-display font-normal text-[#303C3D] text-[5.5rem] leading-[6.5rem] block mt-[8px] text-center normal-case">
                <Salutation :salutation="card.salutation"/>
              </span>
                        </template>
                    </h1>

                    <Foreword :foreword="card.foreword"
                              class="w-7/12 md:w-[250px] mb-[30px] text-[#303C3D]"/>

                    <img class="w-full bg-[#F9F7F1] mt-[-75px] md:hidden"
                         src="../../../../images/designs/evergreen/leaves-m-02.svg"/>
                </div>
            </div>

            <div class="relative bg-[#F9F7F1] pt-[240px] md:pt-[120px]">
                <img class="absolute right-0 top-0" src="../../../../images/designs/evergreen/leaves-03.svg"/>

                <template v-for="(schedule, index) in card.wedding_schedules">
                    <div>
                        <div class="date" v-if="index === 0">{{ getDate(schedule.scheduled_at, 'DD.MM.YYYY') }}</div>
                        <div class="date"
                             v-if="index > 0 && getDate(schedule.scheduled_at as Date, 'DD.MM.YYYY') !== getDate(card.wedding_schedules[index - 1].scheduled_at as Date, 'DD.MM.YYYY')">
                            {{ getDate(schedule.scheduled_at, 'DD.MM.YYYY') }}
                        </div>

                        <div class="type">
                            {{ __('invitation.wedding_schedule.' + schedule.type) }}
                        </div>
                        <span class="time">{{ getTime(schedule.scheduled_at as Date, card) }}</span>
                        <span class="address" v-html="address(schedule.location)"/>
                    </div>
                </template>

                <div class="couple">
                    {{ card.user.concatenated_names }}

                    <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                        <img class="mx-auto mt-10"
                             :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                    </template>

                    <img v-if="card.user.id === 2448 || card.user.id === 2537" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2448.jpg"
                    />
                    <img v-if="card.user.id === 1755" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-1755-1.jpg"
                    />
                    <img v-if="card.user.id === 2509" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2509.jpg"/>
                    <img v-if="card.user.id === 2691" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2691.jpg"/>
                    <img v-if="card.user.id === 2255" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2255.jpg"/>
                    <img v-if="card.user.id === 2080" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2080.jpg"/>
                    <img v-if="card.user.id === 2768" class="mx-auto w-[250px] my-[25px]"
                         src="../../../../images/designs/evergreen/user-2768.jpg"/>
                    <img v-if="card.user.id === 1236" class="mx-auto w-[200px] mt-[25px]"
                         src="../../../../images/designs/evergreen/user-1236.jpg"/>
                    <img v-if="card.user.id === 751" class="mx-auto w-[125px] mt-[25px]"
                         src="../../../../images/designs/evergreen/user-751.svg"/>
                    <img v-if="card.user.id === 583" class="mx-auto w-[215px] mt-8"
                         src="../../../../images/designs/evergreen/user-583.jpg"/>

                    <img class="absolute right-2 top-4" src="../../../../images/designs/evergreen/leaves-04.svg"/>
                </div>
            </div>

            <div class="linkify pt-10 pb-30 relative pb-[200px] bg-[#F9F7F1]">
                <div v-for="(block, key) in card.content_blocks" class="w-3/4 md:w-[350px] mx-auto">
                    <InvitationTextBlock v-if="block.type === 'invitationText'" :block="block"/>
                    <AgendaBlock v-if="block.type === 'agenda'" :block="block"/>
                    <WeddingGamesBlock v-if="block.type === 'weddingGames'" :block="block"/>
                    <GroomsmenBlock v-if="block.type === 'groomsmen'" :block="block"/>
                    <GiftWishesBlock v-if="block.type === 'giftWishes'" :block="block"/>
                    <DresscodeBlock v-if="block.type === 'dresscode'" :block="block"/>
                    <KidsBlock v-if="block.type === 'kids'" :block="block"/>
                    <AccommodationBlock v-if="block.type === 'accommodation'" :block="block"/>
                    <FreeTextBlock v-if="block.type === 'freeText'" :block="block"/>
                    <ClosingTextBlock v-if="block.type === 'closingText'" :block="block"/>

                    <div v-if="key < Object.keys(card.content_blocks).length"
                         class="my-8 flex flex-wrap justify-between mx-auto w-[125px]">
                        <img src="../../../../images/designs/evergreen/leave-divider.svg"/>
                    </div>
                </div>

                <img class="absolute left-0 -bottom-12" src="../../../../images/designs/evergreen/leaves-05.svg"/>

                <img v-if="card.user.id === 1755" class="mx-auto w-[250px] my-[25px]"
                     src="../../../../images/designs/evergreen/user-1755-2.jpg"
                />
            </div>

            <div class="bg-[#E8E7E2]">
                <Location :card="card" :invitation="invitation"></Location>
            </div>

            <div class="w-full bg-[#F9F7F1] py-12 px-4 pt-16">
                <h2 class="mb-6 md:text-4xl md:w-[600px] md:mx-auto md:text-center relative">
                    <template v-if="!(invitation?.was_answered_already)">
                        {{ __('invitation.feedback.headline') }}
                    </template>

                    <template v-if="invitation?.was_answered_already">
                        {{ __('invitation.feedback.thanks') }}
                    </template>
                </h2>

                <p class="max-w-[350px] mb-10">
                    <template v-if="!card.feedback_text">
                        {{ __('invitation.feedback.text_default') }}
                    </template>
                    <span v-html="card.feedback_text"/>
                </p>

                <div class="md:grid md:grid-cols-1 md:justify-center">
                    <template v-if="card.is_personalized_invitation">
                        <Link
                            :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                            v-if="invitation?.was_answered_already"
                            class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.edit_feedback') }}
                            </div>
                        </Link>

                        <Link
                            :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                            v-if="!invitation?.was_answered_already"
                            class="button button-primary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.submit_feedback_now') }}
                            </div>
                        </Link>

                        <a
                            :href="route('app.invitation.ical', { 'card': card })"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            v-if="invitation?.was_answered_already">
                            {{ __('invitation.add_to_calendar') }}
                        </a>
                    </template>

                    <template v-if="card.is_general_invitation">
                        <Link
                            :href="generateRoute('app.invitation.feedback.general.edit', invitation, { 'card': card })"
                            v-if="invitation?.was_answered_already"
                            class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.edit_feedback') }}
                            </div>
                        </Link>

                        <Link
                            :href="generateRoute('app.invitation.feedback.general.chooser', invitation, { 'card': card })"
                            v-if="!invitation?.was_answered_already"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto"
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.submit_feedback_now') }}
                            </div>
                        </Link>

                        <a
                            :href="route('app.invitation.ical', { 'card': card })"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            v-if="invitation?.id">
                            {{ __('invitation.add_to_calendar') }}
                        </a>
                    </template>
                </div>
            </div>

            <Footer :card="card" class="bg-[#F9F7F1]"></Footer>
        </div>

        <div class="saveTheDate bg-[#faf8f3] relative min-h-screen" v-if="state === 'saveTheDate'">
            <img src="../../../../images/designs/evergreen/leaves-m-01.svg" alt="leaves-m-01.svg"
                 class="w-full md:w-auto"/>
            <img src="../../../../images/designs/evergreen/leaves-03.svg" alt="leaves-03.svg"
                 class="hidden md:block md:absolute md:right-0 md:top-0"/>

            <div class="save-the-date-container">
                <h1 class="mx-auto text-center mb-3 font-evergreen-display text-6xl font-normal ">
                    Save the Date
                </h1>

                <span class="block mx-auto text-center font-classic-body font-normal text-[16px] text-[#373735]"
                      v-html="card.foreword"/>

                <div class="my-6 flex flex-wrap justify-between mx-auto w-[125px]">
                    <img src="../../../../images/designs/evergreen/leave-divider.svg" alt="leave-divider.svg"/>
                </div>

                <span class="date">{{ getDate(card.first_wedding_schedule.scheduled_at, 'DD. MMMM YYYY') }}</span>

                <div class="my-6 mb-8 flex flex-wrap justify-between mx-auto w-[125px]">
                    <img src="../../../../images/designs/evergreen/leave-divider.svg" alt="leave-divider.svg"/>
                </div>

                <span class="couple">
                {{ card.user.concatenated_names }}
            </span>

                <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                    <img class="mx-auto"
                         :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                </template>
            </div>

            <img src="../../../../images/designs/evergreen/leaves-m-02.svg" alt="leaves-m-02.svg"
                 class="md:hidden w-full"/>

            <Footer :card="card" class="bg-[#F9F7F1] mb-0 w-[300px] md:mt-[250px]"></Footer>
        </div>

        <div class="saveTheDate bg-[#faf8f3] relative min-h-screen" v-if="state === 'thankYou'">
            <img src="../../../../images/designs/evergreen/leaves-m-01.svg" alt="leaves-m-01.svg"
                 class="w-full md:w-auto"/>
            <img src="../../../../images/designs/evergreen/leaves-03.svg" alt="leaves-03.svg"
                 class="hidden md:block md:absolute md:right-0 md:top-0"/>

            <div class="thank-you-container flex pt-0 items-center flex-col justify-center">
                <h1 class="mx-auto text-center mb-3 font-evergreen-display text-6xl font-normal w-[300px]">
                    Danke
                </h1>

                <span
                    class="block mx-auto text-center font-classic-body font-normal text-[16px] text-[#373735] w-[300px]"
                    v-html="card.foreword"/>

                <div class="my-6 mb-8 flex flex-wrap justify-between mx-auto w-[125px]">
                    <img src="../../../../images/designs/evergreen/leave-divider.svg" alt="leave-divider.svg"/>
                </div>

                <span class="couple">
                {{ card.user.concatenated_names }}
            </span>

                <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                    <img class="mx-auto"
                         :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                </template>
            </div>

            <div v-if="card.has_gallery_block">
                <div
                    class="bg-[#EFEEE9] pb-[80px] mt-[80px] flex justify-center flex-wrap flex-col text-center md:mx-auto">
                    <div class="font-montserrat text-base font-semibold mb-8 mt-[40px]"
                         :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                        {{ __('thankYou.gallery.headline') }}
                    </div>

                    <div class="mt-4 mb-8 flex flex-wrap justify-between mx-auto w-[125px]">
                        <img src="../../../../images/designs/evergreen/leave-divider.svg" alt="leave-divider.svg"/>
                    </div>

                    <div class="px-8 grid grid-cols-1 gap-4 md:w-[550px] md:mx-auto md:text-center">
                        <div v-for="image in getImagesFromCallery(card)">
                            <img class="object-cover object-center w-full max-w-full rounded-lg md:h-auto"
                                 :src="image"/>
                        </div>
                    </div>
                </div>
            </div>

            <img src="../../../../images/designs/evergreen/leaves-m-02.svg" alt="leaves-m-02.svg"
                 class="md:hidden w-full -mt-16"/>


            <img src="../../../../images/designs/evergreen/leaves-07.svg" alt="leaves-07.svg"
                 class="hidden md:block w-full mt-[-250px] md:w-[500px]"/>

            <Footer :card="card" class="bg-[#F9F7F1] mb-0 w-[300px]"></Footer>
        </div>
    </div>
</template>

<style lang="scss">
.saveTheDate {
    .date {
        @apply block font-evergreen-body font-normal text-2xl text-center text-[#303C3D] mb-4;
    }

    .couple {
        @apply block relative font-evergreen-display font-normal text-4xl text-center text-[#303C3D];
    }

    .save-the-date-container,
    .thank-you-container {
        @apply bg-none mx-auto w-[300px] md:w-[574px] md:h-[605px] md:pt-[152.25px];

        @screen md {
            background: url('../../../../images/designs/evergreen/leaves-01.svg');
        }
    }
}

.design {
    &.design-evergreen {
        .background-fix {
            @apply bg-[#F9F7F1];

            @screen md {
                background: none !important;
            }
        }

        .linkify {
            a {
                @apply block break-all font-bold hover:underline;
            }
        }

        p {
            @apply font-evergreen-body font-medium text-base text-center mx-auto text-[#5E5E5B];
        }

        .date {
            @apply block font-evergreen-display font-normal text-4xl text-center text-[#303C3D] mb-4;
        }

        .type {
            @apply block font-evergreen-body font-normal text-base text-center text-[#303C3D] uppercase underline mb-4 w-[225px] flex justify-center ml-auto mr-auto;
        }

        .time {
            @apply block font-evergreen-display font-normal text-3xl text-center text-[#303C3D] mb-2;
        }

        .address {
            @apply block font-evergreen-body font-normal text-base text-center text-[#303C3D] mb-[40px];
        }

        .couple {
            @apply block relative font-evergreen-display font-normal text-4xl text-center text-[#303C3D] min-h-[125px];
        }

        h2 {
            @apply font-evergreen-display font-normal text-4xl text-center;
        }

        h3 {
            @apply font-evergreen-body font-semibold text-base text-center mb-[4px];
        }

        .guest {
            .name {
                @apply font-evergreen-display font-normal text-4xl;
            }
        }

        textarea {
            background: #E1E0DA !important;
        }

        .button,
        button {
            @apply bg-[#303C3D];
        }

        .button-secondary {
            @apply text-[#303c3d] border-[#5e5e5b] font-black;
            background: #fff !important;
        }
    }
}
</style>
