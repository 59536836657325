<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import TipTap from "@/Shared/TipTap.vue";
import ConfirmationDialog from "@/Shared/ConfirmationDialog.vue";
import {onUpdated, PropType, ref} from "vue";
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    block: {
        type: Object,
    },
    exists: {
        type: Boolean,
        required: true,
    },
    suggestion: {
        type: String,
    },
});

const form = useForm({
    block: {
        text: props.block ? props.block.text : null,
    },
});

const textSuggestionForm = useForm({
    component: 'blocks.wedding_games',
    base_text: props.block.text,
    opposite: true,
});

const updateText = (text: string) => {
    form.block.text = text;
};

const deleteDialogRef = ref<InstanceType<typeof ConfirmationDialog>>(null);

const triggerDialog = () => {
    deleteDialogRef.value.open();
};

onUpdated(() => {
    if (props.suggestion) {
        updateText(props.suggestion);
    }
});
</script>

<template>
    <AppHead :title="'Hochzeitsspiele ' + (exists ? 'bearbeiten' : 'hinzufügen')"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', {
          card: card.slug
        })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Hochzeitsspiele {{ exists ? 'bearbeiten' : 'hinzufügen' }}</h1>

                <p class="body body-m">
                    Die einen lieben sie, die anderen hassen sie. Entscheidet selbst!
                </p>

                <form class="mt-6" @submit.prevent="form.post(route('app.design_invitation.edit.blocks.wedding_games', {
          card: card.slug
        }))">

                    <div class="flex justify-end mb-4">
                        <form
                            @submit.prevent="textSuggestionForm.post(route('app.design_invitation.edit.blocks.wedding_games.text_suggestions.suggestions', { card: card }))">
                            <ButtonComponent :processing="textSuggestionForm.processing"
                                             class="w-auto button button-primary"
                                             type="submit">
                                <div class="flex flex-row">
                                    <img src="../../../../../images/icons/type.svg" class="mr-2" alt="type.svg"/>
                                    Textvorschläge generieren (5s)
                                </div>
                            </ButtonComponent>
                        </form>
                    </div>

                    <TipTap name="text" :error="form.errors['block.text']" :model-value="form.block.text"
                            @onUpdateModelValue="updateText"/>

                    <template v-if="exists">
                        <ConfirmationDialog ref="deleteDialogRef">
                            <template v-slot:description>
                                <p class="body body-m mb-6">
                                    Falls Ihr Eure Meinung später ändern solltet, könnt Ihr dieses Element auch wieder
                                    aktivieren.
                                </p>
                            </template>
                            <template v-slot:button>
                                <form
                                    class="w-full"
                                    @submit.prevent="form.post(route('app.design_invitation.edit.blocks.wedding_games.delete', {
                      card: card.slug
                    }))">
                                    <ButtonComponent :processing="form.processing"
                                                     class="button button-primary w-full h-full"
                                                     type="submit">
                                        Bestätigen
                                    </ButtonComponent>
                                </form>
                            </template>
                        </ConfirmationDialog>

                        <button class="hide-block text-base font-bold text-flower-500 mt-8" type="button"
                                @click="triggerDialog">
                            Element deaktivieren
                        </button>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            {{ exists ? 'Speichern' : 'Hinzufügen' }}
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
