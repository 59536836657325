<script setup lang="ts">
import {PropType, ref} from 'vue';
import AgendaBlock from '@/Designs/Legacy/Blocks/Agenda.vue';
import DresscodeBlock from '@/Designs/Legacy/Blocks/Dresscode.vue';
import FreeTextBlock from '@/Designs/Legacy/Blocks/FreeText.vue';
import GroomsmenBlock from '@/Designs/Legacy/Blocks/Groomsmen.vue';
import InvitationTextBlock from '@/Designs/Legacy/Blocks/InvitationText.vue';
import KidsBlock from '@/Designs/Legacy/Blocks/Kids.vue';
import WeddingGamesBlock from '@/Designs/Legacy/Blocks/WeddingGames.vue';
import ClosingTextBlock from '@/Designs/Legacy/Blocks/ClosingText.vue';
import GiftWishesBlock from '@/Designs/Legacy/Blocks/GiftWishes.vue';
import Foreword from '@/Designs/Legacy/Components/Foreword.vue';
import AccommodationBlock from '@/Designs/Legacy/Blocks/Accommodation.vue';
import {Link} from '@inertiajs/vue3';
import {useMixin} from "@/mixins";
import Location from "@/Designs/Legacy/Components/Location.vue";
import Salutation from "@/Designs/Components/Salutation.vue";
import Footer from "@/Designs/Legacy/Footer.vue";

const {
    route,
    getTime,
    getDate,
    address,
    generateRoute,
    __,
    getSalutation,
    formatGuestNames,
    getImagesFromCallery,
} = useMixin();

const loading = ref(false);

const loadingStart = () => {
    loading.value = true;

    setTimeout(() => {
        loading.value = false;
    }, 2000);
};

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    state: {
        type: String,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
    }
});
</script>

<template>
    <div class="h-full">
        <div v-if="state === 'invitation'" class="design design-classic w-full bg-white">
            <div class="md:grid md:grid-cols-3">
                <div class="relative mx-auto text-center overflow-hidden md:col-span-2 md:m-0">

                    <img class="absolute right-0 top-0" src="../../../../images/designs/classic/top-right.svg"/>
                    <img class="absolute left-0 top-1/4" src="../../../../images/designs/classic/middle-left.svg"/>
                    <img class="absolute right-0 -bottom-20" src="../../../../images/designs/classic/bottom-right.svg"/>

                    <h1 class="font-light text-sm text-center tracking-wider uppercase pt-[64px] pb-[12px]">
                        <template v-if="card.is_personalized_invitation && invitation">
                            {{ getSalutation(invitation) }}
                            <br/><br/>
                            <div class="font-classic-display font-normal text-4xl block text-center normal-case"
                                 v-html="formatGuestNames(invitation.guests_names_for_salutation)">
                            </div>
                        </template>

                        <template v-if="card.is_general_invitation">
                            {{ __('invitation.salutation.plural') }}
                            <br/><br/>
                            <div class="font-classic-display font-normal text-4xl block text-center normal-case">
                                <Salutation :salutation="card.salutation"/>
                            </div>
                        </template>
                    </h1>

                    <Foreword :foreword="card.foreword" class="w-7/12 md:w-[240px]"/>

                    <template v-for="(schedule, index) in card.wedding_schedules">
                        <div class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                            <img src="../../../../images/designs/classic/divider-01.svg"/>
                            <img src="../../../../images/designs/classic/heart.svg"/>
                            <img src="../../../../images/designs/classic/divider-02.svg"/>
                        </div>

                        <div>
                            <div class="date" v-if="index === 0">{{
                                    getDate(schedule.scheduled_at, 'DD.MM.YYYY')
                                }}
                            </div>
                            <div class="date"
                                 v-if="index > 0 && getDate(schedule.scheduled_at as Date, 'DD.MM.YYYY') !== getDate(card.wedding_schedules[index - 1].scheduled_at as Date, 'DD.MM.YYYY')">
                                {{ getDate(schedule.scheduled_at, 'DD.MM.YYYY') }}
                            </div>

                            <div class="type">
                                {{ __('invitation.wedding_schedule.' + schedule.type) }}
                            </div>
                            <div class="time">{{ getTime(schedule.scheduled_at as Date, card) }}</div>
                            <div class="address" v-html="address(schedule.location)"/>
                        </div>
                    </template>

                    <div class="couple">{{ card.user.concatenated_names }}</div>
                </div>

                <div class="w-full md:col-span-1">
                    <img class="w-full md:h-full md:object-cover" src="../../../../images/designs/classic/header.jpg"/>
                </div>
            </div>

            <div class="relative">
                <img class="absolute right-0 top-4 hidden md:block"
                     src="../../../../images/designs/classic/spot-01.svg"/>
                <img class="absolute left-0 top-1/4 hidden md:block"
                     src="../../../../images/designs/classic/spot-02.svg"/>
                <img class="absolute right-0 bottom-0 hidden md:block"
                     src="../../../../images/designs/classic/spot-03.svg"/>

                <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                    <img class="mx-auto mt-16 md:mb-16 relative z-50"
                         :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                </template>

                <div class="linkify py-16 md:bg-flower-50">
                    <div class="relative mx-auto w-3/4 md:w-[538px] md:bg-white md:rounded-lg md:shadow-sm md:p-12">
                        <template v-for="(block, key) in card.content_blocks">
                            <InvitationTextBlock v-if="block.type === 'invitationText'" :block="block"/>
                            <AgendaBlock v-if="block.type === 'agenda'" :block="block"/>
                            <WeddingGamesBlock v-if="block.type === 'weddingGames'" :block="block"/>
                            <GroomsmenBlock v-if="block.type === 'groomsmen'" :block="block"/>
                            <GiftWishesBlock v-if="block.type === 'giftWishes'" :block="block"/>
                            <DresscodeBlock v-if="block.type === 'dresscode'" :block="block"/>
                            <KidsBlock v-if="block.type === 'kids'" :block="block"/>
                            <AccommodationBlock v-if="block.type === 'accommodation'" :block="block"/>
                            <FreeTextBlock v-if="block.type === 'freeText'" :block="block"/>
                            <ClosingTextBlock v-if="block.type === 'closingText'" :block="block"/>

                            <div v-if="key < Object.keys(card.content_blocks).length"
                                 class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                                <img src="../../../../images/designs/classic/divider-01.svg"/>
                                <img src="../../../../images/designs/classic/heart.svg"/>
                                <img src="../../../../images/designs/classic/divider-02.svg"/>
                            </div>
                        </template>
                    </div>
                </div>

                <Location :card="card" :invitation="invitation" class="bg-flower-50"></Location>
            </div>

            <div class="w-full bg-white py-12 px-4">
                <h2 class="text-flower-300 mb-10 md:text-4xl md:w-[600px] md:mx-auto md:text-center">
                    <template v-if="!(invitation?.was_answered_already)">
                        {{ __('invitation.feedback.headline') }}
                    </template>

                    <template v-if="invitation?.was_answered_already">
                        {{ __('invitation.feedback.thanks') }}
                    </template>
                </h2>

                <p class="max-w-[350px] mb-10">
                    <template v-if="!card.feedback_text">
                        {{ __('invitation.feedback.text_default') }}
                    </template>
                    <span v-html="card.feedback_text"/>
                </p>

                <div class="md:grid md:grid-cols-1 md:justify-center">
                    <template v-if="card.is_personalized_invitation">
                        <Link
                            :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                            v-if="invitation?.was_answered_already"
                            class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.edit_feedback') }}
                            </div>
                        </Link>

                        <Link
                            :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                            v-if="!invitation?.was_answered_already"
                            class="button button-primary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.submit_feedback_now') }}
                            </div>
                        </Link>

                        <a
                            :href="route('app.invitation.ical', { 'card': card })"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            v-if="invitation?.was_answered_already">
                            {{ __('invitation.add_to_calendar') }}
                        </a>
                    </template>

                    <template v-if="card.is_general_invitation">
                        <Link
                            :href="generateRoute('app.invitation.feedback.general.edit', invitation, { 'card': card })"
                            v-if="invitation?.was_answered_already"
                            class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.edit_feedback') }}
                            </div>
                        </Link>

                        <Link
                            :href="generateRoute('app.invitation.feedback.general.chooser', invitation, { 'card': card })"
                            v-if="!invitation?.was_answered_already"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            preserve-state preserve-scroll
                            @click="loadingStart">
                            <svg v-show="loading"
                                 class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                 fill="none"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      fill="currentColor"></path>
                            </svg>

                            <div :class="{'invisible' : loading}" class="inline-flex">
                                {{ __('invitation.feedback.submit_feedback_now') }}
                            </div>
                        </Link>

                        <a
                            :href="route('app.invitation.ical', { 'card': card })"
                            class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                            v-if="invitation?.id">
                            {{ __('invitation.add_to_calendar') }}
                        </a>
                    </template>
                </div>
            </div>

            <Footer :card="card"></Footer>
        </div>

        <template v-if="state === 'saveTheDate'">
            <div class="saveTheDate md:grid md:grid-cols-2">
                <div class="h-full w-full relative md:pb-[200px]">
                    <img class="absolute right-0 top-0" src="../../../../images/designs/classic/top-right.svg"
                         alt="top-right.png"/>
                    <img class="absolute left-0 top-2/4" src="../../../../images/designs/classic/middle-left.svg"
                         alt="middle-left.svg"/>
                    <img class="absolute right-0 bottom-0" src="../../../../images/designs/classic/bottom-right.svg"
                         alt="bottom-right.png"/>

                    <div
                        class="md:w-[500px] md:absolute md:top-1/2 md:left-1/2 md:-translate-x-2/4 md:-translate-y-2/4">
                        <h1 class="mx-auto text-center pt-20 mb-3 font-classic-display font-light text-4xl">
                            Save the Date
                        </h1>

                        <div class="mx-auto w-[237px]">
                    <span class="block mx-auto text-center font-classic-body font-light text-base text-[#122f4b]"
                          v-html="card.foreword"/>

                            <div class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                                <img src="../../../../images/designs/classic/divider-01.svg" alt="divider-01.svg"/>
                                <img src="../../../../images/designs/classic/heart.svg" alt="heart.svg"/>
                                <img src="../../../../images/designs/classic/divider-02.svg" alt="divider-02.svg"/>
                            </div>

                            <span class="date">{{
                                    getDate(card.first_wedding_schedule.scheduled_at, 'DD. MMMM YYYY')
                                }}</span>

                            <div class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                                <img src="../../../../images/designs/classic/divider-01.svg" alt="divider-01.svg"/>
                                <img src="../../../../images/designs/classic/heart.svg" alt="heart.svg"/>
                                <img src="../../../../images/designs/classic/divider-02.svg" alt="divider-02.svg"/>
                            </div>
                        </div>

                        <div class="mx-auto w-[350px]">
                            <span class="couple">{{ card.user.concatenated_names }}</span>
                        </div>

                        <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                            <img class="mx-auto my-8"
                                 :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                        </template>
                    </div>
                </div>
                <div>
                    <img class="w-full" src="../../../../images/designs/classic/header.jpg"
                         alt="header.png"/>
                </div>
            </div>

            <Footer :card="card"></Footer>
        </template>

        <template v-if="state === 'thankYou'">
            <div class="thankYou">
                <div class="h-full w-full relative md:pb-6">
                    <img class="absolute right-0 top-0" src="../../../../images/designs/classic/top-right.svg"
                         alt="top-right.png"/>
                    <img class="absolute left-0 top-[200px]" src="../../../../images/designs/classic/middle-left.svg"
                         alt="middle-left.svg"/>
                    <img class="absolute right-0 top-[300px] md:top-[325px]"
                         src="../../../../images/designs/classic/bottom-right.svg"
                         alt="bottom-right.png"/>

                    <div
                        class="mx-auto">
                        <h1 class="mx-auto text-center pt-20 mb-3 font-classic-display font-light text-4xl md:text-[49px] md:pt-[112px]">
                            Danke
                        </h1>

                        <div class="mx-auto w-[237px]">
                    <span class="block mx-auto text-center font-classic-body font-light text-base text-[#122f4b]"
                          v-html="card.foreword"/>

                            <div class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                                <img src="../../../../images/designs/classic/divider-01.svg" alt="divider-01.svg"/>
                                <img src="../../../../images/designs/classic/heart.svg" alt="heart.svg"/>
                                <img src="../../../../images/designs/classic/divider-02.svg" alt="divider-02.svg"/>
                            </div>
                        </div>

                        <div class="mx-auto w-[350px]">
                            <span class="couple mb-0 md:text-[32px]">{{ card.user.concatenated_names }}</span>
                        </div>

                        <div class="my-8 flex flex-wrap justify-between mx-auto w-[180px]">
                            <img src="../../../../images/designs/classic/divider-01.svg" alt="divider-01.svg"/>
                            <img src="../../../../images/designs/classic/heart.svg" alt="heart.svg"/>
                            <img src="../../../../images/designs/classic/divider-02.svg" alt="divider-02.svg"/>
                        </div>

                        <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                            <img class="mx-auto my-8"
                                 :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                        </template>
                    </div>

                    <template v-if="card.has_gallery_block">
                        <div class="bg-[#FFF7F7] relative z-50 mt-[100px]">
                            <div class="px-4 pb-16 relative -top-8">
                                <div class="grid grid-cols-1 gap-4 md:w-[550px] md:mx-auto md:text-center">
                                    <div v-for="image in getImagesFromCallery(card)">
                                        <img class="object-cover object-center w-full max-w-full rounded-lg md:h-auto"
                                             :src="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <Footer :card="card"></Footer>
        </template>
    </div>
</template>

<style lang="scss">
.saveTheDate,
.thankYou {
    .date {
        @apply block font-classic-body font-bold text-2xl text-center mb-[16px];
    }

    .couple {
        @apply block font-classic-display text-3xl text-center text-[#E89E9E] mb-8 md:mb-0;
    }
}

.design {
    &.design-classic {
        .linkify {
            a {
                @apply block break-all text-flower-300 font-bold hover:underline;
            }
        }

        p {
            @apply font-classic-body font-light text-base text-center mx-auto mb-[12px];
        }

        .date {
            @apply block font-classic-body font-bold text-2xl text-center mb-[16px];
        }

        .type {
            @apply block font-classic-body font-light text-base text-center tracking-wider underline uppercase mb-[16px] w-[225px] flex justify-center ml-auto mr-auto;
        }

        .time {
            @apply block font-classic-body font-bold text-xl text-center mb-[16px];
        }

        .address {
            @apply block font-classic-body font-light text-base text-center;
        }

        .couple {
            @apply block font-classic-display text-3xl text-center pt-[50px] pb-[64px] text-[#E89E9E];
        }

        h2 {
            @apply font-classic-display text-2xl text-center font-normal not-italic;
        }

        h3 {
            @apply font-classic-body font-bold not-italic text-base text-center mb-[4px];
        }

        .guest {
            .name {
                @apply font-classic-display;
            }
        }

        footer {
            @apply font-classic-body font-light not-italic text-sm text-center bg-flower-50;
        }
    }
}
</style>
