<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import TipTap from "@/Shared/TipTap.vue";
import ConfirmationDialog from "@/Shared/ConfirmationDialog.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import AddressComponent from "@/Shared/Forms/AddressComponent.vue";
import AppHead from "@/Shared/AppHead.vue";
import {PropType, ref} from "vue";
import {useMixin} from "@/mixins";

const {route, __} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    block: {
        type: Object,
        required: true,
    },
    exists: {
        type: Boolean,
        required: true,
    }
});

const form = useForm({
    block: {
        address: props.block ? props.block.address : null,
        address_custom: props.block ? props.block.addressCustom : null,
        description: props.block ? props.block.description : null,
    },
});

const updateAddress = (address: string) => {
    form.block.address = address;
};

const updateDescription = (description: string) => {
    form.block.description = description;
};

const deleteDialogRef = ref<InstanceType<typeof ConfirmationDialog>>(null);

const triggerDialog = () => {
    deleteDialogRef.value.open();
};
</script>

<template>
    <AppHead :title="'Anfahrt ' + (exists ? 'bearbeiten' : 'hinzufügen')"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Anfahrt {{ exists ? 'bearbeiten' : 'hinzufügen' }}</h1>

                <p class="body body-m">
                    Helft Euren Gästen, den Weg zu Eurer Location zu finden.
                </p>

                <form class="mt-6"
                      @submit.prevent="form.post(route('app.design_invitation.edit.blocks.arrival', { card: card.slug }))">
                    <div>
                        <template v-for="schedule in card.wedding_schedules">
                            <RadioButtonComponent :id="schedule.type" :error="form.errors['block.address']"
                                                  :model-value="form.block.address" name="address"
                                                  :value="schedule.type" @onUpdateModelValue="updateAddress">
                                <template v-slot:label>
                                    Adresse von {{ __('invitation.wedding_schedule.' + schedule.type) }}
                                </template>
                            </RadioButtonComponent>
                        </template>

                        <RadioButtonComponent id="custom" :error="form.errors['block.address']"
                                              :model-value="form.block.address" name="address" value="custom"
                                              @onUpdateModelValue="updateAddress">
                            <template v-slot:label>Andere Adresse</template>
                        </RadioButtonComponent>
                    </div>

                    <template v-if="form.block.address === 'custom'">
                        <AddressComponent v-model:value="form.block.address_custom"
                                          :error="form.errors['block.address_custom']" name="address_custom"
                                          placeholder="Adresse eingeben"/>
                    </template>

                    <div class="mt-4"/>
                    <TipTap name="text" :error="form.errors['block.description']" :model-value="form.block.description"
                            @onUpdateModelValue="updateDescription">
                        Anfahrtsbeschreibung
                    </TipTap>

                    <template v-if="exists">
                        <ConfirmationDialog ref="deleteDialogRef">
                            <template v-slot:description>
                                <p class="body body-m mb-6">
                                    Falls Ihr Eure Meinung später ändern solltet, könnt Ihr dieses Element auch wieder
                                    aktivieren.
                                </p>
                            </template>
                            <template v-slot:button>
                                <form class="w-full"
                                      @submit.prevent="form.post(route('app.design_invitation.edit.blocks.arrival.delete', { card: card.slug }))">
                                    <ButtonComponent :processing="form.processing"
                                                     class="button button-primary w-full h-full" type="submit">
                                        Bestätigen
                                    </ButtonComponent>
                                </form>
                            </template>
                        </ConfirmationDialog>

                        <button class="hide-block text-base font-bold text-flower-500 mt-8" type="button"
                                @click="triggerDialog">
                            Element deaktivieren
                        </button>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            {{ exists ? 'Speichern' : 'Hinzufügen' }}
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
