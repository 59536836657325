<script setup lang="ts">
import Layout from '@/Layouts/Default.vue';
import DesignCard from '@/Pages/App/Card/Design/DesignCard.vue';
import AppHead from '@/Shared/AppHead.vue';
import Card from '@/Shared/Card.vue';
import BackLink from '@/Shared/BackLink.vue';
import {PropType} from 'vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    designs: {
        type: Array as PropType<Array<App.Models.Design>>,
        required: true
    },
    cardType: {
        type: String,
        required: true
    },
    selectedDesign: {
        type: String,
        required: false
    }
});
</script>

<template>
    <AppHead title="Welches Design wählt Ihr?"/>
    <layout class="md:bg-flower-50">
        <Card>
            <template v-slot:outer>
                <BackLink :href="route('app.dashboard.card.choose_card_type')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s mb-6 md:hero-m">Welches Design wählt Ihr?</h1>

                <div class="grid lg:grid-cols-3 lg:gap-6">
                    <DesignCard v-for="design in designs"
                                :key="design.slug"
                                :design="design"
                                :card-type="cardType"
                                :selected-design="selectedDesign"
                    />
                </div>
            </template>
        </Card>
    </layout>
</template>
