<script setup lang="ts">
import {PropType, watchEffect} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Invitation from '@/Layouts/Invitation.vue';
import Templates from '@/Designs/Templates.vue';
import AppHead from '@/Shared/AppHead.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
    cardType: {
        type: String,
        required: true,
    },
    returnUrl: {
        type: String,
        required: true,
    },
    submitUrl: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    }
});

const form = useForm({
    design: props.card.design,
    theme: props.card.theme,
});

const changeTheme = (theme: string) => {
    props.card.theme = theme;
    form.theme = theme;
    updateQueryParam('theme', theme);
};

const updateQueryParam = (paramName: string, newValue: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(paramName, newValue);
    const newUrl = url.toString();
    window.history.replaceState({path: newUrl}, '', newUrl);
};

watchEffect(() => {
    updateQueryParam('theme', form.theme);
});

const {mix} = useMixin();
</script>

<template>
    <AppHead :title="title"/>
    <Invitation :card="card">

        <div class="w-full bg-white px-4 py-5">
            <div class="flex justify-between">
                <BackLink :href="returnUrl" style="margin-bottom: 0 !important;">
                    Zurück
                </BackLink>

                <div class="flex gap-4 items-center">
                    <div v-for="(color, key) in design.variants" class="justify-between">
                        <img :src="mix(`resources/images/designs/${design.slug}/${key}.svg`)"
                             class="w-[21px] cursor-pointer"
                             :class="{'rounded-full w-[23px] p-[2px] border-2 border-[#CB7373]': key === card.theme}"
                             @click="changeTheme(key as String)"
                             :alt=" key"/>
                    </div>
                </div>

                <form @submit.prevent="form.post(submitUrl)" class="hidden md:block">
                    <input v-model="design.slug" name="design" type="hidden"/>
                    <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                     type="submit">
                        Dieses Design auswählen
                    </ButtonComponent>
                </form>
            </div>
        </div>

        <Templates
            :state="cardType"
            :card="card"
            :design="design"
            :invitation="invitation"
        />

        <form @submit.prevent="form.post(submitUrl)">
            <input v-model="design.slug" name="design" type="hidden"/>
            <ButtonGroup class="md:hidden">
                <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                 type="submit">
                    Dieses Design auswählen
                </ButtonComponent>
            </ButtonGroup>
        </form>

    </Invitation>
</template>
