<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
});

const form = useForm({
    type: props.card.shipping_type,
});

const updateShippingType = (type: string) => {
    form.type = type;
};

const {route} = useMixin();

const submitForm = () => {
    form.post(route('app.design_invitation.edit.shipping_type', {card: props.card.slug}));
};
</script>

<template>
    <AppHead title="Welche Einladungsform wählt Ihr?"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Welche Einladungsform wählt Ihr?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <RadioButtonComponent
                        id="personalized"
                        :error="form.errors.type"
                        :model-value="form.type"
                        name="type"
                        value="personalized"
                        @onUpdateModelValue="updateShippingType"
                    >
                        <template v-slot:label>Personalisierte Einladung</template>
                        <template v-slot:description>
                            Ihr erhaltet für jeden Gast eine namentliche Einladung, die Ihr per Link an diesen
                            verschickt. Die Einladung kann auch Pärchen oder Gruppen beinhalten.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent
                        id="general"
                        :error="form.errors.type"
                        :model-value="form.type"
                        name="type"
                        value="general"
                        @onUpdateModelValue="updateShippingType"
                    >
                        <template v-slot:label>Allgemeine Einladung</template>
                        <template v-slot:description>
                            Ihr erhaltet eine unpersonalisierte Einladung, die Ihr per Link an alle Gäste verschickt.
                        </template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
