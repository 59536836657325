<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import Layout from '@/Layouts/Default.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import TabNavigation from '@/Shared/TabNavigation.vue';
import FeedbackInvitationCard from '@/Pages/App/Feedback/InvitationCard.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    hasInvitations: {
        type: Boolean,
        required: true,
    },
    invitationsAnswered: {
        type: Array as PropType<Array<App.Models.Invitation>>,
        required: true,
    },
    invitationsNotAnswered: {
        type: Array as PropType<Array<App.Models.Invitation>>,
        required: true,
    },
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Rückmeldungen"/>
    <layout class="bg-flower-50">
        <TabNavigation :card="props.card" current-route="feedback"/>

        <div
            v-if="(!props.invitationsAnswered.length && props.card.is_general_invitation) || (!props.hasInvitations && props.card.is_personalized_invitation)"
            class="md:w-[525px] md:mx-auto">
            <div class="rounded-lg p-4 shadow-md bg-white py-8 px-6 mt-3">
                <img class="mx-auto mb-8" src="../../../images/invitations-empty-state.svg"/>
                <div>
                    <h2 class="headline headline-xl text-center">
                        Bisher habt Ihr noch keine Rückmeldungen erhalten.
                    </h2>
                </div>
            </div>
        </div>

        <div class="md:w-[525px] md:mx-auto">
            <template v-if="props.invitationsAnswered.length > 0">
                <div class="grid grid-cols-1 gap-3 mt-3 md:mt-6">
                    <FeedbackInvitationCard v-for="invitation in props.invitationsAnswered" :card="card"
                                            :invitation="invitation"/>
                </div>
            </template>

            <template v-if="props.invitationsNotAnswered.length > 0 && props.card.is_personalized_invitation">
                <div :class="{ 'mt-4' : !props.invitationsAnswered.length }" class="mb-4 mt-8">
                    <hr v-if="props.invitationsAnswered.length" class="w-full h-[2px] bg-flower-100 mb-6 border-0"/>
                    <h2 class="font-body font-bold text-center text-base text-[#6f7f9f]">
                        Noch offene Rückmeldungen
                    </h2>
                </div>

                <div class="grid grid-cols-1 gap-3 mt-3 md:mt-6">
                    <FeedbackInvitationCard v-for="invitation in props.invitationsNotAnswered" :card="props.card"
                                            :invitation="invitation"/>
                </div>
            </template>

            <ButtonGroup v-if="props.invitationsAnswered.length > 0" class="md:mt-10">
                <LinkComponent :href="route('app.export', { card: props.card.slug })"
                               class="button button-secondary w-full">
                    <img class="mr-2" src="../../../images/icons/export.svg"/>
                    Rückmeldungen exportieren
                </LinkComponent>
            </ButtonGroup>
        </div>
    </layout>
</template>
