<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import BackLink from '@/Shared/BackLink.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

interface FormData {
    email: string | null;
}

const form = useForm<FormData>({
    email: null,
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Passwort vergessen?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.login.email')">Zurück zum Login</BackLink>
            </template>
            <template v-slot:inner>
                <h1 class="hero hero-s">Passwort vergessen?</h1>

                <p class="body body-m">
                    Wenn Ihr Eurer Passwort vergessen habt, könnt Ihr mit Hilfe Eurer E-Mail-Adresse das Passwort
                    zurücksetzen lassen.
                </p>

                <form class="mt-6" @submit.prevent="form.transform((data) => ({
                    ...data,
                    email: data.email ? data.email.toLowerCase() : null,
                  })).post(route('app.password.forgot'))">
                    <InputComponent v-model:value="form.email" :error="form.errors.email" name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    type="text">
                        E-Mail-Adresse
                    </InputComponent>

                    <ButtonGroup>
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Passwort vergessen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
