<script setup lang="ts">
import {PropType, watchEffect} from 'vue';
import Notification from "../Shared/Notification.vue";
import {Modal} from "momentum-modal";
import {usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from "@/Types/inertia";

watchEffect(() => {
    document.documentElement.style.setProperty("--primary-color", usePage<PageWithSharedProps>().props.card ? usePage<PageWithSharedProps>().props.card.primary_color_from_design : '#CB7373');
});

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
});
</script>

<template>
    <main v-bind="$attrs">
        <Modal/>

        <Notification v-for="(notification, key) in usePage<PageWithSharedProps>().props.flash.success" :key="key" type="success">
            <span v-html="notification"></span>
        </Notification>

        <Notification v-for="(notification, key) in usePage<PageWithSharedProps>().props.flash.error" :key="key" type="error">
            <span v-html="notification"></span>
        </Notification>

        <slot></slot>
    </main>
</template>

<style lang="scss">
#nprogress .bar {
    background: var(--primary-color) !important;
    height: 3px !important;
}

#nprogress .peg {
    box-shadow: none !important;
}
</style>
