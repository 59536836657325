<script setup lang="ts">
import { computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import Layout from "@/Layouts/Default.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import BackLink from "@/Shared/BackLink.vue";
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    cardType: {
        type: String,
        required: true,
    },
    language: {
        type: String,
        required: true,
    }
});

const form = useForm({
    language: props.language,
});

const title = computed(() => {
    return props.cardType === 'thankYou'
        ? 'In welcher Sprache wollt Ihr Euch bedanken?'
        : 'In welcher Sprache wollt Ihr einladen?';
});

function updateLanguage(language: string) {
    form.language = language;
}
</script>

<template>
    <AppHead :title="title"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink href=""/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">{{ title }}</h1>

                <form class="mt-6" @submit.prevent="form.post(route('app.dashboard.new.language'))">
                    <RadioButtonComponent id="DE" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="de" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Deutsch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="EN" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="en" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Englisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="FR" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="fr" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Französisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="ES" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="es" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Spanisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="IT" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="it" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Italienisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="NL" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="nl" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Niederländisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="SE" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="se" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Schwedisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="FI" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="fi" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Finnisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="TR" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="tr" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Türkisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="TH" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="th" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Thailändisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="PT" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="pt" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Portugiesisch</template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Weiter
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
