<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    ceremony_date: {
        type: String,
        required: true,
    },
});

const form = useForm({
    ceremony_date: props.ceremony_date,
});

const submitForm = () => {
    form.post(route('app.design_invitation.edit.date_of_wedding', {card: props.card.slug}));
};

const {route} = useMixin();
</script>

<template>
    <AppHead title="Wann wollt Ihr heiraten?"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.bridal_couple')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wann wollt Ihr heiraten?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <InputComponent
                        v-model:value="form.ceremony_date"
                        :error="form.errors.ceremony_date"
                        name="ceremony_date"
                        type="date"
                    >
                        Datum
                    </InputComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
