<script setup lang="ts">
import {PropType} from "vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
});
</script>

<template>
    <footer class="w-full mx-auto text-center py-6 md:mb-6">

        <template v-if="!card.blocks.filter(block => block.type === 'logo').length">
            <a class="block mb-4" href="https://mi-boda.com" target="_blank">
                <img alt="Das Logo von Mi Boda. Der Name Mi Boda und daneben zwei Herzen." class="mx-auto h-[25px]"
                     src="../../../images/logo.svg"/>
            </a>
            <div class="mb-4">
                &copy; Copyright 2021 by Mi Boda
            </div>
        </template>


        <a :href="route('web.imprint')" class="inline-block underline mb-4 mr-4" target="_blank">
            Impressum
        </a>

        <a :href="route('web.privacy')" class="inline-block underline mb-4" target="_blank">
            Datenschutz
        </a>

        <div class="text-center">
            <slot/>
        </div>
    </footer>
</template>
