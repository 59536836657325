<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import LinkComponent from "@/Shared/Forms/LinkComponent.vue";
import ConfirmationDialog from "@/Shared/ConfirmationDialog.vue";
import {PropType, ref} from "vue";
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    block: {
        type: Object,
        required: true,
    },
    exists: {
        type: Boolean,
        required: true,
    }
});

const deleteDialogRef = ref<InstanceType<typeof ConfirmationDialog>>(null);

const removeLogoDialog = () => {
    deleteDialogRef.value.open();
};

const form = useForm({});
</script>

<template>
    <AppHead title="Logo & Copyright"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', {
          card: card.slug
        })"/>
            </template>

            <template v-slot:inner>
                <img src="../../../../../images/remove-logo.svg" class="mb-6 mx-auto w-full"/>

                <h1 class="hero hero-s">Logo & Copyright</h1>

                <p class="body body-m">
                    Solltet Ihr den Wunsch haben, dann könnt Ihr das Logo und den Copyright-Hinweis von <strong>Mi
                    Boda</strong> auch einfach wieder hinzufügen.
                </p>

                <form class="mt-6" @submit.prevent="form.post(route('app.design_invitation.edit.blocks.logo', {
          card: card.slug
        }))">

                    <template v-if="!exists">
                        <ConfirmationDialog ref="deleteDialogRef">
                            <template v-slot:description>
                                <p class="body body-m mb-6">
                                    Falls Ihr Eure Meinung später ändern solltet, könnt Ihr dieses Element auch wieder
                                    aktivieren.
                                </p>
                            </template>
                            <template v-slot:button>
                                <form class="w-full"
                                      @submit.prevent="form.post(route('app.design_invitation.edit.blocks.logo.delete', { card: card.slug }))">
                                    <ButtonComponent :processing="form.processing"
                                                     class="button button-primary w-full h-full" type="submit">
                                        Bestätigen
                                    </ButtonComponent>
                                </form>
                            </template>
                        </ConfirmationDialog>

                        <button class="hide-block text-base font-bold text-flower-500 mt-8" type="button"
                                @click="removeLogoDialog">
                            Logo entfernen für 4,99 &euro;
                        </button>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit" v-if="exists">
                            Hinzufügen
                        </ButtonComponent>

                        <LinkComponent :href="route('app.design_invitation.edit', { card: card.slug })"
                                       class="button button-primary w-full" v-if="!exists">
                            Abbrechen
                        </LinkComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
