<script setup lang="ts">
import {PropType, reactive} from 'vue';
import draggable from 'vuedraggable';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";
import Card from "@/Shared/Card.vue";
import BackLink from "@/Shared/BackLink.vue";
import IconLink from "@/Shared/IconLink.vue";
import {usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from "@/Types/inertia";

const {route, isSameAppVersion, isMinimumAppVersion, __} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    orderedBlocks: {
        type: Array as PropType<Array<App.Models.Card.Block>>,
        required: true,
        default: [],
    },
    backToRoute: {
        type: String,
        required: true,
    },
    hasInvitationText: {
        type: Boolean,
        required: true,
    },
    hasAgenda: {
        type: Boolean,
        required: true,
    },
    hasGroomsmen: {
        type: Boolean,
        required: true,
    },
    hasDresscode: {
        type: Boolean,
        required: true,
    },
    hasWeddingGames: {
        type: Boolean,
        required: true,
    },
    hasGiftWishes: {
        type: Boolean,
        required: true,
    },
    hasDirections: {
        type: Boolean,
        required: true,
    },
    hasArrival: {
        type: Boolean,
        required: true,
    },
    hasAccommodation: {
        type: Boolean,
        required: true,
    },
    hasKids: {
        type: Boolean,
        required: true,
    },
    hasGallery: {
        type: Boolean,
        required: true,
    },
    hasClosingText: {
        type: Boolean,
        required: true,
    },
    hasFood: {
        type: Boolean,
        required: true,
    },
    hasPhoto: {
        type: Boolean,
        required: true,
    },
    hasLogo: {
        type: Boolean,
        required: true,
    }
});

let blocks = reactive<App.Models.Card.Block[]>([]);

props.orderedBlocks.forEach((block: App.Models.Card.Block) => {
    // Ablauf
    if (block.type === 'agenda') {
        blocks.push({
            id: block.id,
            name: 'Ablauf',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.agenda',
        });
    }

    // Dresscode
    if (block.type === 'dresscode') {
        blocks.push({
            id: block.id,
            name: 'Dresscode',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.dresscode',
        });
    }

    // Hochzeitsspiele
    if (block.type === 'weddingGames') {
        blocks.push({
            id: block.id,
            name: 'Hochzeitsspiele',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.wedding_games',
        });
    }

    // Geschenkwünsche
    if (block.type === 'giftWishes') {
        blocks.push({
            id: block.id,
            name: 'Geschenkwünsche',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.gift_wishes',
        });
    }

    // Trauzeugen
    if (block.type === 'groomsmen') {
        blocks.push({
            id: block.id,
            name: 'Trauzeugen',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.groomsmen',
        });
    }

    // Kinder
    if (block.type === 'kids') {
        blocks.push({
            id: block.id,
            name: 'Kinder',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.kids',
        });
    }

    // Unterkunft
    if (block.type === 'accommodation') {
        blocks.push({
            id: block.id,
            name: 'Unterkunft',
            type: block.type,
            route: 'app.design_invitation.edit.blocks.accommodation',
        });
    }

    // Freier Text
    if (block.type === 'freeText') {
        blocks.push({
            id: block.id,
            name: block.data.header,
            type: block.type,
            route: 'app.design_invitation.edit.blocks.free_text.edit',
        });
    }
});

const handleChange = (updatedBlocks: App.Models.Card.Block[]) => {
    window.axios.post(route('app.design_invitation.edit.blocks.edit.change_order', {
        card: props.card.slug
    }), {
        blocks: updatedBlocks,
    }).then(() => {
        if (usePage<PageWithSharedProps>().props.flash.success) {
            usePage<PageWithSharedProps>().props.flash.success.push(
                'Reihenfolge der Inhalte wurde aktualisiert.'
            );

            blocks.length = 0;
            updatedBlocks.forEach(block => blocks.push(block));
        }
    });
};
</script>

<template>
    <AppHead title="Karte bearbeiten"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template #outer>
                <BackLink :href="backToRoute">
                    Zurück
                </BackLink>
            </template>

            <template #inner>
                <h1 class="hero hero-s mb-8">Karte bearbeiten</h1>

                <h2 class="headline headline-s text-raven-700 mb-3">Inhalte</h2>

                <ul class="m-0 mb-6">
                    <li v-if="card.is_general_invitation && card.is_an_invitation_card"
                        class="body body-s element pl-8">
                        Anrede

                        <IconLink :href="route('app.design_invitation.edit.salutation', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li class="body body-s element" :class="{'pl-8' : card.is_an_invitation_card}">
                        Vorwort

                        <IconLink :href="route('app.design_invitation.edit.foreword', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li :class="{'element-inactive pl-0' : !hasInvitationText}" class="body body-s element pl-8"
                        v-if="card.is_an_invitation_card">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Einladungstext

                        <IconLink :href="route('app.design_invitation.edit.blocks.invitation_text', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <draggable :modelValue="blocks" handle=".drag" itemKey="id" @update:modelValue="handleChange">
                        <template #item="{ element }">
                            <li class="body body-s element first:border-t-0">
                                <img class="drag w-[24px] mr-2 cursor-pointer" src="../../../../images/icons/move.svg"/>
                                {{ element.name }}

                                <IconLink
                                    :href="element.type === 'freeText' ? route(element.route, { card: card.slug, id: element.id }) : route(element.route, { card: card.slug })">
                                    <template #icon>
                                        <div class="flex items-center">
                                            <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                            <img class="add" src="../../../../images/icons/plus.svg"/>
                                        </div>
                                    </template>
                                </IconLink>
                            </li>
                        </template>
                    </draggable>

                    <li v-if="!hasAgenda && card.is_an_invitation_card" class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Ablauf

                        <IconLink :href="route('app.design_invitation.edit.blocks.agenda', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasDresscode && card.is_an_invitation_card" class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Dresscode

                        <IconLink :href="route('app.design_invitation.edit.blocks.dresscode', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasWeddingGames && card.is_an_invitation_card"
                        class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Hochzeitsspiele

                        <IconLink :href="route('app.design_invitation.edit.blocks.wedding_games', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasGiftWishes && card.is_an_invitation_card"
                        class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Geschenkwünsche

                        <IconLink :href="route('app.design_invitation.edit.blocks.gift_wishes', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasGroomsmen && card.is_an_invitation_card" class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Trauzeugen

                        <IconLink :href="route('app.design_invitation.edit.blocks.groomsmen', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasKids && card.is_an_invitation_card" class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Kinder

                        <IconLink :href="route('app.design_invitation.edit.blocks.kids', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="!hasAccommodation && card.is_an_invitation_card"
                        class="body body-s element element-inactive">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Unterkunft

                        <IconLink :href="route('app.design_invitation.edit.blocks.accommodation', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="card.is_a_thank_you_card"
                        :class="{'element-inactive pl-0' : !hasGallery}"
                        class="body body-s element pl-8">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Bildergalerie

                        <IconLink :href="route('app.design_invitation.edit.blocks.gallery', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li :class="{'element-inactive pl-0' : !hasClosingText}" class="body body-s element pl-8"
                        v-if="card.is_an_invitation_card">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Verabschiedung

                        <IconLink :href="route('app.design_invitation.edit.blocks.closing_text', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="isSameAppVersion(card, 1) && card.is_an_invitation_card"
                        :class="{'element-inactive pl-0' : !hasDirections}"
                        class="body body-s element pl-8">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Anfahrtsbeschreibung

                        <IconLink :href="route('app.design_invitation.edit.blocks.directions', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li v-if="isMinimumAppVersion(card, 2) && card.is_an_invitation_card"
                        :class="{'element-inactive pl-0' : !hasArrival}"
                        class="body body-s element pl-8">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Anfahrt

                        <IconLink :href="route('app.design_invitation.edit.blocks.arrival', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li
                        :class="{'element-inactive pl-0' : !hasPhoto}"
                        class="body body-s element pl-8">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Persönliches Foto

                        <img src="../../../../images/paid-icon.svg" class="ml-2" v-if="hasPhoto"/>

                        <IconLink :href="route('app.design_invitation.edit.blocks.photo', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li
                        :class="{'element-inactive pl-0' : hasLogo}"
                        class="body body-s element pl-8">
                        <img class="eye" src="../../../../images/icons/eye-off.svg"/>
                        Logo & Copyright

                        <img src="../../../../images/paid-icon.svg" class="ml-2" v-if="hasLogo"/>

                        <IconLink :href="route('app.design_invitation.edit.blocks.logo', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                <img class="add" src="../../../../images/icons/plus.svg"/>
                            </template>
                        </IconLink>
                    </li>

                </ul>

                <template v-if="card.is_an_invitation_card">
                    <h2 class="headline headline-s text-raven-700 mb-3">Rückmeldung</h2>

                    <ul class="m-0 mb-6">
                        <li class="body body-s element pl-8">
                            Planungshinweis

                            <IconLink :href="route('app.design_invitation.edit.feedback.text', { card: card.slug })">
                                <template v-slot:icon>
                                    <img src="../../../../images/icons/edit-2.svg" alt="edit-2.svg"/>
                                </template>
                            </IconLink>
                        </li>

                        <li :class="{'element-inactive pl-0' : !hasFood}" class="body body-s element pl-8">
                            <div class="flex">
                                <img class="eye" src="../../../../images/icons/eye-off.svg" alt="eye-off.svg"/>
                                Menüauswahl
                            </div>

                            <IconLink :href="route('app.design_invitation.edit.food', {
              card: card.slug
            })">
                                <template v-slot:icon>
                                    <img class="edit" src="../../../../images/icons/edit-2.svg"/>
                                    <img class="add" src="../../../../images/icons/plus.svg"/>
                                </template>
                            </IconLink>
                        </li>

                    </ul>
                </template>

                <h2 class="headline headline-s text-raven-700 mb-3">Einstellungen</h2>

                <ul class="m-0 mb-8">
                    <li class="body body-s element" v-if="card.is_a_save_the_date_card">
                        Datum der Hochzeit anpassen

                        <IconLink :href="route('app.design_invitation.edit.date_of_wedding', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <template v-if="card.is_an_invitation_card">
                        <li class="body body-s element">
                            Wozu ladet Ihr ein?

                            <IconLink :href="route('app.design_invitation.edit.wedding_schedule', {
              card: card.slug,
            })">
                                <template v-slot:icon>
                                    <img src="../../../../images/icons/edit-2.svg"/>
                                </template>
                            </IconLink>
                        </li>

                        <template v-for="(schedule, index) in card.wedding_schedules">
                            <li class="body body-s element">
                                Wann und Wo findet {{ __('wedding_schedule.' + schedule.type) }} statt?

                                <IconLink :href="route('app.design_invitation.edit.wedding_schedule.single', {
              card: card.slug,
              weddingSchedule: schedule.slug
            })">
                                    <template v-slot:icon>
                                        <img src="../../../../images/icons/edit-2.svg"/>
                                    </template>
                                </IconLink>
                            </li>
                        </template>
                    </template>

                    <li class="body body-s element" v-if="card.is_an_invitation_card">
                        Einladungsform ändern

                        <IconLink
                            :href="route('app.design_invitation.edit.shipping_type', {
                  card: card.slug
                })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li class="body body-s element">
                        Wie lauten Eure Vornamen?

                        <IconLink :href="route('app.design_invitation.edit.bridal_couple', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li class="body body-s element">
                        Design anpassen

                        <IconLink :href="route('app.design_invitation.edit.design', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>

                    <li class="body body-s element">
                        Sprache ändern

                        <IconLink :href="route('app.design_invitation.edit.language', {
              card: card.slug
            })">
                            <template v-slot:icon>
                                <img src="../../../../images/icons/edit-2.svg"/>
                            </template>
                        </IconLink>
                    </li>
                </ul>

                <ButtonGroup class="md:mt-10" v-if="card.is_an_invitation_card">
                    <LinkComponent :href="route('app.design_invitation.edit.blocks.free_text', { card: card.slug })"
                                   class="button button-secondary w-full">
                        Neuen Inhalt hinzufügen
                    </LinkComponent>
                </ButtonGroup>
            </template>
        </Card>
    </layout>
</template>

<style scoped lang="scss">
ul {
    li {
        &.element {
            @apply list-none py-3 border-b border-gray flex justify-start items-center font-bold text-flower-700 mr-4;

            &:first-child {
                @apply border-t;
            }

            a {
                @apply ml-auto;
            }

            .add,
            .eye {
                display: none;
            }

            &.element-inactive {
                @apply text-raven-700;

                .eye {
                    @apply mr-4;
                }

                .add,
                .eye {
                    display: block;
                }

                .edit {
                    display: none;
                }
            }
        }
    }
}
</style>
