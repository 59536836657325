<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AddressComponent from '@/Shared/Forms/AddressComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const {route, __} = useMixin();

const props = defineProps({
    type: {
        type: String,
        required: true
    },
    date: {
        type: String,
    },
    hour: {
        type: String,
    },
    location: {
        type: String,
    }
});

const form = useForm({
    type: props.type,
    date: props.date,
    hour: props.hour,
    location: props.location,
});
</script>

<template>
    <AppHead :title="'Wann und Wo findet ' + __('wedding_schedule.' + type) + ' statt?'"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.dashboard.new.wedding_schedule.single.back', { type: type })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wann und Wo findet {{ __('wedding_schedule.' + type) }} statt?</h1>

                <form class="mt-6" @submit.prevent="form.post(route('app.dashboard.new.wedding_schedule.single', { type: type }), {
                    preserveState: (page) => Object.keys(page.props.errors).length,
              })">
                    <InputComponent v-model:value="form.date" :error="form.errors.date"
                                    name="date"
                                    type="date">
                        Datum
                    </InputComponent>

                    <InputComponent v-model:value="form.hour" :error="form.errors.hour"
                                    name="hour"
                                    placeholder="Uhrzeit eingeben"
                                    type="time">
                        Uhrzeit
                    </InputComponent>

                    <AddressComponent v-model:value="form.location" :error="form.errors.location"
                                      name="location" placeholder="Adresse eingeben">
                        Adresse
                    </AddressComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Weiter
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
