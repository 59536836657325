<script setup lang="ts">
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import {useForm} from "@inertiajs/vue3";
import {computed, PropType, ref} from "vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    }
});

const form = useForm({});

const price = computed(() => {
    let price = 0;

    if (!props.card.paid_at && props.card.is_an_invitation_card) {
        price = 49.99;
        if (props.card.user.has_paid_already) {
            price = 29.99;
        }
    }

    if (!props.card.paid_at && props.card.is_a_thank_you_card) {
        price = 24.99;
    }

    if (props.card.has_photo_block && !props.card.has_photo_block_paid) {
        price += 14.99;
    }

    if (props.card.has_logo_block && !props.card.has_logo_block_paid) {
        price += 4.99;
    }

    return price;
});

const formattedPrice = price.value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});
const priceStrikeThrough = (price.value + 10).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const isVisible = ref(false);
const processing = ref(false);

const open = () => {
    isVisible.value = true;
};

const close = () => {
    processing.value = true;
    setTimeout(() => {
        isVisible.value = false;
    }, 100);
};

defineExpose({
    open
});
</script>

<template>
    <transition>
        <div v-if="isVisible">

            <template v-if="!card.user.email_verified_at">
                <div
                    class="fixed inset-0 top-0 left-0 right-0 bottom-0 z-100 w-full h-screen bg-black opacity-30"></div>
                <div
                    class="fixed z-100 left-0 right-0 bottom-0 w-full bg-white mx-auto text-center rounded-tl-lg rounded-tr-lg md:w-[500px] md:h-[275px] md:top-1/2 md:bottom-1/2 md:-translate-y-2/4 md:rounded-lg">
                    <div class="absolute right-4 top-4 cursor-pointer" @click="close">
                        <img src="../../../../images/icons/icon-x.svg"/>
                    </div>
                    <div class="pt-6 px-6 text-left">
                        <div class="body body-xs block mb-4" v-if="card.is_an_invitation_card">
                            Schritt 1 von 2
                        </div>

                        <h2 class="headline headline-l mb-3">
                            E-Mail Adresse noch nicht bestätigt
                        </h2>

                        <p class="body body-s pb-6">
                            Damit Ihr Eure Einladungen verschicken könnt, benötigt Ihr eine gültige Email Adresse. Ihr
                            erhaltet eine
                            Bestätigungsmail. Bitte schaut in Euer Postfach und vergesst auch den Spam Ordner nicht.
                        </p>
                    </div>

                    <span class="mt-4 block w-full h-[2px] bg-light-gray"></span>
                    <div class="p-4">
                        <form @submit.prevent="form.post(route('app.verification.resend', { resendFromShipping: true, redirectToShippingCardSlug: card.slug }), {
              preserveScroll: true,
            })">
                            <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                             type="submit">
                                E-Mail erneut senden
                            </ButtonComponent>
                        </form>
                    </div>
                </div>
            </template>

            <template v-if="(card.user.email_verified_at && !card.has_paid)">
                <div
                    class="fixed inset-0 top-0 left-0 right-0 bottom-0 z-100 w-full h-screen bg-black opacity-30"></div>
                <div
                    class="fixed z-100 left-0 right-0 bottom-0 w-full bg-white mx-auto text-center rounded-tl-lg rounded-tr-lg md:w-[500px] md:h-[525px] md:top-1/2 md:bottom-1/2 md:-translate-y-2/4 md:rounded-lg">
                    <div class="absolute right-4 top-4 cursor-pointer" @click="close">
                        <img src="../../../../images/icons/icon-x.svg"/>
                    </div>
                    <div class="pt-6 px-4">
                        <div class="body body-xs block mb-4 text-left">
                            Schritt 2 von 2
                        </div>

                        <h2 class="headline headline-l mb-7">
                            Versand freischalten
                        </h2>

                        <div>
                            <div class="grid grid-cols-3 mb-5 items-center">
                                <div class="text-left">
                                    <span class="headline headline-l">Gesamtpreis</span>
                                </div>

                                <div class="col-span-2 flex items-center justify-end">
                                    <div
                                        class="line-through-diagonal-small inline-block mr-3 headline headline-l font-black bg-flower-100 text-flower-500 py-1 mb-0 px-3 rounded-lg font-body">
                                        {{ priceStrikeThrough }} &euro;
                                    </div>

                                    <div class="inline-block headline headline-l mb-0">
                                        {{ formattedPrice }} &euro;
                                        {{ !card.paid_at && card.user.has_paid_already ? ' *' : '' }}
                                    </div>
                                </div>
                            </div>

                            <hr class="h-[2px] w-full bg-flower-100 border-0"/>

                            <div class="body body-s py-4 grid grid-cols-1 gap-1">
                                <div class="grid grid-cols-2" v-if="card.is_an_invitation_card && !card.paid_at">
                                    <span class="text-left">Versand freischalten</span>
                                    <span class="text-right">{{
                                            card.user.has_paid_already ? '29,99' : '49,99'
                                        }} &euro;</span>
                                </div>

                                <div class="grid grid-cols-2" v-if="card.is_a_thank_you_card && !card.paid_at">
                                    <span class="text-left">Versand freischalten</span>
                                    <span class="text-right">24,99 &euro;</span>
                                </div>

                                <div class="grid grid-cols-2"
                                     v-if="card.has_photo_block && !card.has_photo_block_paid">
                                    <span class="text-left">Persönliches Foto</span>
                                    <span class="text-right">14,99 &euro;</span>
                                </div>

                                <div class="grid grid-cols-2"
                                     v-if="card.has_logo_block && !card.has_logo_block_paid">
                                    <span class="text-left">Logo & Copyright </span>
                                    <span class="text-right">4,99 &euro;</span>
                                </div>
                            </div>

                            <hr class="h-[2px] w-full bg-flower-100 border-0"/>
                        </div>

                        <div class="mt-4 flex justify-center items-center gap-3">

                        </div>

                        <span v-if="card.is_an_invitation_card && !card.paid_at && card.user.has_paid_already"
                              class="block body body-m text-flower-500 font-display mt-2 w-[300px] mx-auto">
            *Angebot wurde reduziert, da Ihr bereits ein anderes Produkt gekauft habt.
          </span>


                        <div class="px-4">
                            <ul class="m-0 p-0 mt-4 text-left">
                                <li class="w-full body body-m list-none inline-flex font-bold">
                                    <img class="mr-1" src="../../../../images/list-arrow.svg"/>
                                    14 Tage Geld-zurück-Garantie
                                </li>
                                <li class="w-full body body-m list-none inline-flex">
                                    <img class="mr-1" src="../../../../images/list-arrow.svg"/>
                                    Unkomplizierte Einladungsgestaltung
                                </li>

                                <li class="w-full body body-m list-none inline-flex">
                                    <img class="mr-1" src="../../../../images/list-arrow.svg"/>
                                    Keine hohen Druck- oder Versandkosten.
                                </li>

                                <li class="w-full body body-m list-none inline-flex">
                                    <img class="mr-1" src="../../../../images/list-arrow.svg"/>
                                    Übersicht über Zusagen und Absagen.
                                </li>

                                <li class="w-full body body-m list-none inline-flex">
                                    <img class="mr-1" src="../../../../images/list-arrow.svg"/>
                                    Stilvoll feiern, die Umwelt lieben.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <span class="mt-4 block w-full h-[2px] bg-light-gray"></span>
                    <div class="p-4">
                        <form @submit.prevent="form.post(route('app.shipping.pay', { card: card.slug }))">
                            <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                             type="submit">
                                Jetzt bezahlen
                            </ButtonComponent>
                        </form>
                    </div>
                </div>
            </template>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

