<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {Link} from '@inertiajs/vue3';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    currentRoute: {
        type: String,
        required: true,
    }
});
</script>

<template>
    <div class="bg-white rounded-lg p-4 shadow-md mb-3 mx-auto md:w-[525px]">
        <nav :class="card.is_personalized_invitation ? 'justify-between' : 'justify-start'" class="flex items-center">
            <Link v-if="card.is_personalized_invitation && card.is_an_invitation_card"
                          :class="currentRoute === 'guest_list' ? 'text-flower-500 border-b-2 border-flower-500' : 'text-raven-500'"
                          :href="route('app.guest_list', { card: card.slug })"
                          class="tab-link">
                Gäste
            </Link>

            <Link
                :class="currentRoute === 'shipping' ? 'text-flower-500 border-b-2 border-flower-500' : 'text-raven-500'"
                :href="route('app.shipping', { card: card.slug })"
                class="tab-link"
                v-bind:class="{ 'mr-5': card.is_general_invitation }">
                Versand
            </Link>

            <Link v-if="card.is_an_invitation_card"
                          :class="currentRoute === 'feedback' ? 'text-flower-500 border-b-2 border-flower-500' : 'text-raven-500'"
                          :href="route('app.feedback', { card: card.slug })"
                          class="tab-link">
                Rückmeldungen
            </Link>
        </nav>
    </div>
</template>

<style lang="scss">
.tab-link {
    @apply text-xl font-bold;
}

@media screen and (max-width: 375px) {
    .tab-link {
        @apply text-base;
    }
}
</style>
