<script setup lang="ts">
import {useMixin} from "@/mixins";
import Modal from '@/Pages/App/Design/Blocks/TextSuggestions/Modal.vue';
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import {useForm} from "@inertiajs/vue3";
import {PropType} from "vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import IconLink from "@/Shared/IconLink.vue";

const {route, __} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    suggestions: {
        type: Array,
        required: true,
    },
    component: {
        type: String,
        required: true,
    }
});

const form = useForm({
    suggestion: props.suggestions[0],
    component: props.component,
});

const updateSuggestion = (suggestion: string) => {
    form.suggestion = suggestion;
};

</script>

<template>
    <Modal>
        <template #title>
            <div class="flex items-center">
                Textvorschlag wählen

                <!--
                <form
                    class="inline-block ml-2"
                    @submit.prevent="form.post(route(`app.design_invitation.edit.${props.component}.text_suggestions.select`, { card: card}))">
                    <IconLink href="">
                        <template v-slot:icon>
                            <img src="../../../../../../images/icons/refresh-ccw.svg" alt="refresh-ccw.svg"/>
                        </template>
                    </IconLink>
                </form>
                -->
            </div>
        </template>

        <template #default>
            <template v-for="(suggestion, index) in suggestions">
                <RadioButtonComponent :id="`suggestions_${index}`" :error="form.errors.suggestion"
                                      :model-value="form.suggestion"
                                      name="suggestion" :value="suggestion" @onUpdateModelValue="updateSuggestion"
                                      class="mb-4">
                    <template v-slot:label>Vorschlag {{ index + 1 }}</template>
                    <template v-slot:description>
                        <span v-html="suggestion"/>
                    </template>
                </RadioButtonComponent>
            </template>
        </template>

        <template #button>
            <form
                class="w-full"
                @submit.prevent="form.post(route(`app.design_invitation.edit.${props.component}.text_suggestions.select`, { card: card}))">
                <ButtonComponent :processing="form.processing"
                                 class="button button-primary w-full h-full"
                                 type="submit">
                    Übernehmen
                </ButtonComponent>
            </form>
        </template>
    </Modal>
</template>
