<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import TipTap from "@/Shared/TipTap.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import AppHead from "@/Shared/AppHead.vue";
import {PropType} from "vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    block: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    block: {
        salutation: props.block.salutation ? props.block.salutation : null,
        text: props.block.text,
    },
});

const updateSalutation = (salutation: string) => {
    form.block.salutation = salutation;
};

const updateText = (text: string) => {
    form.block.text = text;
};
</script>

<template>
    <AppHead title="Anrede bearbeiten"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Anrede bearbeiten</h1>

                <form class="mt-6"
                      @submit.prevent="form.post(route('app.design_invitation.edit.salutation', { card: card.slug }), { preserveScroll: true })">
                    <p class="body body-m">Liebe...</p>

                    <RadioButtonComponent id="guests" :error="form.errors['block.salutation']"
                                          :model-value="form.block.salutation"
                                          name="type" value="guests"
                                          @onUpdateModelValue="updateSalutation">
                        <template v-slot:label>Gäste</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="family" :error="form.errors['block.salutation']"
                                          :model-value="form.block.salutation"
                                          name="type" value="family"
                                          @onUpdateModelValue="updateSalutation">
                        <template v-slot:label>Familie</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="friends" :error="form.errors['block.salutation']"
                                          :model-value="form.block.salutation"
                                          name="type" value="friends"
                                          @onUpdateModelValue="updateSalutation">
                        <template v-slot:label>Freunde</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="family_and_friends" :error="form.errors['block.salutation']"
                                          :model-value="form.block.salutation"
                                          name="type" value="family_and_friends"
                                          @onUpdateModelValue="updateSalutation">
                        <template v-slot:label>Familie & Freunde</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="custom" :error="form.errors['block.salutation']"
                                          :model-value="form.block.salutation"
                                          class="mb-[6px]" name="type"
                                          value="custom"
                                          @onUpdateModelValue="updateSalutation">
                        <template v-slot:label>Eigene Beschreibung</template>
                    </RadioButtonComponent>

                    <template v-if="form.block.salutation === 'custom'">
                        <TipTap name="text" :error="form.errors['block.text']" :model-value="form.block.text"
                                @onUpdateModelValue="updateText"/>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
