<script setup lang="ts">
import { useForm } from '@inertiajs/vue3';
import Layout from "@/Layouts/Default.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import BackLink from "@/Shared/BackLink.vue";
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const form = useForm({
    action: null,
});

function updateAction(action: string) {
    form.action = action;
}
</script>

<template>
    <AppHead title="Neu oder duplizieren?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.dashboard.card.choose_card_type')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Neu oder duplizieren?</h1>

                <form class="mt-6" @submit.prevent="form.post(route('app.dashboard.card.new_or_duplicate'))">
                    <RadioButtonComponent id="new" :error="form.errors.action" :model-value="form.action"
                                          name="action" value="new" @onUpdateModelValue="updateAction">
                        <template v-slot:label>Neue Karte erstellen</template>
                        <template v-slot:description>
                            Erstelle eine neue Karte von Grund auf neu. Du kannst aus einer Vielzahl von Vorlagen wählen.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="duplicate" :error="form.errors.action" :model-value="form.action"
                                          name="action" value="duplicate" @onUpdateModelValue="updateAction">
                        <template v-slot:label>Vorhandene Karte duplizieren</template>
                        <template v-slot:description>
                            Spar dir ein bisschen Zeit. Dupliziere eine bereits vorhandene Karte. Alles wird übernommen.
                        </template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Auswählen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
