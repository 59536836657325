<script setup lang="ts">
import {Link} from '@inertiajs/vue3';
import {useMixin} from "@/mixins";

const {route} = useMixin();
</script>

<template>
    <footer class="py-9 px-4 bg-flower-50">
        <div class="relative w-full bg-white pt-12 pb-6 rounded-lg">
            <nav class="px-8 md:grid md:grid-cols-2 md:gap-[100px] md:px-10 lg:grid-cols-4 lg:px-12">
                <div>
                    <h3 class="headline headline-l text-flower-900 mb-5">
                        Produkte
                    </h3>
                    <ul class="m-0 p-0">
                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('app.start', { type: 'invitation' })"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Hochzeitseinladung
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('app.start', { type: 'saveTheDate' })"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Save the Date
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700">
                            <span class="text-[#6f7f9f]">
                                Danksagungen <span class="badge m-0 ml-2 bg-light-gray body body-xs text-[#6f7f9f]">Bald verfügbar</span>
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="mt-8 md:mt-0">
                    <h3 class="headline headline-l text-flower-900 mb-5">
                        Funktionen
                    </h3>
                    <ul class="m-0 p-0">
                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.functions')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Einfache Gestaltung
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.functions')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Verschiedene Einladungsformen
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.functions')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Digitaler Versand
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700">
                            <Link :href="route('web.functions')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Automatisierte Rückmeldungen
                            </Link>
                        </li>
                    </ul>
                </div>

                <div class="mt-8 lg:mt-0">
                    <h3 class="headline headline-l text-flower-900 mb-5">
                        Support
                    </h3>
                    <ul class="m-0 p-0">
                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.pricing')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Preise
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.faq')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                FAQ
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.privacy')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Datenschutz
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700">
                            <a href="javascript:openAxeptioCookies();"
                               class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Cookies
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="mt-8 lg:mt-0">
                    <h3 class="headline headline-l text-flower-900 mb-5">
                        Unternehmen
                    </h3>
                    <ul class="m-0 p-0">
                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.imprint')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                Impressum
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700 mb-3">
                            <Link :href="route('web.conditions')"
                                  class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1">
                                AGB
                            </Link>
                        </li>

                        <li class="list-none body body-m text-flower-700">
                            <a class="hover:bg-flower-50 hover:text-flower-500 hover:p-1 hover:-ml-1"
                               href="https://climate.stripe.com/l1mk9r"
                               target="_blank">
                                Klimaschutz
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div class="mt-12 border-t-2 border-flower-50">
                <div class="px-8 pt-6 md:flex md:justify-between md:items-center">
                    <Link :href="route('web.index')" class="block mb-6 md:m-0">
                        <img alt="Das Logo von Mi Boda. Der Name Mi Boda und daneben zwei Herzen."
                             class="mx-auto h-[20px] md:h-[30px]" src="../../images/logo.svg"/>
                    </Link>

                    <ul class="flex justify-center mb-0">
                        <li class="list-none mr-2">
                            <a href="https://www.facebook.com/MiBoda.Hochzeitseinladungen/" target="_blank">
                                <img alt="facebook" src="../../images/icon-facebook.svg"/>
                            </a>
                        </li>

                        <li class="list-none mr-2">
                            <a href="https://www.pinterest.de/MiBodaHochzeitseinladungen/" target="_blank">
                                <img alt="Pinterest" src="../../images/icon-pinterest.svg"/>
                            </a>
                        </li>

                        <li class="list-none">
                            <a href="https://www.instagram.com/miboda.hochzeitseinladungen/" target="_blank">
                                <img alt="Instagram" src="../../images/icon-instagram.svg"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
