<script setup lang="ts">
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import Card from '@/Shared/Card.vue';
import AppHead from '@/Shared/AppHead.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();
</script>

<template>
    <AppHead title="Nur noch einen Schritt..."/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.language')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Nur noch einen Schritt...</h1>

                <p class="body body-m mb-6">
                    Wähle zwischen E-Mail-Anmeldung oder Anmeldung über Dienste wie Apple, Google oder Facebook, gib
                    deine Informationen ein und akzeptiere die Bedingungen, um dich zu registrieren.
                </p>

                <LinkComponent :href="route('app.onboarding.register.email')"
                               class="w-full button button-secondary">
                    <img src="@/../images/login-with-mail.svg" alt="Registrieren mit E-Mail" class="mr-2"/>
                    <span>Registrieren mit E-Mail</span>
                </LinkComponent>

                <div class="relative w-full my-8 flex-grow-0 bg-gray h-[1px]">
                    <span
                        class="text-xs text-raven-500 absolute left-1/2 right-1/2 w-[41px] text-center bg-white top-[-8px] ml-[-20px]">oder</span>
                </div>

                <a :href="route('app.onboarding.register.apple')"
                   class="w-full button button-secondary mb-2">
                    <img src="@/../images/login-with-apple.svg" alt="Registrieren mit Apple" class="mr-2"/>
                    <span>Registrieren mit Apple</span>
                </a>

                <a :href="route('app.onboarding.register.google')"
                   class="w-full button button-secondary mb-2">
                    <img src="@/../images/login-with-google.svg" alt="Registrieren mit Google" class="mr-2"/>
                    <span>Registrieren mit Google</span>
                </a>

                <a :href="route('app.onboarding.register.facebook')" class="w-full button button-secondary">
                    <img src="@/../images/login-with-facebook.svg" alt="Registrieren mit Facebook" class="mr-2"/>
                    <span>Registrieren mit Facebook</span>
                </a>
            </template>
        </Card>
    </layout>
</template>
