<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";

const props = defineProps<{
    type: string;
}>();

const hidden = ref(false);

onMounted(() => {
    setTimeout(() => {
        hidden.value = true;
    }, 3500);
});

watch(() => usePage().flash, () => {
    hidden.value = false;
}, {deep: true});

const close = () => {
    hidden.value = true;
};
</script>

<template>
    <div
        v-if="!hidden"
        :class="{ 'bg-error': type === 'error' }"
        class="notification flex justify-between text-sm text-white p-3 bg-raven-700 fixed rounded-lg top-4 left-4 right-4 shadow-md md:w-[525px] md:mx-auto md:top-14"
    >
        <slot/>
        <span class="cursor-pointer text-right font-bold" @click="close">OK</span>
    </div>
</template>

<style scoped lang="scss">
.notification {
    z-index: 999 !important;
}
</style>
