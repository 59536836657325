<script setup lang="ts">
import {ref} from 'vue';
import {toClipboard} from '@soerenmartius/vue3-clipboard';
import Paywall from '../../Pages/App/Shipping/Paywall.vue';
import ConfirmationDialog from "@/Shared/ConfirmationDialog.vue";
import {usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from '@/Types/inertia';

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    link: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
    hasPaid: {
        type: Boolean,
        required: true,
        default: false,
    },
});

const paywall = ref<InstanceType<typeof ConfirmationDialog>>(null)

const triggerMail = () => {
    if (!props.hasPaid) {
        paywall.value.open();

        return false;
    }

    window.location.href = 'mailto:?body=' + props.link;
};

const triggerWhatsApp = () => {
    if (!props.hasPaid) {
        paywall.value.open();

        return false;
    }

    window.location.href = 'whatsapp://send?text=' + props.link;
};

const triggerShare = () => {
    if (!props.hasPaid) {
        paywall.value.open();

        return false;
    }

    navigator.share({
        url: props.link,
    }).catch(() => {
        // Ignore error
    });
};

const triggerLink = () => {
    if (!props.hasPaid) {
        paywall.value.open();

        return false;
    }

    addToClipboard();
};

const addToClipboard = () => {
    toClipboard(props.link);

    if (usePage<PageWithSharedProps>().props.flash.success) {
        usePage<PageWithSharedProps>().props.flash.success.push(
            'Link wurde erfolgreich kopiert. Ihr könnt jetzt diesen teilen.'
        );
    }
};
</script>

<template>
    <div>
        <div v-if="type === 'Mail'" class="inline-flex justify-center">
            <a class="w-[64px] h-[64px] bg-flower-500 flex items-center justify-center rounded-xl"
               href="#"
               @click="triggerMail">
                <img src="../../../images/shares/mail-share.svg"/>
            </a>
        </div>

        <div v-if="type === 'WhatsApp'" class="inline-flex justify-center">
            <a class="w-[64px] h-[64px] bg-flower-500 flex items-center justify-center rounded-xl"
               href="#"
               @click="triggerWhatsApp">
                <img src="../../../images/shares/whatsapp-share.svg"/>
            </a>
        </div>

        <div v-if="type === 'Share'" class="inline-flex justify-center">
            <a class="w-[64px] h-[64px] bg-flower-500 flex items-center justify-center rounded-xl"
               href="#"
               @click="triggerShare">
                <img src="../../../images/shares/share.svg"/>
            </a>
        </div>

        <div v-if="type === 'Link'" class="inline-flex justify-center">
            <a class="w-[64px] h-[64px] bg-flower-500 flex items-center justify-center rounded-xl"
               href="#"
               @click="triggerLink">
                <img src="../../../images/shares/link-share.svg"/>
            </a>
        </div>

        <Paywall ref="paywall" :card="card"/>
    </div>
</template>
