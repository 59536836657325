<script setup lang="ts">
import {ref} from 'vue';
import BackLink from "../Shared/BackLink.vue";
import {toClipboard} from '@soerenmartius/vue3-clipboard';
import {usePage} from "@inertiajs/vue3";
import {PageWithSharedProps} from "@/Types/inertia";

const emailVisible = ref(false);

function toggleWhatsApp() {
    window.open('https://wa.me/+4915223208387');
}

function toggleIntercom() {
    window.Intercom('show');
}

function toggleEmail() {
    emailVisible.value = true;
}

function back() {
    emailVisible.value = false;
}

function addToClipboard() {
    toClipboard('support@mi-boda.com');
    // Assuming there's a way to access flash messages within the current setup
    // Adjust the code below as necessary to correctly push the flash message
    if (typeof usePage<PageWithSharedProps>() !== 'undefined' && usePage<PageWithSharedProps>().props.flash && usePage<PageWithSharedProps>().props.flash.success) {
        usePage<PageWithSharedProps>().props.flash.success.push('E-Mail-Adresse wurde erfolgreich in die Zwischenablage kopiert.');
    }
}
</script>

<template>
    <div class="overlay py-6 px-4 bg-white w-full absolute left-0 z-50">
        <div class="container mx-auto">
            <div v-if="!emailVisible">
                <h2 class="hero hero-s mb-6">
                    Wie können wir Euch weiterhelfen?
                </h2>

                <div class="flex items-center border-1 py-3 border-t border-gray cursor-pointer"
                     @click="toggleWhatsApp">
                    <div class="mr-3">
                        <img src="../../images/support-icon-whatsapp.svg"/>
                    </div>
                    <div class="w-full block">
                        <div class="headline headline-s block mb-0" style="padding-bottom: 1px;">
                            WhatsApp Support
                        </div>
                        <div class="body body-xs block">
                            Mo.-Fr. 9-20 Uhr, Sa. nur eingeschränkt verfügbar
                        </div>
                    </div>
                    <div class="mr-2">
                        <img src="../../images/chevron-right-colored.svg"/>
                    </div>
                </div>

                <div class="flex items-center border-1 py-3 border-t border-gray cursor-pointer"
                     @click="toggleIntercom">
                    <div class="mr-3">
                        <img src="../../images/support-icon-intercom.svg"/>
                    </div>
                    <div class="w-full block">
                        <div class="headline headline-s block mb-0" style="padding-bottom: 1px;">
                            Live-Chat Support
                        </div>
                        <div class="body body-xs block">
                            Mo.-Fr. 9-20 Uhr, Sa. nur eingeschränkt verfügbar
                        </div>
                    </div>
                    <div class="mr-2">
                        <img src="../../images/chevron-right-colored.svg"/>
                    </div>
                </div>

                <div class="flex items-center py-3 border-t border-b border-gray cursor-pointer" @click="toggleEmail">
                    <div class="mr-3">
                        <img src="../../images/support-icon-mail.svg"/>
                    </div>
                    <div class="w-full block">
                        <div class="headline headline-s block mb-0" style="padding-bottom: 1px;">
                            E-Mail Support
                        </div>
                        <div class="body body-xs block">
                            Sendet uns eine E-Mail mit Eurem Anliegen.
                        </div>
                    </div>
                    <div class="mr-2">
                        <img src="../../images/chevron-right-colored.svg"/>
                    </div>
                </div>
            </div>

            <div v-if="emailVisible">
                <BackLink @click="back">Zurück</BackLink>

                <h2 class="hero hero-s">
                    E-Mail Support
                </h2>

                <p class="body body-m">
                    Sendet uns eine E-Mail an <a class="underline text-flower-500" href="mailto:support@mi-boda.com">support@mi-boda.com</a>
                    mit Eurem Anliegen und wir melden uns so schnell wie möglich bei Euch zurück.
                </p>

                <button class="copy-mail-address text-base font-bold text-flower-500 mt-2" type="button"
                        @click="addToClipboard">
                    E-Mail-Adresse kopieren
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.overlay {
    height: auto;
    min-height: 100vh;
    top: 57px;
    z-index: 999 !important;
}

.copy-mail-address {
    @apply flex hover:underline;

    &:before {
        @apply w-[24px] h-[24px] inline-block mr-1 bg-flower-500;
        content: "";
        mask: url("../../images/icons/copy.svg") no-repeat;
    }
}
</style>
