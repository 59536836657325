<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import {PropType} from 'vue';
import Layout from '@/Layouts/Default.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import BackLink from '@/Shared/BackLink.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
});

const form = useForm({});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Gästeliste herunterladen"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.feedback', { card: props.card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Gästeliste herunterladen</h1>

                <p class="body body-m">
                    Erhaltet auf einen Blick einen Überblick über Eure tatsächlichen Gäste sowie deren mögliche
                    Sonderwünsche.
                </p>

                <LinkComponent :as-link="true" :href="route('app.export.download', { card: props.card.slug })"
                               class="button button-primary w-full mt-6">
                    PDF herunterladen
                </LinkComponent>

                <ButtonGroup class="md:mt-10">
                    <div class="w-full">
                        <span class="body body-s flex justify-center mb-3">
                            Oder lieber bisschen bequemer?
                        </span>

                        <form @submit.prevent="form.post(route('app.export.mail', { card: props.card.slug }))">
                            <ButtonComponent :processing="form.processing" class="button button-secondary w-full"
                                             type="submit">
                                PDF an unsere E-Mail-Adresse senden
                            </ButtonComponent>
                        </form>
                    </div>
                </ButtonGroup>
            </template>
        </Card>
    </layout>
</template>
