<script setup lang="ts">
import {defineProps, PropType, ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import CheckboxComponent from '@/Shared/Forms/SimpleCheckboxComponent.vue';
import BackLink from '@/Shared/BackLink.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import Tooltip from '@/Shared/Tooltip.vue';
import AppHead from '@/Shared/AppHead.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import {useMixin} from "@/mixins";

const {route, __} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
});

const form = useForm({
    guests: [
        {
            name: null,
        },
    ],
    additional_guests: false,
    salutation: 'female',
    button: 'save',
});

const currentFocus = ref(0);

const submit = () => {
    form.post(route('app.guest_list.create', {card: props.card.slug}), {
        onSuccess: () => {
            form.reset();
        },
    });
};

const clickButton = (name: string) => {
    form.button = name;
};

const addGuest = () => {
    form.guests.push({});
    currentFocus.value = form.guests.length - 1;
};

const removeGuest = (index: number) => {
    form.guests.splice(index, 1);
};

const updateAdditionalGuests = (additionalGuests: boolean) => {
    form.additional_guests = additionalGuests;
};

const updateSalutation = (salutation: string) => {
    form.salutation = salutation;
};
</script>

<template>
    <AppHead title="Gruppe erstellen"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.guest_list', { card: card.slug })">Zurück</BackLink>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Gruppe erstellen</h1>

                <p class="body body-m">
                    Fügt alle Gäste hinzu, die Ihr gemeinsam in einer Einladung einladen möchtet.
                </p>

                <form @submit.prevent="submit">
                    <div v-for="(guest, index) in form.guests" :key="index" class="flex align-items-center">
                        <InputComponent
                            id="index"
                            v-model:value="form.guests[index].name"
                            :error="form.errors[`guests.${index}.name`]"
                            :focus="currentFocus === index"
                            :name="`name_${index}`"
                            class="flex-grow"
                            placeholder="Vorname eintragen"
                            type="text"
                        >
                            Vorname
                        </InputComponent>

                        <button v-if="index !== 0" class="ml-4 mr-2" type="button" @click="removeGuest(index)">
                            <img src="../../../../images/icons/trash-2.svg"/>
                        </button>
                    </div>

                    <button class="add-guest" type="button" @click="addGuest">
                        Weiteren Gast hinzufügen
                    </button>

                    <div class="mt-6">
                        <div class="mb-6">
                            <h2 class="headline headline-s mb-4 flex items-center">
                                Anrede
                                <Tooltip>
                                    <h3 class="headline headline-s">Anrede in der Einladung</h3>
                                    <p class="body body-s">
                                        Hiermit wird bestimmt wie die Anrede aussieht, die in der Einladung verwendet
                                        wird. Entweder die
                                        weibliche oder männliche Form. Entscheidet selbst.
                                    </p>
                                </Tooltip>
                            </h2>

                            <RadioButtonComponent
                                id="female"
                                :error="form.errors.salutation"
                                :model-value="form.salutation"
                                value="female"
                                name="salutation"
                                version="simple"
                                class="mb-2"
                                @onUpdateModelValue="updateSalutation"
                            >
                                {{ __('invitation.salutation.female.example') }}
                            </RadioButtonComponent>

                            <RadioButtonComponent
                                id="male"
                                :error="form.errors.salutation"
                                :model-value="form.salutation"
                                value="male"
                                name="salutation"
                                version="simple"
                                class="mb-2"
                                @onUpdateModelValue="updateSalutation"
                            >
                                {{ __('invitation.salutation.male.example') }}
                            </RadioButtonComponent>

                            <RadioButtonComponent
                                id="non_binary"
                                :error="form.errors.salutation"
                                :model-value="form.salutation"
                                value="non_binary"
                                name="salutation"
                                version="simple"
                                @onUpdateModelValue="updateSalutation"
                            >
                                {{ __('invitation.salutation.non_binary.example') }}
                            </RadioButtonComponent>
                        </div>
                    </div>

                    <div class="mt-6">
                        <div class="mb-6">
                            <h2 class="headline headline-s mb-4 flex items-center">
                                Weitere Einstellungen
                                <Tooltip>
                                    <h3 class="headline headline-s">"Plus Eins"</h3>
                                    <p class="body body-s">
                                        Wenn diese Option aktiviert wird, dürfen die Gäste dieser Einladung noch eine
                                        zusätzliche Begleitung
                                        mitbringen. Diese Begleitung wird dann Euch als zusätzlicher Gast angezeigt.
                                    </p>
                                </Tooltip>
                            </h2>

                            <CheckboxComponent
                                id="additional_guests"
                                :error="form.errors.additional_guests"
                                :model-value="form.additional_guests"
                                :value="true"
                                class="mt-4"
                                name="additional_guests"
                                @onUpdateModelValue="updateAdditionalGuests"
                            >
                                Gäste dürfen eine Begleitung mitbringen
                            </CheckboxComponent>
                        </div>
                    </div>

                    <ButtonGroup class="md:mt-10 grid gap-2">
                        <ButtonComponent
                            :processing="form.processing"
                            class="button button-primary w-full order-2"
                            type="submit"
                            @click="clickButton('save')"
                        >
                            Speichern
                        </ButtonComponent>

                        <ButtonComponent
                            :processing="form.processing"
                            class="button button-secondary w-full order-1"
                            type="submit"
                            @click="clickButton('save_and_new')"
                        >
                            Speichern & nächste Gruppe hinzufügen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
