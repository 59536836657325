<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import BackLink from '@/Shared/BackLink.vue';
import {useMixin} from "@/mixins";

interface FormData {
    email: string | null;
    password: string | null;
}

const props = defineProps({
    sent: {
        type: Boolean,
    }
});

const form = useForm<FormData>({
    email: null,
    password: null,
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Anmelden"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.login')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Anmelden</h1>

                <p class="body body-m">
                    Wenn Ihr Euch schon mal bei <strong>Mi Boda</strong> registriert habt, könnt Ihr Euch mit Eurer
                    E-Mail-Adresse
                    und dem dazugehörigen Passwort hier wieder anmelden.
                </p>

                <div v-if="props.sent">
                    <p class="body body-m font-bold"></p>
                </div>

                <form class="mt-6" @submit.prevent="form.transform((data) => ({
                    ...data,
                    email: data.email?.toLowerCase(),
                  })).post(route('app.login'))">
                    <InputComponent v-model:value="form.email" :error="form.errors.email" name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    type="text">
                        E-Mail-Adresse
                    </InputComponent>

                    <InputComponent v-model:value="form.password" :error="form.errors.password" name="password"
                                    placeholder="Passwort eingeben"
                                    class="mb-2"
                                    type="password">
                        Passwort
                    </InputComponent>

                    <LinkComponent :href="route('app.password.forgot')"
                                   class="block text-flower-700 text-[14px] md:mb-12">
                        <span class="underline">Passwort vergessen?</span>
                    </LinkComponent>

                    <ButtonGroup>
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Anmelden
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
