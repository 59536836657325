<script lang="ts" setup>
import Layout from "@/Layouts/Default.vue";
import DesignCard from "@/Pages/App/Onboarding/Design/DesignCard.vue";
import AppHead from "@/Shared/AppHead.vue";
import Card from "@/Shared/Card.vue";
import BackLink from "@/Shared/BackLink.vue";
import {useMixin} from "@/mixins";

defineProps<{
    designs: Array<App.Models.Design>;
    cardType: string;
    selectedDesign?: string;
}>();

const {route} = useMixin();
</script>

<template>
    <AppHead title="Welches Design wählt Ihr?"/>
    <layout class="md:bg-flower-50">
        <Card>
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.card_type')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s mb-6 md:hero-m">Welches Design wählt Ihr?</h1>
                <div class="grid lg:grid-cols-3 lg:gap-6">
                    <DesignCard v-for="design in designs" :key="design.id" :design="design" :card-type="cardType"
                                :selected-design="selectedDesign"/>
                </div>
            </template>
        </Card>
    </layout>
</template>
