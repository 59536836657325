<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import CheckboxComponent from '@/Shared/Forms/SimpleCheckboxComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

interface FormData {
    email: string;
    notifications_enabled: boolean;
}

const props = defineProps<{
    email: string;
    notifications_enabled: boolean;
}>();

const form = useForm<FormData>({
    email: props.email,
    notifications_enabled: props.notifications_enabled,
});

const updateNotifications = (notifications_enabled: boolean) => {
    form.notifications_enabled = !form.notifications_enabled;
};

const {route} = useMixin();
</script>

<template>
    <AppHead title="Einstellungen"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <h1 class="hero hero-s">Einstellungen</h1>

                <p class="body body-m">
                    Hier könnt Ihr Einstellungen zu Eurem Account vornehmen.
                </p>

                <form class="mt-6" @submit.prevent="form.transform((data) => ({
                    ...data,
                    email: data.email.toLowerCase(),
                  })).post(route('app.user.settings'))">
                    <InputComponent v-model:value="form.email" :error="form.errors.email" name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    type="text">
                        E-Mail-Adresse
                    </InputComponent>

                    <CheckboxComponent id="notifications_enabled" :error="form.errors.notifications_enabled"
                                       :model-value="form.notifications_enabled"
                                       :value="true"
                                       name="notifications_enabled" @onUpdateModelValue="updateNotifications">
                        E-Mail Benachrichtigungen erhalten
                    </CheckboxComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
