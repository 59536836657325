<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    name1: {
        type: String,
        required: false,
    },
    name2: {
        type: String,
        required: false,
    },
});

const form = useForm({
    name1: props.name1,
    name2: props.name2,
});

const submitForm = () => {
    form.post(route('app.design_invitation.edit.bridal_couple', {card: props.card.slug}));
};
</script>

<template>
    <AppHead title="Wie lauten Eure Vornamen?"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wie lauten Eure Vornamen?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <InputComponent
                        v-model:value="form.name1"
                        :error="form.errors.name1"
                        name="name1"
                        placeholder="Vorname eintragen"
                        type="text"
                    >
                        1. Vorname
                    </InputComponent>

                    <InputComponent
                        v-model:value="form.name2"
                        :error="form.errors.name2"
                        name="name2"
                        placeholder="Vorname eintragen"
                        type="text"
                    >
                        2. Vorname
                    </InputComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
