<script setup lang="ts">
import {computed, defineProps, PropType} from 'vue';
import {Link, useForm} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";

const {__, generateRoute, getColorByThemeAndOption} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({
    guests: props.invitation.guests,
});

const accept = (guest: App.Models.Invitation.Guest, index: number) => {
    guest.feedback = true;
    form.guests[index].feedback = true;
};

const cancel = (guest: App.Models.Invitation.Guest, index: number) => {
    guest.feedback = false;
    form.guests[index].feedback = false;
};

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.general.feedback', props.invitation, {'card': props.card}), {
        preserveScroll: true,
    });
};

const feedbackMissing = computed(() => {
    let response = false;

    const array = Object.values(form.guests);
    array.forEach((guest: any) => {
        if (guest.feedback === null) {
            response = true;
        }
    });

    return response;
});
</script>

<template>
    <Modal>
        <template #title>
            {{ __('invitation.feedback.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4">
                    <h2 class="font-montserrat font-semibold text-base">
                        {{ __('invitation.feedback.answer') }}
                    </h2>

                    <div class="mt-8">
                        <template v-for="(guest, key) in form.guests">
                            <div class="mb-8">
                                <div
                                    class="flex justify-between mb-3 font-montserrat font-semibold text-sm text-[#303C3D]">
                                    <div>
                                        {{ guest.name }}
                                    </div>

                                    <Link
                                        :href="generateRoute('app.invitation.feedback.general.edit_guest', invitation, { 'card': card, 'number': key })">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             viewBox="0 0 24 24" fill="none" stroke="#303C3D"
                                             stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-edit-2">
                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                        </svg>
                                    </Link>
                                </div>

                                <div class="grid grid-cols-2 justify-center border rounded-md"
                                     :style="`border-color: ${getColorByThemeAndOption(card.theme, 'feedback')};`">
                                    <div class="font-semibold text-center border-r py-2"
                                         :style="`color: ${getColorByThemeAndOption(card.theme, 'feedback')};`"
                                         :class="{'border-[#6A7171] bg-[#6A7171] text-[#ffffff]': guest.feedback === false}"
                                         @click="cancel(guest, key)">
                                        {{ __('invitation.feedback.answer.no') }}
                                    </div>

                                    <div class="font-semibold text-center py-2"
                                         :style="`color: ${getColorByThemeAndOption(card.theme, 'feedback')}; background-color: ${guest.feedback ? getColorByThemeAndOption(card.theme, 'feedback') : 'inherit'};`"
                                         :class="{'border-[#6A7171] text-[#ffffff]': guest.feedback === true}"
                                         @click="accept(guest, key)">
                                        {{ __('invitation.feedback.answer.yes') }}
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="mt-6">
                            <Link
                                :href="generateRoute('app.invitation.feedback.general.add_guest', invitation, { 'card': card, 'addAnotherGuest': true })"
                                class="font-montserrat font-semibold text-sm underline md:no-underline hover:underline"
                                preserve-scroll>
                                + {{ __('invitation.feedback.add_another_guest') }}
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="mt-12">
                    <div v-if="$page.props.progress" class="w-full h-[3px]"
                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'backgroundWide')};`">
                        <div
                            :style="{'width': $page.props.progress + '%', 'background-color': getColorByThemeAndOption(card.theme, 'feedback')}"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5">
                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :disabled="feedbackMissing"
                                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'feedback')};`"
                                         type="submit">
                            {{ __('invitation.feedback.button.next') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
