<script setup lang="ts">
import Placeholder from '@tiptap/extension-placeholder';
import {Editor, EditorContent, Extension} from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import {computed, onBeforeUnmount, onMounted, ref, useSlots, watch} from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['onUpdateModelValue']);

const slots = useSlots();
const editor = ref<Editor | null>(null);
const showLabel = computed(() => !!slots.default);

watch(
    () => props.modelValue,
    (value) => {
        if (editor.value && editor.value.getHTML() !== value) {
            editor.value.commands.setContent(value, false);
        }
    }
);

onMounted(() => {
    editor.value = new Editor({
        content: props.modelValue,
        extensions: [
            StarterKit,
            Extension.create({
                addKeyboardShortcuts() {
                    return {
                        Enter: () => {
                            this.editor.commands.setHardBreak();
                            return true;
                        },
                    };
                },
            }),
            Placeholder.configure({
                placeholder: props.placeholder,
            }),
        ],
        onUpdate: () => {
            let html = editor.value?.getHTML() || '';
            if (html === '<p></p>') html = null;
            emit('onUpdateModelValue', html);
        },
    });
});

onBeforeUnmount(() => {
    editor.value?.destroy();
});
</script>

<template>
    <label v-if="showLabel" :for="name" class="headline headline-s block mb-1">
        <slot/>
    </label>
    <editor-content :id="name" :editor="editor"
                    class="tiptap body body-s p-3 w-full bg-gray rounded-md min-h-[135px]"
                    :class="{ 'border border-error': error }"/>
    <span class="body body-xs block mt-1 text-error">{{ error }}</span>
</template>

<style lang="scss">
.tiptap {
    .ProseMirror {
        p {
            @apply text-sm m-0 text-raven-700;

            &.is-editor-empty:first-child::before {
                content: attr(data-placeholder);
                float: left;
                @apply text-raven-700;
                pointer-events: none;
                height: 0;
            }
        }
    }
}
</style>
