<script setup lang="ts">
import {defineProps} from 'vue';
import {Link} from '@inertiajs/vue3';

const props = defineProps({
    href: {
        type: String,
        required: false,
        default: null,
    }
});

const hasHref = props.href !== null && props.href !== undefined;
</script>

<template>
    <Link v-if="hasHref" :href="href" class="back-link mb-2.5 text-flower-500 font-bold text-base"
          v-bind="$attrs">
        <slot>Zurück</slot>
    </Link>

    <a v-else class="back-link mb-2.5 text-flower-500 font-bold text-base" href="#" v-bind="$attrs">
        <slot>Zurück</slot>
    </a>
</template>

<style lang="scss" scoped>
.back-link {
    @apply inline-flex items-center;

    @screen md {
        @apply mb-6;
    }

    &:before {
        @apply w-[24px] h-[24px] inline-block pr-6 bg-flower-500;
        content: "";
        mask: url("../../images/icons/arrow-left.svg") no-repeat;
    }
}
</style>
