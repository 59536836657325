<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    token: {
        type: String,
        required: true,
    },
    email: {
        type: String,
        required: true,
    },
});

const form = useForm({
    email: props.email,
    token: props.token,
    password: null,
    password_confirmation: null,
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Passwort zurücksetzen"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <h1 class="hero hero-s">Passwort zurücksetzen</h1>

                <p class="body body-m">
                    Bitte gebt unten Euer neues Passwort ein und bestätigt diese Eingabe einmal.
                </p>

                <form class="mt-6" @submit.prevent="form.post(route('app.password.reset'))">
                    <InputComponent v-model:value="form.password" :error="form.errors.password" name="password"
                                    placeholder="Passwort eingeben"
                                    type="password">
                        Passwort
                    </InputComponent>

                    <InputComponent v-model:value="form.password_confirmation"
                                    :error="form.errors.password_confirmation" name="password_confirmation"
                                    placeholder="Passwort erneut eingeben"
                                    type="password">
                        Passwort erneut eingeben
                    </InputComponent>

                    <ButtonGroup>
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Passwort zurücksetzen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
