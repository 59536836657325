<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import CheckboxComponent from '@/Shared/Forms/CheckboxComponent.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    schedules: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    schedules: props.schedules,
});

const updateSchedule = (type: string) => {
    if (!form.schedules[type]) {
        form.schedules[type] = {
            type: type,
            scheduledAt: null,
            location: null,
        };
    } else {
        delete form.schedules[type];
    }
};

const submitForm = () => {
    form.post(route('app.design_invitation.edit.wedding_schedule', {card: props.card.slug}));
};

const {route} = useMixin();
</script>

<template>
    <AppHead title="Wozu ladet Ihr ein?"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wozu ladet Ihr ein?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <CheckboxComponent
                        id="polterabend"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="polterabend"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Polterabend</template>
                        <template v-slot:description>
                            Traditionelles Fest, bei dem Porzellan zerschlagen wird, um Glück zu bringen.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="get_together"
                        :error="form.errors.schedules"
                        :model-value="form.schedules"
                        name="schedules"
                        value="get_together"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Get Together</template>
                        <template v-slot:description>
                            Ein entspanntes Treffen der Hochzeitsgäste, oft am Vorabend der Hochzeit stattfindet.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="getting_ready"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="getting_ready"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Getting Ready</template>
                        <template v-slot:description>
                            Vorbereitungen des Brautpaares am Hochzeitsmorgen.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="civil_ceremony"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="civil_ceremony"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Standesamtliche Trauung</template>
                        <template v-slot:description>
                            Eine rechtlich bindende Zeremonie, die meist in einem Standesamt stattfindet.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="church_ceremony"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="church_ceremony"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Kirchliche Trauung</template>
                        <template v-slot:description>
                            Eine Zeremonie, die in einer Kirche nach religiösen Riten durchgeführt wird.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="ceremony"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="ceremony"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Trauung (unspezifisch)</template>
                        <template v-slot:description>
                            Die offizielle Zeremonie, in der das Paar getraut wird.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="symbolic_ceremony"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="symbolic_ceremony"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Freie Trauung</template>
                        <template v-slot:description>
                            Eine Zeremonie, die von einem freien Redner gestaltet wird und persönlich auf das Paar
                            zugeschnitten ist.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="secular_ceremony"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="secular_ceremony"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Weltliche Trauung</template>
                        <template v-slot:description>
                            Eine nicht-religiöse Zeremonie, die oft in einem neutralen oder persönlich bedeutsamen Ort
                            abgehalten wird.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="champagne_reception"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="champagne_reception"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Sektempfang</template>
                        <template v-slot:description>
                            Ein kurzer Empfang direkt nach der Trauung.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="party"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="party"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Hochzeitsfeier</template>
                        <template v-slot:description>
                            Das Hauptevent mit Essen, Musik und Tanz.
                        </template>
                    </CheckboxComponent>

                    <CheckboxComponent
                        id="post_wedding_brunch"
                        :error="form.errors.schedules"
                        v-model="form.schedules"
                        name="schedules"
                        value="post_wedding_brunch"
                        @onUpdateModelValue="updateSchedule"
                        class="mb-4"
                    >
                        <template v-slot:label>Brunch am nächsten Morgen</template>
                        <template v-slot:description>
                            Ein entspannter Abschluss des Hochzeitswochenendes.
                        </template>
                    </CheckboxComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
