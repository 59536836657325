<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import CheckboxComponent from '@/Shared/Forms/SimpleCheckboxComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

localStorage.removeItem('event.registration');
localStorage.removeItem('event.paid');

const form = useForm({
    email: '',
    password: '',
    terms: false,
    newsletter: false,
});

const updateTerms = (terms: boolean) => {
    form.terms = terms;
};

const updateNewsletter = (newsletter: boolean) => {
    form.newsletter = newsletter;
};

const {route} = useMixin();
</script>

<template>
    <AppHead title="...zur digitalen Einladung!"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.register')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">...zur digitalen Einladung!</h1>

                <p class="body body-m">
                    Bitte gebt nun eine <strong>gültige E-Mail-Adresse</strong> an und wählt ein <strong>sicheres
                    Passwort</strong>, um jederzeit auf Eure Einladungen zugreifen zu können.
                </p>

                <form class="mt-6" @submit.prevent="form.post(route('app.onboarding.register.email'))">
                    <InputComponent v-model:value="form.email" :error="form.errors.email" name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    type="text">
                        E-Mail-Adresse
                    </InputComponent>

                    <InputComponent v-model:value="form.password" :error="form.errors.password" name="password"
                                    placeholder="Passwort eingeben"
                                    type="password">
                        Passwort
                    </InputComponent>

                    <CheckboxComponent id="terms" :error="form.errors.terms" :model-value="form.terms"
                                       :value="true"
                                       class="mb-3" name="terms" @onUpdateModelValue="updateTerms">
                        *Ich bestätige, dass ich die <a :href="route('web.conditions')" class="underline"
                                                        target="_blank">AGBs</a> und <a
                        :href="route('web.privacy')"
                        class="underline" target="_blank">Datenschutzrichtlinien</a>
                        von <strong>Mi Boda</strong> gelesen und verstanden habe und diese hiermit akzeptiere.
                    </CheckboxComponent>

                    <CheckboxComponent id="newsletter" :error="form.errors.newsletter" :model-value="form.newsletter"
                                       :value="true" name="newsletter" @onUpdateModelValue="updateNewsletter">
                        Ich bestätige, dass ich ab sofort (nach Bestätigung der E-Mail-Adresse) den regelmäßigen
                        Newsletter von
                        <strong>Mi Boda</strong> erhalten möchte.
                    </CheckboxComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Jetzt registrieren und Einladung gestalten
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
