<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useForm} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";

const {route, mix} = useMixin();

// Props definieren
const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    selectedDesign: {
        type: String,
        required: false,
    },
    selectedTheme: {
        type: String,
        required: false,
    },
    cardType: {
        type: String,
        required: true,
    },
});

let selectedDesign = props.design.slug;
let selectedTheme = props.design.first_variant;

if (props.design.slug === props.selectedDesign) {
    selectedDesign = props.selectedDesign;
    selectedTheme = props.selectedTheme;
}

const form = useForm({
    design: selectedDesign,
    theme: selectedTheme,
});

// Methoden
const changeTheme = (newTheme: string) => {
    form.theme = newTheme;
};

const submitForm = () => {
    form.post(route('app.design_invitation.edit.design', {card: props.card.slug}));
};
</script>

<template>
    <div class="flex flex-wrap bg-white border-2 border-flower-100 rounded-lg mb-8">
        <img
            :alt="design.name"
            :src="mix(`resources/images/designs/${design.slug}/teaser/${design.slug}_${form.theme}_${cardType}.png`)"
            class="w-full"
        />

        <div class="w-full px-4 py-6">
            <div class="flex justify-between">
                <h2 class="headline headline-xxl mb-1">
                    {{ design.name }}
                </h2>
            </div>

            <p class="body body-m">
                {{ design.description }}
            </p>

            <div class="flex gap-2 mb-4">
                <div v-for="(color, key) in design.variants" v-if="design.has_variants" :key="key">
                    <img
                        :src="mix(`resources/images/designs/${design.slug}/${key}.svg`)"
                        class="w-[30px] cursor-pointer hover:rounded-full hover:p-[2px] hover:border-2 hover:border-[#CB7373]"
                        :class="{ 'rounded-full p-[2px] border-2 border-[#CB7373]': key === form.theme }"
                        :alt="key"
                        @click="changeTheme(key as string)"
                    />
                </div>

                <div v-if="!design.has_variants">
                    <img
                        :src="mix(`resources/images/designs/${design.slug}/default.svg`)"
                        class="w-[30px] cursor-pointer hover:rounded-full hover:p-[2px] hover:border-2 hover:border-[#CB7373]"
                        :class="{ 'rounded-full p-[2px] border-2 border-[#CB7373]': 'default' === form.theme }"
                        alt="default"
                        @click="changeTheme('default')"
                    />
                </div>
            </div>

            <form @submit.prevent="submitForm">
                <input v-model="design.slug" name="design" type="hidden"/>

                <div class="flex gap-2 mt-6">
                    <ButtonComponent :processing="form.processing" class="button button-primary w-full" type="submit">
                        Auswählen
                    </ButtonComponent>

                    <LinkComponent
                        :href="route('app.designs.demo', {
              design: form.design,
              theme: form.theme,
              returnUrl: route('app.design_invitation.edit.design', { card: card.slug }),
              submitUrl: route('app.design_invitation.edit.design', { card: card.slug }),
              cardType: cardType
            })"
                        class="button button-secondary w-full"
                    >
                        Vorschau
                    </LinkComponent>
                </div>
            </form>
        </div>
    </div>
</template>
