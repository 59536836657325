<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    language: props.card.language,
});

const updateLanguage = (language: string) => {
    form.language = language;
};

const {route} = useMixin();

const submitForm = () => {
    form.post(route('app.design_invitation.edit.language', {card: props.card.slug}));
};
</script>

<template>
    <AppHead title="In welcher Sprache wollt Ihr einladen?"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">In welcher Sprache wollt Ihr einladen?</h1>

                <form class="mt-6" @submit.prevent="submitForm">

                    <RadioButtonComponent id="DE" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="de" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Deutsch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="EN" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="en" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Englisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="FR" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="fr" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Französisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="ES" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="es" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Spanisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="IT" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="it" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Italienisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="NL" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="nl" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Niederländisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="SE" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="se" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Schwedisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="FI" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="fi" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Finnisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="TR" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="tr" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Türkisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="TH" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="th" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Thailändisch</template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="PT" :error="form.errors.language" :model-value="form.language"
                                          name="language" value="pt" @onUpdateModelValue="updateLanguage">
                        <template v-slot:label>Portugiesisch</template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
