<script setup lang="ts">
import {defineEmits, defineProps, ref} from 'vue';

defineProps({
    name: {
        type: String,
        required: true,
    },
    value: {
        type: String,
    },
    placeholder: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: '',
    },
});

const results = ref<any[]>([]);
let timer: any = null;

const emit = defineEmits(['update:value']);

const handleInput = (event: Event) => {
    emit('update:value', (event.target as HTMLInputElement).value);
};

const search = (event: KeyboardEvent) => {
    if (timer) {
        clearTimeout(timer);
        timer = null;
    }

    timer = setTimeout(() => {
        const inputValue = (event.target as HTMLInputElement).value;
        if (inputValue.length >= 3) {
            fetch(`/api/autosuggest?search=${inputValue}`)
                .then(response => response.json())
                .then(data => {
                    results.value = data;
                })
                .catch(() => {
                    results.value = [];
                });
        } else {
            results.value = [];
        }
    }, 10);
};

const selectAddress = (address: string) => {
    emit('update:value', address);
    results.value = [];
};
</script>

<template>
    <div class="mb-4">
        <label :for="name" class="headline headline-s block mb-1">
            <slot></slot>
        </label>
        <input :id="name" :name="name" :placeholder="placeholder" :value="value"
               class="body body-s w-full h-[44px] bg-gray rounded-md focus:ring-raven-500"
               type="text"
               :class="{ 'border-error': error, 'border-white': !error }"
               @input="handleInput"
               @keydown="search"
        />

        <ul v-if="results.length" class="w-full bg-white m-0 mt-1 rounded-lg shadow-lg">
            <li
                v-for="item in results"
                :key="item.id"
                class="list-none body body-xs m-0 px-4 py-2 cursor-pointer hover:bg-flower-50 active:bg-flower-50"
                @click="selectAddress(item.name)"
            >
                <strong class="headline headline-s">{{ item.street }}</strong><br/>
                {{ item.other }}
            </li>
        </ul>

        <span v-if="error" class="body body-xs block mt-1 text-error">{{ error }}</span>
    </div>
</template>
