<script setup lang="ts">
import {useMixin} from "@/mixins";

const {__} = useMixin();

defineProps({
    salutation: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <template v-if="salutation.salutation === 'guests'">
        <span v-html="__('invitation.salutation.guests')"/>
    </template>

    <template v-if="salutation.salutation === 'family'">
        <span v-html="__('invitation.salutation.family')"/>
    </template>

    <template v-if="salutation.salutation === 'friends'">
        <span v-html="__('invitation.salutation.friends')"/>
    </template>

    <template v-if="salutation.salutation === 'family_and_friends'">
        <span v-html="__('invitation.salutation.family_and_friends')"/>
    </template>

    <template v-if="salutation.salutation === 'custom'">
        <span v-html="salutation.text"/>
    </template>
</template>
