<script setup lang="ts">
import {defineEmits, defineProps} from 'vue';

const props = defineProps({
    class: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'button',
    },
    name: {
        type: String,
    },
    processing: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['click']);

const handleClick = () => {
    emit('click');
};
</script>

<template>
    <button :class="props.class" :name="name" :type="type" class="relative" v-bind="$attrs" @click="handleClick">

        <svg v-if="processing && props.class.includes('button-primary')"
             class="animate-spin h-3 w-3 text-white absolute left-auto right-auto"
             fill="none"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"></path>
        </svg>

        <svg v-if="processing && props.class.includes('button-secondary')"
             class="animate-spin h-3 w-3 text-white absolute left-auto right-auto"
             fill="none"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b" stroke-width="4"></circle>
            <path class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="#893e3e"></path>
        </svg>

        <span :class="{ 'invisible': processing }">
            <slot></slot>
        </span>
    </button>
</template>
