<script setup lang="ts">
import {defineProps} from 'vue';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <AppHead title="2. Einladungen versenden"/>
    <Layout :rimeless="true" class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <img
                    class="w-full mx-auto px-3 pt-4 pb-6 bg-flower-50"
                    src="../../../../images/guidance-versand.svg"
                    alt="Einladung versenden"
                />

                <div class="mt-5 px-4">
                    <h1 class="hero hero-s">2. Einladungen versenden</h1>

                    <ul>
                        <li>
                            Ihr könnt Eure Hochzeitseinladung via Whatsapp, Telegram und E-Mail versenden oder den Link
                            kopieren und
                            entsprechend verwenden.
                        </li>
                        <li>Bestätigt, dass Ihr die Einladung versendet habt und verschickt die Nächste!</li>
                    </ul>
                </div>

                <ButtonGroup class="md:mt-10 grid gap-2 shadow-none border-none" style="background: none !important;">
                    <LinkComponent
                        :href="route('app.guest_list.guidance.three', { card: card.slug })"
                        class="button button-primary w-full order-2"
                    >
                        Verstanden
                    </LinkComponent>
                </ButtonGroup>
            </template>
        </Card>
    </Layout>
</template>
