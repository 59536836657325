<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";
import {PropType} from "vue";

const props = defineProps({
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    selectedDesign: {
        type: String,
    },
    cardType: {
        type: String,
        required: true,
    }
});

let selectedDesign = props.design.slug;
let selectedTheme = props.design.first_variant;

if (props.selectedDesign) {
    const extracted = props.selectedDesign.split('_')[0];
    if (props.design.slug === extracted) {
        selectedDesign = extracted;
        selectedTheme = props.selectedDesign.split('_')[1];
    }
}

const form = useForm({
    design: selectedDesign,
    theme: selectedTheme,
});

const changeTheme = (theme: string) => {
    form.theme = theme;
};

const {route, mix} = useMixin();
</script>

<template>
    <div class="flex flex-wrap bg-white border-2 border-flower-100 rounded-lg mb-8">
        <img :alt="props.design.name"
             :src="mix(`resources/images/designs/${props.design.slug}/teaser/${props.design.slug}_${form.theme}_${cardType}.png`)"
             class="w-full"/>

        <div class="w-full px-4 py-4">

            <h2 class="headline headline-xxl mb-1">
                {{ props.design.name }}
            </h2>

            <p class="body body-m mb-6">
                {{ props.design.description }}
            </p>

            <div class="flex gap-2 mb-4">
                <div v-for="(color, key) in props.design.variants" v-if="props.design.has_variants">
                    <img :src="mix(`resources/images/designs/${props.design.slug}/${key}.svg`)"
                         class="w-[30px] cursor-pointer hover:rounded-full hover:p-[2px] hover:border-2 hover:border-[#CB7373]"
                         :class="{'rounded-full p-[2px] border-2 border-[#CB7373]': key === form.theme}"
                         :alt="key as String"
                         @click="changeTheme(key)"
                    />
                </div>

                <div v-if="!props.design.has_variants">
                    <img :src="mix(`resources/images/designs/${props.design.slug}/default.svg`)"
                         class="w-[30px] cursor-pointer hover:rounded-full hover:p-[2px] hover:border-2 hover:border-[#CB7373]"
                         :class="{'rounded-full p-[2px] border-2 border-[#CB7373]': 'default' === form.theme}"
                         alt="default"
                         @click="changeTheme('default')"
                    />
                </div>
            </div>

            <form @submit.prevent="form.post(route('app.onboarding.design'))">
                <input v-model="design.slug" name="design" type="hidden"/>

                <div class="flex gap-2 mt-6">
                    <ButtonComponent :processing="form.processing" class="button button-primary w-full" type="submit">
                        Auswählen
                    </ButtonComponent>

                    <LinkComponent :href="route('app.designs.demo', {
                        design : form.design,
                        theme: form.theme,
                        returnUrl: route('app.onboarding.design', {
                            cardType: cardType
                        }),
                        submitUrl: route('app.onboarding.design'),
                        cardType: cardType
                    })"
                                   class="button button-secondary w-full">
                        Vorschau
                    </LinkComponent>
                </div>
            </form>
        </div>
    </div>
</template>
