<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import BackLink from '@/Shared/BackLink.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    type: {
        type: String,
        required: true
    }
});

const form = useForm({
    type: props.type
});

function updateCardType(type: string) {
    form.type = type;
}
</script>

<template>
    <AppHead title="Welche Art von Karte wollt Ihr erstellen?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.dashboard')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Welche Art von Karte wollt Ihr erstellen?</h1>

                <form class="mt-6" @submit.prevent="form.post(route('app.dashboard.card.choose_card_type'))">
                    <RadioButtonComponent id="saveTheDate" :error="form.errors.type"
                                          :model-value="form.type" name="type" value="saveTheDate"
                                          @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Save The Date
                            <span
                                class="badge m-0 bg-light-gray text-[#7182A0] font-normal rounded-md ml-2">Kostenlos</span>
                        </template>
                        <template v-slot:description>
                            Teile den Gästen das Datum deiner bevorstehenden Hochzeit mit und lass die Vorfreude
                            steigen.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="invitation" :error="form.errors.type" :model-value="form.type"
                                          name="type" value="invitation" @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Einladungskarte
                        </template>
                        <template v-slot:description>
                            Erstelle eine stilvolle digitale Einladungskarte für deine Hochzeit mit personalisierten
                            Details und einem RSVP-Formular.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="thankYou" :error="form.errors.type"
                                          :model-value="form.type" name="type" value="thankYou"
                                          @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Danksagungskarte
                        </template>
                        <template v-slot:description>
                            Erstelle eine stilvolle digitale Danksagungskarte mit Hochzeitsfotos und einem
                            Dankeschön-Text.
                        </template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Auswählen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
