<script lang="ts" setup>
import {useForm} from '@inertiajs/vue3';
import AppHead from "@/Shared/AppHead.vue";
import Layout from "@/Layouts/Default.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import {useMixin} from "@/mixins";

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
});

const form = useForm({
    type: props.type,
});

const {route} = useMixin();

const updateCardType = (type: string) => {
    form.type = type;
};
</script>

<template>
    <AppHead title="Welche Art von Karte wollt Ihr erstellen?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <h1 class="hero hero-s">Welche Art von Karte wollt Ihr erstellen?</h1>
                <form class="mt-6" @submit.prevent="form.post(route('app.onboarding.card_type'))">
                    <RadioButtonComponent id="saveTheDate" :error="form.errors.type" v-model="form.type" name="type"
                                          value="saveTheDate" @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Save The Date
                            <div class="badge m-0 bg-light-gray text-[#7182A0] font-normal rounded-md ml-2">
                                Kostenlos
                            </div>
                        </template>

                        <template v-slot:description>
                            Teile den Gästen das Datum deiner bevorstehenden Hochzeit mit und lass die Vorfreude
                            steigen.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="invitation" :error="form.errors.type" v-model="form.type" name="type"
                                          value="invitation" @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Einladungskarte
                        </template>

                        <template v-slot:description>
                            Erstelle eine stilvolle digitale Einladungskarte für deine Hochzeit mit personalisierten
                            Details und einem RSVP-Formular.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="thankYou" :error="form.errors.type" v-model="form.type" name="type"
                                          value="thankYou" @onUpdateModelValue="updateCardType">
                        <template v-slot:label>
                            Danksagungskarte
                        </template>

                        <template v-slot:description>
                            Erstelle eine stilvolle digitale Danksagungskarte mit Hochzeitsfotos und einem
                            Dankeschön-Text.
                        </template>
                    </RadioButtonComponent>

                    <div class="relative z-0 rounded-lg bg-flower-300 text-white body font-bold p-4 mt-4">
                        🎉 NEU: Sommeraktion für deine Hochzeitseinladung! Jetzt 10% Rabatt auf deine digitalen Einladungen
                        mit dem Gutscheincode SOMMER10 sichern!
                    </div>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Auswählen
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
