<template>
    <div
        class="z-50 button-group w-full fixed left-0 bottom-0 bg-white p-4 md:relative md:shadow-none md:bg-none md:p-0"
        v-bind="$attrs">
        <slot/>
    </div>
</template>

<style scoped lang="scss">
.button-group {
    box-shadow: 0 -2px 4px 0 rgba(75, 27, 27, 0.08);

    @media (min-width: 768px) {
        box-shadow: none;
    }
}
</style>
