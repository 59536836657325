<script setup lang="ts">
import {computed, onMounted, ref, useSlots} from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    name: String,
    value: String,
    ref: String,
    placeholder: String,
    error: String,
    focus: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
const showLabel = computed(() => !!slots.default);
const inputRef = ref<HTMLInputElement | null>(null);

onMounted(() => {
    if (props.focus && inputRef.value) {
        inputRef.value.focus();
    }
});
</script>

<template>
    <div class="mb-4">
        <label v-if="showLabel" :for="name" class="headline headline-s block mb-1">
            <slot/>
        </label>
        <input
            :id="name"
            ref="input"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            :disabled="disabled"
            class="body body-s w-full h-[44px] bg-gray rounded-md focus:ring-raven-500"
            v-bind="$attrs"
            :class="{ 'border-error': error, 'border-white': !error }"
            @input="$emit('update:value', $event.target.value)"
        />

        <span v-if="error" class="body body-xs block mt-1 text-error">{{ error }}</span>
    </div>
</template>
