<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import CheckboxComponent from '@/Shared/Forms/SimpleCheckboxComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AppHead from '@/Shared/AppHead.vue';
import {computed} from "vue";
import {useMixin} from "@/mixins";

const props = defineProps({
    email: {
        type: String,
    },
    provider: {
        type: String,
    }
});

const form = useForm({
    email: props.email,
    terms: false,
    newsletter: false,
});

const updateTerms = (terms: boolean) => {
    form.terms = terms;
};

const updateNewsletter = (newsletter: boolean) => {
    form.newsletter = newsletter;
};

const providerName = computed(() => {
    switch (props.provider) {
        case 'facebook':
            return 'Facebook';
        case 'google':
            return 'Google';
        case 'sign-in-with-apple':
            return 'Apple';
        default:
            return 'Anbieter';
    }
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="...zur digitalen Einladung!"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.register')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">...zur digitalen Einladung!</h1>

                <p class="body body-m">
                    Vielen Dank für die Anmeldung mit {{ providerName }}! Bitte bestätige deine E-Mail-Adresse, die mit
                    deinem
                    {{ providerName }}-Konto verknüpft ist. Du kannst sie nicht mehr ändern. Akzeptiere bitte auch
                    unsere AGB und
                    Datenschutzbestimmungen, bevor du fortfährst.
                </p>

                <div class="bg-white border-2 border-flower-300 rounded-lg p-3 shadow-md mb-3" v-if="!email">
                    <p class="text-base text-flower-700">
                        Leider konnten wir die E-Mail-Adresse von {{ providerName }} nicht automatisch übernehmen. Du
                        musst diese bitte noch einmal erneut angeben oder eine vollständig andere E-Mail-Adresse
                        verwenden.
                    </p>
                </div>

                <form class="mt-6" @submit.prevent="form.post(route('app.onboarding.register.complete'))">
                    <InputComponent v-model:value="form.email" :error="form.errors.email" name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    type="text">
                        E-Mail-Adresse
                    </InputComponent>

                    <CheckboxComponent id="terms" :error="form.errors.terms" :model-value="form.terms"
                                       :value="true"
                                       class="mb-3" name="terms" @onUpdateModelValue="updateTerms">
                        *Ich bestätige, dass ich die <a :href="route('web.conditions')" class="underline"
                                                        target="_blank">AGBs</a> und <a
                        :href="route('web.privacy')"
                        class="underline" target="_blank">Datenschutzrichtlinien</a>
                        von <strong>Mi Boda</strong> gelesen und verstanden habe und diese hiermit akzeptiere.
                    </CheckboxComponent>

                    <CheckboxComponent id="newsletter" :error="form.errors.newsletter" :model-value="form.newsletter"
                                       :value="true" name="newsletter" @onUpdateModelValue="updateNewsletter">
                        Ich bestätige, dass ich ab sofort (nach Bestätigung der E-Mail-Adresse) den regelmäßigen
                        Newsletter von
                        <strong>Mi Boda</strong> erhalten möchte.
                    </CheckboxComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Zustimmen und fortfahren
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
