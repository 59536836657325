<script setup lang="ts">
import {defineProps, PropType, ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import ConfirmationDialog from '@/Shared/ConfirmationDialog.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import moment from 'moment';
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({});
const deleteFeedbackLoading = ref(false);
const dialogRef = ref(null);

const deleteFeedback = () => {
    deleteFeedbackLoading.value = true;
    dialogRef.value.isVisible = true;
    setTimeout(() => {
        deleteFeedbackLoading.value = false;
    }, 250);
};
</script>

<template>
    <AppHead :title="'Rückmeldung von ' + invitation.concatenated_names"/>
    <Layout class="bg-flower-50">
        <div class="md:w-[525px] md:mx-auto">
            <BackLink :href="route('app.feedback', { card: card.slug })"/>

            <div class="grid grid-cols-1 gap-3 mt-6">
                <div class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center"
                     v-if="card.is_personalized_invitation">
                    <ul class="m-0 w-full">
                        <li class="w-full inline-flex justify-between px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <div class="body body-s text-raven-700">
                                <template v-if="invitation.open_at">
                                    Einladung geöffnet ({{ moment(invitation.open_at as Date, 'DD.MM.YYYY H:MM') }} Uhr)
                                </template>
                                <template v-if="!invitation.open_at">
                                    Einladung wurde bisher nicht geöffnet
                                </template>
                            </div>
                            <img v-if="invitation.open_at" class="w-[24px]" src="../../../../images/feedback-yes.svg"/>
                            <img v-if="!invitation.open_at" class="w-[24px]" src="../../../../images/feedback-no.svg"/>
                        </li>
                    </ul>
                </div>

                <div v-for="guest in invitation.guests" :key="guest.id"
                     class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center">
                    <ul class="m-0 w-full" v-if="guest.feedback">
                        <li class="headline headline-m w-full inline-flex justify-between items-center pb-2 mb-2 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <span>{{ guest.name }}</span>
                            <div class="flex gap-4 items-center">
                                <span class="body body-s">Zusage</span>
                                <img class="w-[24px]" src="../../../../images/feedback-yes.svg" alt="feedback-yes.svg"/>
                            </div>
                        </li>
                        <li v-if="guest.selected_food"
                            class="headline headline-s w-full pb-2 mb-2 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <div class="body body-s text-raven-700 flex gap-2">
                                {{ guest.selected_food }}
                                <img src="../../../../images/icons/alert.svg" alt="alert.svg" v-if="guest.food_notice"/>
                            </div>
                            <div class="body body-s text-raven-700 mt-3" v-if="guest.food_notice">
                                {{ guest.food_notice }}
                            </div>
                        </li>
                    </ul>
                    <ul class="m-0 w-full" v-if="!guest.feedback">
                        <li class="w-full inline-flex justify-between items-center pb-2 mb-2 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <span class="headline headline-m m-0">{{ guest.name }}</span>
                            <div class="flex gap-4 items-center">
                                <template v-if="guest.feedback === false">
                                    <span class="body body-s">Absage</span>
                                    <img class="w-[24px]" src="../../../../images/feedback-no.svg"
                                         alt="feedback-no.svg"/>
                                </template>
                                <template v-if="guest.feedback === null">
                                    <span class="body body-s">Rückmeldung ausstehend</span>
                                    <img class="w-[24px]" src="../../../../images/feedback-unknown.svg"
                                         alt="feedback-unknown.svg"/>
                                </template>
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-if="invitation.comment" class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center">
                    <ul class="m-0 w-full">
                        <li class="w-full pb-2 mb-2 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <span class="headline headline-m">Kommentar</span>
                        </li>
                        <li class="w-full px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            <p class="body body-s">{{ invitation.comment }}</p>
                        </li>
                    </ul>
                </div>

                <ConfirmationDialog ref="dialogRef">
                    <template v-slot:button>
                        <form class="w-full"
                              @submit.prevent="form.post(route('app.feedback.delete', { card: card.slug, invitation: invitation.slug }))">
                            <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                             type="submit">
                                Bestätigen
                            </ButtonComponent>
                        </form>
                    </template>
                </ConfirmationDialog>

                <ButtonGroup class="md:mt-10">
                    <button v-if="card.is_general_invitation" class="button button-secondary w-full mb-2"
                            @click="deleteFeedback">
            <span :class="{ 'invisible' : deleteFeedbackLoading }" class="inline-flex">
              <img class="mr-2" src="../../../../images/icons/trash-red.svg"/>
              Rückmeldung löschen
            </span>
                        <svg v-if="deleteFeedbackLoading"
                             class="animate-spin h-3 w-3 text-white absolute left-auto right-auto" fill="none"
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b"
                                    stroke-width="4"></circle>
                            <path class="opacity-75"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  fill="#893e3e"></path>
                        </svg>
                    </button>

                    <LinkComponent
                        :href="route('app.feedback.overwrite', { card: card.slug, invitation: invitation.slug })"
                        class="button button-primary w-full">
                        <img class="mr-2" src="../../../../images/icons/edit-white.svg"/>
                        Rückmeldung anpassen
                    </LinkComponent>
                </ButtonGroup>
            </div>
        </div>
    </Layout>
</template>
