<script setup lang="ts">
import {reactive} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import BackLink from '@/Shared/BackLink.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    type: {
        type: String,
    }
});

const form = reactive(useForm({
    type: props.type,
}));

const {route} = useMixin();

const submitForm = () => {
    form.post(route('app.onboarding.shipping_type'));
};

const updateShippingType = (type: string) => {
    form.type = type;
};
</script>

<template>
    <AppHead title="Welche Einladungsform wählt Ihr?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.wedding_schedule.single.last')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Welche Einladungsform wählt Ihr?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <RadioButtonComponent id="personalized" :error="form.errors.type" :model-value="form.type"
                                          name="type" value="personalized" @onUpdateModelValue="updateShippingType">
                        <template v-slot:label>Personalisierte Einladung</template>
                        <template v-slot:description>
                            Ihr erhaltet für jeden Gast eine namentliche Einladung, die Ihr per Link an diesen
                            verschickt.
                            Die Einladung kann auch Päarchen oder Gruppen beinhalten.
                        </template>
                    </RadioButtonComponent>

                    <RadioButtonComponent id="general" :error="form.errors.type" :model-value="form.type"
                                          name="type" value="general" @onUpdateModelValue="updateShippingType">
                        <template v-slot:label>Allgemeine Einladung</template>
                        <template v-slot:description>
                            Ihr erhaltet eine unpersonalisierte Einladung, die Ihr per Link an alle Gäste verschickt.
                        </template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Weiter
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
