<script setup lang="ts">
import {defineProps} from 'vue';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

defineProps({
    card: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <AppHead title="3. Rückmeldungen erhalten"/>
    <Layout :rimeless="true" class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:inner>
                <img
                    class="w-full mx-auto px-3 pt-4 pb-6 bg-flower-50"
                    src="../../../../images/guidance-rueckmeldungen.svg"
                    alt="Rückmeldunen erhalten"
                />

                <div class="mt-5 px-4">
                    <h1 class="hero hero-s">3. Rückmeldungen erhalten</h1>

                    <ul>
                        <li>Überprüft, welche Gäste Euch bereits eine Rückmeldung gegeben haben.</li>
                        <li>Für eine Zusage gibt es ein grünes Häkchen, bei einer Absage ein rotes Kreuz.</li>
                        <li>
                            Welche Menü-Wünsche haben die Gäste gewählt? Haben die Gäste einen Kommentar hinterlassen?
                            Behaltet alles
                            genau im Blick!
                        </li>
                    </ul>
                </div>

                <ButtonGroup class="md:mt-10 grid gap-2 shadow-none border-none" style="background: none !important;">
                    <LinkComponent
                        :href="route('app.guest_list.create', { card: card.slug, guidance: false })"
                        class="button button-primary w-full order-2"
                    >
                        Okay, erste Gruppe anlegen
                    </LinkComponent>
                </ButtonGroup>
            </template>
        </Card>
    </Layout>
</template>
