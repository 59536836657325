<script setup lang="ts">
import {PropType, ref} from 'vue';
import {useMixin} from "@/mixins";
import AgendaBlock from '@/Designs/Legacy/Blocks/Agenda.vue';
import DresscodeBlock from '@/Designs/Legacy/Blocks/Dresscode.vue';
import FreeTextBlock from '@/Designs/Legacy/Blocks/FreeText.vue';
import GroomsmenBlock from '@/Designs/Legacy/Blocks/Groomsmen.vue';
import InvitationTextBlock from '@/Designs/Legacy/Blocks/InvitationText.vue';
import KidsBlock from '@/Designs/Legacy/Blocks/Kids.vue';
import WeddingGamesBlock from '@/Designs/Legacy/Blocks/WeddingGames.vue';
import ClosingTextBlock from '@/Designs/Legacy/Blocks/ClosingText.vue';
import GiftWishesBlock from '@/Designs/Legacy/Blocks/GiftWishes.vue';
import Foreword from '@/Designs/Legacy/Components/Foreword.vue';
import AccommodationBlock from '@/Designs/Legacy/Blocks/Accommodation.vue';
import Location from "@/Designs/Legacy/Components/Location.vue";
import Footer from "@/Designs/Legacy/Footer.vue";
import {Link} from '@inertiajs/vue3';
import Salutation from "@/Designs/Components/Salutation.vue";

const {
    route,
    getTime,
    getDate,
    address,
    generateRoute,
    __,
    getSalutation,
    formatGuestNames,
    getImagesFromCallery,
    getColorByThemeAndOption
} = useMixin();

const loading = ref(false);

const loadingStart = () => {
    loading.value = true;

    setTimeout(() => {
        loading.value = false;
    }, 2000);
};

defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    state: {
        type: String,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
    }
});
</script>

<template>
    <div class="h-full">
        <div v-if="state === 'invitation'" class="design design-royal w-full bg-[#FFFFF8]">
            <div class="border border-[#DFD8D7] m-1">

                <div class="md:grid md:grid-cols-2">
                    <!-- user id 1937 -->
                    <template v-if="card.user.id === 1937">
                        <div
                            class="mt-4 font-royal-body font-light text-base text-center text-[#684A66] w-3/4 md:w-[350px] mx-auto">

                            <h3>Bitte schau mich zuerst an:</h3>

                            <video autoplay class="w-full mb-8" controls
                                   poster="../../../../../public/videos/user_1937_video_2.png"
                                   preload="metadata">
                                <source src="../../../../../public/videos/user_1937_video_2.mp4" type="video/mp4">
                                Bein Browser unterstützt das Abspielen von Videos leider nicht.
                            </video>

                            <h3>Bitte schau mich als Zweites an:</h3>

                            <video class="w-full" controls poster="../../../../../public/videos/user_1937_video_1.png"
                                   preload="metadata">
                                <source src="../../../../../public/videos/user_1937_video_1.mp4" type="video/mp4">
                                Bein Browser unterstützt das Abspielen von Videos leider nicht.
                            </video>
                        </div>
                    </template>

                    <div>
                        <h1 class="font-royal-body font-medium text-sm text-center uppercase pt-[55px] pb-[10px]">
                            <template v-if="card.is_personalized_invitation && invitation">
                                {{ getSalutation(invitation) }}
                                <br/>
                                <div class="font-royal-display font-normal text-4xl block text-center normal-case"
                                     v-html="formatGuestNames(invitation.guests_names_for_salutation)">

                                </div>
                            </template>

                            <template v-if="card.is_general_invitation">
                                {{ __('invitation.salutation.plural') }}
                                <br/>
                                <div class="font-royal-display font-normal text-4xl block text-center normal-case">
                                    <Salutation :salutation="card.salutation"/>
                                </div>
                            </template>
                        </h1>

                        <Foreword :foreword="card.foreword" class="w-7/12 md:w-[240px] mb-[30px]"/>

                        <span class="couple hidden md:block">{{ card.user.concatenated_names }}</span>
                    </div>

                    <div class="relative bg-[#5F416E] mx-8 py-10 md:my-6">
                        <img class="absolute -left-5 top-5" src="../../../../images/designs/viola/flower-01.svg"/>
                        <img class="absolute -right-5 bottom-20" src="../../../../images/designs/viola/flower-02.svg"/>

                        <template v-for="(schedule, index) in card.wedding_schedules">
                            <div class="mb-[40px]">
                                <div class="date mb-6" v-if="index === 0">{{
                                        getDate(schedule.scheduled_at, 'DD.MM.YYYY')
                                    }}
                                </div>
                                <div class="date mb-6"
                                     v-if="index > 0 && getDate(schedule.scheduled_at as Date, 'DD.MM.YYYY') !== getDate(card.wedding_schedules[index - 1].scheduled_at as Date, 'DD.MM.YYYY')">
                                    {{ getDate(schedule.scheduled_at, 'DD.MM.YYYY') }}
                                </div>
                                <div class="type">
                                    {{ __('invitation.wedding_schedule.' + schedule.type) }}
                                </div>
                                <div class="time">{{ getTime(schedule.scheduled_at as Date, card) }}</div>
                                <div class="address" v-html="address(schedule.location)"/>
                            </div>
                        </template>
                    </div>
                </div>

                <span class="couple md:hidden">{{ card.user.concatenated_names }}</span>

                <div class="py-20 bg-[#F3F3E0] relative">
                    <img class="absolute left-0 -top-16" src="../../../../images/designs/viola/flower-03.svg"/>
                    <img class="absolute right-0 -bottom-16" src="../../../../images/designs/viola/flower-04.svg"/>

                    <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                        <img class="mx-auto mb-8"
                             :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                    </template>

                    <img v-if="card.user.id === 795" class="mx-auto w-[250px] mb-8"
                         src="../../../../images/designs/viola/user-795.jpg"/>

                    <div class="linkify">
                        <div v-for="(block, key) in card.content_blocks" class="w-3/4 md:w-[350px] mx-auto">
                            <InvitationTextBlock v-if="block.type === 'invitationText'" :block="block"/>
                            <AgendaBlock v-if="block.type === 'agenda'" :block="block"/>
                            <WeddingGamesBlock v-if="block.type === 'weddingGames'" :block="block"/>
                            <GroomsmenBlock v-if="block.type === 'groomsmen'" :block="block"/>
                            <GiftWishesBlock v-if="block.type === 'giftWishes'" :block="block"/>
                            <DresscodeBlock v-if="block.type === 'dresscode'" :block="block"/>
                            <KidsBlock v-if="block.type === 'kids'" :block="block"/>
                            <AccommodationBlock v-if="block.type === 'accommodation'" :block="block"/>
                            <FreeTextBlock v-if="block.type === 'freeText'" :block="block"/>
                            <ClosingTextBlock v-if="block.type === 'closingText'" :block="block"/>

                            <div v-if="key < Object.keys(card.content_blocks).length"
                                 class="my-8 flex flex-wrap justify-between mx-auto w-[50px]">
                                <img src="../../../../images/designs/viola/divider.svg"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Location :card="card" :invitation="invitation"></Location>

                <div class="w-full bg-white py-12 px-4 pt-16">
                    <h2 class="mb-6 md:text-4xl md:w-[600px] md:mx-auto md:text-center relative">
                        <img class="absolute -left-4 -top-24" src="../../../../images/designs/viola/flower-05.svg"/>
                        <template v-if="!(invitation?.was_answered_already)">
                            {{ __('invitation.feedback.headline') }}
                        </template>

                        <template v-if="invitation?.was_answered_already">
                            {{ __('invitation.feedback.thanks') }}
                        </template>
                    </h2>

                    <p class="max-w-[350px] mb-10">
                        <template v-if="!card.feedback_text">
                            {{ __('invitation.feedback.text_default') }}
                        </template>
                        <span v-html="card.feedback_text"/>
                    </p>


                    <div class="md:grid md:grid-cols-1 md:justify-center">
                        <template v-if="card.is_personalized_invitation">
                            <Link
                                :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                                v-if="invitation?.was_answered_already"
                                class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.edit_feedback') }}
                                </div>
                            </Link>

                            <Link
                                :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                                v-if="!invitation?.was_answered_already"
                                class="button button-primary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.submit_feedback_now') }}
                                </div>
                            </Link>

                            <a
                                :href="route('app.invitation.ical', { 'card': card })"
                                class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                                v-if="invitation?.was_answered_already">
                                {{ __('invitation.add_to_calendar') }}
                            </a>
                        </template>

                        <template v-if="card.is_general_invitation">
                            <Link
                                :href="generateRoute('app.invitation.feedback.general.edit', invitation, { 'card': card })"
                                v-if="invitation?.was_answered_already"
                                class="button button-secondary w-full h-[54px] mb-4 md:max-w-[393px] md:mx-auto"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.edit_feedback') }}
                                </div>
                            </Link>

                            <Link
                                :href="generateRoute('app.invitation.feedback.general.chooser', invitation, { 'card': card })"
                                v-if="!invitation?.was_answered_already"
                                class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.submit_feedback_now') }}
                                </div>
                            </Link>

                            <a
                                :href="route('app.invitation.ical', { 'card': card })"
                                class="button button-primary w-full h-[54px] md:max-w-[393px] md:mx-auto"
                                v-if="invitation?.id">
                                {{ __('invitation.add_to_calendar') }}
                            </a>
                        </template>
                    </div>
                </div>

                <Footer :card="card"/>
            </div>
        </div>

        <div class="design design-royal h-full" v-if="state === 'saveTheDate'">
            <div class="w-full bg-[#FFFFF8]">
                <div class="border border-[#DFD8D7] m-1">
                    <div class="md:grid md:grid-cols-2">
                        <div>
                            <img src="../../../../images/designs/viola/leave-01.svg" alt="leave-01.svg"
                                 class="mx-auto mt-[66px] mb-[33px]"/>

                            <h1 class="font-royal-display mb-3 font-bold text-4xl text-[#684A66] w-[335px] mx-auto text-center">
                                Save the Date
                            </h1>

                            <span
                                class="w-[250px] block font-royal-body font-light text-base text-center mx-auto text-[#684A66]"
                                v-html="card.foreword"/>

                            <span class="hidden md:block couple">{{ card.user.concatenated_names }}</span>

                            <img src="../../../../images/designs/viola/leave-01.svg" alt="leave-01.svg"
                                 class="mx-auto mb-[100px] hidden md:block"/>
                        </div>

                        <div class="relative bg-[#5F416E] mx-8 py-12 mt-10 md:my-6 md:grid md:items-center">
                            <img class="absolute -left-5 top-5" src="../../../../images/designs/viola/flower-01.svg"/>
                            <img class="absolute -right-5 bottom" src="../../../../images/designs/viola/flower-02.svg"/>

                            <span class="date">{{ getDate(card.first_wedding_schedule.scheduled_at, 'DD. MMMM YYYY') }}</span>
                        </div>

                        <span class="couple md:hidden">{{ card.user.concatenated_names }}</span>

                        <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                            <img class="mx-auto mb-8"
                                 :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                        </template>

                        <img src="../../../../images/designs/viola/leave-01.svg" alt="leave-01.svg"
                             class="mx-auto mb-[25px] md:hidden"/>
                    </div>
                </div>

                <Footer :card="card"/>
            </div>
        </div>

        <div class="relative design design-royal h-full" v-if="state === 'thankYou'">
            <img src="../../../../images/designs/viola/viola-thankyou-bg-pattern.svg"
                 alt="viola-thankyou-bg-pattern" class="hidden md:block w-full absolute z-10"/>

            <div class="w-full bg-[#FFFFF8] h-screen overflow-x-hidden">
                <div class="relative z-50 md:max-w-[1060px] md:mt-12 md:mx-[110px]">
                    <div class="border border-[#DFD8D7]">
                        <div class="border border-[#DFD8D7] m-1 bg-[#FFFFFF]">

                            <div>
                                <img src="../../../../images/designs/viola/leave-01.svg" alt="leave-01.svg"
                                     class="mx-auto mt-[66px] mb-[33px]"/>

                                <h1 class="font-royal-display mb-3 font-bold text-4xl text-[#684A66] w-[335px] mx-auto text-center md:text-[48px]">
                                    Danke
                                </h1>

                                <span
                                    class="w-[250px] block font-royal-body font-light text-base text-center mx-auto text-[#684A66]"
                                    v-html="card.foreword"/>

                                <span class="hidden md:block couple pb-8">{{ card.user.concatenated_names }}</span>
                            </div>

                            <span class="couple md:hidden pb-[40px]">{{ card.user.concatenated_names }}</span>

                            <img src="../../../../images/designs/viola/leave-02.svg" alt="leave-01.svg"
                                 class="mx-auto hidden md:block mb-[68px]"/>

                            <img src="../../../../images/designs/viola/leave-02.svg" alt="leave-02.svg"
                                 class="mx-auto mb-[100px] md:hidden"/>

                            <template v-if="card.blocks.filter(x => x.type === 'photo').length > 0">
                                <img class="mx-auto mb-8"
                                     :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"/>
                            </template>

                            <div class="relative bg-[#F3F3E0] pb-[80px] pt-[64px] px-4" v-if="card.has_gallery_block">
                                <div
                                    class="flex justify-center flex-wrap flex-col text-center md:w-[340px] md:mx-auto">
                                    <div
                                        class="font-royal-display font-bold text-[26px] text-[#5F416E] mb-[72px] md:text-[36px]"
                                        :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                                        {{ __('thankYou.gallery.headline') }}
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 gap-4 md:w-[550px] md:mx-auto md:text-center">
                                    <img class="absolute left-[-60px] top-[-60px] z-50"
                                         src="../../../../images/designs/viola/flower-03.svg" alt="flower-03.svg"/>

                                    <div v-for="image in getImagesFromCallery(card)" class="z-10">
                                        <img class="object-cover object-center w-full max-w-full rounded-lg"
                                             :src="image"/>
                                    </div>

                                    <img class="absolute right-[-60px] bottom-[-60px] z-50"
                                         src="../../../../images/designs/viola/flower-04.svg" alt="flower-04.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer :card="card" class="md:mt-[80px]"/>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.design {
    &.design-royal {
        .linkify {
            a {
                @apply block break-all text-[#684A66] font-bold hover:underline;
            }
        }

        p {
            @apply font-royal-body font-light text-base text-center mx-auto text-[#684A66];
        }

        .date {
            @apply block font-royal-body font-bold text-2xl text-center text-[#FFFFF7] mb-[6px];
        }

        .type {
            @apply block font-royal-body font-light text-base tracking-wider text-center text-[#E0DBE3] uppercase mb-[6px] w-[225px] flex justify-center ml-auto mr-auto;
        }

        .time {
            @apply block font-royal-body font-bold text-2xl text-center mb-[8px] text-[#FFFFF7];
        }

        .address {
            @apply block font-royal-body font-light text-sm text-center text-[#E0DBE3];
        }

        .couple {
            @apply block font-royal-display font-bold text-3xl text-center pt-[32px] pb-[90px] text-[#FF9B58];
        }

        h1 {
            @apply font-royal-display font-bold text-2xl text-[#684A66] w-[335px] mx-auto text-center;
        }

        h2 {
            @apply font-royal-display font-bold text-2xl text-center not-italic text-[#684A66];
        }

        h3 {
            @apply font-royal-body font-bold not-italic text-base text-center mb-[4px];
        }

        .guest {
            .name {
                @apply font-royal-display font-bold;
            }
        }

        .button,
        button {
            @apply bg-[#FF9B58];
        }

        .button-secondary {
            @apply bg-[#fff] text-[#5f416e] border-[#5f416e] font-black;
        }
    }
}
</style>
