<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Templates from '@/Designs/Templates.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    design: {
        type: Object,
        required: true,
    },
    card: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
    preview: {
        type: Boolean,
        default: false,
    },
});

const form = useForm({});

const submitForm = () => {
    form.post(route('app.design_invitation.next_step', {card: props.card.slug}));
};
</script>

<template>
    <AppHead title="Vorschau"/>
    <Layout :rimeless="true" class="md:bg-flower-50 md:pb-20">
        <div class="relative md:mx-auto md:bg-white md:pt-12 md:shadow-md md:rounded-lg md:mt-12">
            <div class="hidden md:block md:px-12 md:pb-12">
                <h1 class="hero hero-m">Vorschau</h1>
                <div class="mt-8 flex justify-between">
                    <div class="flex gap-2">
                        <LinkComponent :href="route('app.design_invitation.edit', { card: card.slug })"
                                       class="button button-secondary">
                            <img class="mr-2" src="../../../../images/icons/edit-2.svg" alt="edit-2.svg"/>
                            Bearbeiten
                        </LinkComponent>
                        <a :href="route('app.design_invitation.preview.demo', { card: card.slug })"
                           class="button button-primary" target="_blank">
                            <img class="mr-2" src="../../../../images/icons/eye-white.svg" alt="eye-white.svg"/>
                            Vorschau
                        </a>
                    </div>
                    <form @submit.prevent="submitForm">
                        <ButtonComponent :processing="form.processing" class="button button-secondary" type="submit">
                            {{ card.is_personalized_invitation ? 'Gäste verwalten' : 'Zum Versand' }}
                        </ButtonComponent>
                    </form>
                </div>
            </div>
            <Templates :state="card.type" :card="card" :design="design" :invitation="invitation"/>
            <ButtonGroup class="md:hidden">
                <div class="grid grid-cols-2 gap-2">
                    <form @submit.prevent="submitForm">
                        <ButtonComponent :processing="form.processing" class="button button-secondary w-full"
                                         type="submit">
                            {{ card.is_personalized_invitation ? 'Gäste verwalten' : 'Zum Versand' }}
                        </ButtonComponent>
                    </form>
                    <LinkComponent :href="route('app.design_invitation.edit', { card: card.slug })"
                                   class="button button-secondary w-full">
                        <img class="mr-2" src="../../../../images/icons/edit-2.svg" alt="edit-2.svg"/>
                        Bearbeiten
                    </LinkComponent>
                </div>
                <a :href="route('app.design_invitation.preview.demo', { card: card.slug })"
                   class="button button-primary w-full mt-2" target="_blank">
                    <img class="mr-2" src="../../../../images/icons/eye-white.svg" alt="eye-white.svg"/>
                    Vorschau
                </a>
            </ButtonGroup>
        </div>
    </Layout>
</template>
