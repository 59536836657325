<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {Link, useForm} from '@inertiajs/vue3';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";

const {__, getColorByThemeAndOption, generateRoute} = useMixin();

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    food: {
        type: Object,
        required: true,
    },
    design: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
});

const form = useForm({
    guests: props.invitation.guests,
});

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.general.food', props.invitation, {'card': props.card}), {
        preserveScroll: true,
    });
};

const feedbackMissing = computed(() => {
    let response = false;

    const array = Object.values(form.guests);
    array.forEach((guest: any) => {
        if (guest.feedback === true && guest.selected_food === null) {
            response = true;
        }
    });

    return response;
});
</script>

<template>
    <Modal>
        <template #title>
            {{ __('invitation.feedback.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4 mb-[30px]">
                    <h2 class="font-montserrat font-semibold text-base">
                        {{ __('invitation.feedback.food.headline') }}
                    </h2>

                    <div class="mt-8">
                        <template v-for="guest in form.guests" :key="guest.id">
                            <template v-if="guest.feedback === true">
                                <div class="mb-8">
                                    <div class="block mb-3 font-montserrat font-semibold text-sm text-[#303C3D]">
                                        {{ guest.name }}
                                    </div>

                                    <div>
                                        <select v-model="guest.selected_food"
                                                class="w-full font-montserrat bg-[#F2F1F0] text-[#000000] border-[#A4A9A9] rounded-t-lg text-sm py-3 focus:ring-0">
                                            <option :value="null">{{
                                                    __('invitation.feedback.food.choose_your_food')
                                                }}
                                            </option>
                                            <template v-for="item in food.data">
                                                <option :value="item">{{ item }}</option>
                                            </template>
                                        </select>

                                        <input type="text" v-model="guest.food_notice"
                                               class="w-full bg-[#F2F1F0] text-[#000000] border-[#A4A9A9] text-sm rounded-b-lg border-t-0 py-3 placeholder:text-[##919696] focus:ring-0"
                                               :placeholder="__('invitation.feedback.food.notice')"/>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

                <div class="mt-12">
                    <div v-if="$page.props.progress" class="w-full h-[3px]"
                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'backgroundWide')};`">
                        <div
                            :style="{'width': $page.props.progress + '%', 'background-color': getColorByThemeAndOption(card.theme, 'feedback')}"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5 grid grid-cols-2 gap-4">
                        <Link
                            :href="generateRoute('app.invitation.feedback.general.feedback', invitation, { 'card' : card })"
                            class="button button-secondary w-full border-none bg-[#F2F1F0] text-[#303C3D]"
                            preserve-scroll>
                            {{ __('invitation.feedback.button.back') }}
                        </Link>

                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :disabled="feedbackMissing"
                                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'feedback')};`"
                                         type="submit">
                            {{ __('invitation.feedback.button.next') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
