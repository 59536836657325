<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";
import {PageWithSharedProps} from '@/Types/inertia';

const {__, generateRoute, getColorByThemeAndOption} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({
    comment: props.invitation.comment,
});

const progress = usePage<PageWithSharedProps>().props.progress;

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.personal.thanks', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    }), {
        preserveScroll: true,
    });
};
</script>

<template>
    <Modal :disable-close="true">
        <template #title>
            {{ __('invitation.feedback.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4">
                    <h2 class="font-montserrat font-semibold text-base text-center">
                        {{ __('invitation.feedback.thanks.headline') }}
                    </h2>
                </div>

                <div class="mt-12">
                    <div v-if="progress" class="w-full h-[3px]"
                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'backgroundWide') }">
                        <div
                            :style="{ width: `${progress}%`, backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5">
                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                                         type="submit">
                            {{ __('invitation.feedback.button.close') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.25s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateY(100%);
}
</style>
