<script setup lang="ts">
import {defineProps} from 'vue';
import {useMixin} from "@/mixins";

const {__} = useMixin();

const props = defineProps({
    block: {
        type: Object as () => any,
        required: true,
    }
});

const dresscodeTexts = {
    white_tie: "invitation.dresscode.white_tie",
    black_tie: "invitation.dresscode.black_tie",
    cocktail: "invitation.dresscode.cocktail",
    casual: "invitation.dresscode.casual"
};

const dresscode = props.block.data['dresscode'];
const customText = props.block.data['text'];
const dresscodeMessage = dresscode === 'custom' ? customText : dresscodeTexts[dresscode];

</script>

<template>
    <div class="relative mx-auto text-center">
        <h3>{{ __('invitation.dresscode') }}</h3>
        <p v-html="dresscode === 'custom' ? customText : dresscodeMessage"></p>
    </div>
</template>

<style lang="scss" scoped>
div {
    @apply mx-auto text-center;
}
</style>
