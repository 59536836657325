<script setup lang="ts">
import {defineProps} from 'vue';
import {useMixin} from "@/mixins";

const {__, linkify} = useMixin();

defineProps({
    block: {
        type: Object as () => any,
        required: true,
    }
});
</script>

<template>
    <div class="relative mx-auto text-center">
        <h3>{{ __('invitation.agenda') }}</h3>
        <p v-html="linkify(block.data['text'])"></p>
    </div>
</template>

<style lang="scss" scoped>
div {
    @apply mx-auto text-center;
}
</style>
