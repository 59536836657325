<script setup lang="ts">
import {computed, onMounted, PropType, ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import Share from '@/Shared/Sharing/Share.vue';
import TabNavigation from '@/Shared/TabNavigation.vue';
import Paywall from '@/Pages/App/Shipping/Paywall.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    hasPaid: {
        type: Boolean,
        required: true,
    },
});

const form = useForm({});
const shareEnabled = ref(false);

onMounted(() => {
    shareEnabled.value = !!navigator.share;
});

const {route} = useMixin();

const link = computed(() => {
    return route('app.invitation.thank_you', {slug: props.card.slug});
});

const paywallRef = ref<InstanceType<typeof Paywall> | null>(null);
const handleClick = (event: Event) => {
    if (!props.hasPaid) {
        event.preventDefault();

        paywallRef.value.open();
    }

    return true;
};
</script>

<template>
    <AppHead title="Versand"/>
    <layout class="bg-flower-50">

        <TabNavigation :card="props.card" current-route="shipping"/>

        <div class="md:w-[525px] md:mx-auto">
            <div class="grid grid-cols-1 gap-3 mt-3 md:mt-6">
                <div class="bg-white rounded-lg p-4 shadow-md">
                    <h1 class="hero hero-s">Einladungslink versenden</h1>

                    <p class="body body-m mb-6">
                        Über die unten aufgelisteten <strong>Buttons</strong> könnt Ihr den Einladungslink zu Eurer
                        Hochzeit für
                        Eure Gäste über verschiedene Kanäle (WhatsApp, Telegram, E-Mail, ...) versenden.
                    </p>

                    <div class="flex justify-between">
                        <Share :card="props.card" :has-paid="props.hasPaid" :link="link" type="Mail"/>
                        <Share :card="props.card" :has-paid="props.hasPaid" :link="link" type="Link"/>
                        <Share :card="props.card" :has-paid="props.hasPaid" :link="link" type="WhatsApp"/>
                        <Share :card="props.card" :has-paid="props.hasPaid" :link="link" type="Share"
                               v-if="shareEnabled"/>
                    </div>

                    <div class="w-full bg-gray p-2 mt-6 rounded-lg text-sm text-raven-700 flex items-center">
                        <img class="mr-2" src="../../../../images/icons/eye.svg"/>

                        <a :class="{'blur-xs' : !props.hasPaid}" :href="link" class="underline truncate" target="_blank"
                           @click="handleClick">
                            {{ link }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <Paywall ref="paywallRef" :card="props.card"/>
    </layout>
</template>
