<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import ConfirmationDialog from "@/Shared/ConfirmationDialog.vue";
import {PropType, ref} from 'vue';
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    block: {
        type: Object,
        required: true,
    },
    exists: {
        type: Boolean,
        required: true,
    }
});

const form = useForm({
    block: {
        images: props.block.images || [],
    },
});

const previews = ref(props.block.images || []);

const requestUploadFile = () => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024; // 5 MB in Bytes

    for (let i = 0; i < fileInput.value.files.length; i++) {
        const file = fileInput.value.files[i];

        if (!allowedTypes.includes(file.type)) {
            form.errors['block.images'] = 'Bitte lade nur Bilder im Format JPG, PNG oder GIF hoch.';
            return;
        }

        if (file.size > maxSize) {
            form.errors['block.images'] = 'Die Datei darf nicht größer als 5 MB sein.';
            return;
        }

        previews.value.push(URL.createObjectURL(file));
        form.block.images.push(file);
    }
};

const deleteImage = (key: number) => {
    form.block.images.splice(key, 1);
    previews.value.splice(key, 1);
};

const fileInput = ref<HTMLInputElement | null>(null);

const deleteDialogRef = ref<InstanceType<typeof ConfirmationDialog>>(null);

const triggerDialog = () => {
    deleteDialogRef.value.open();
};
</script>

<template>
    <AppHead title="Bildergalerie"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Bildergalerie</h1>

                <p class="body body-m">
                    Füge deiner Danksagung eine Bildergalerie hinzu und verleihe deiner Einladung eine persönliche Note.
                </p>

                <form class="mt-6"
                      @submit.prevent="form.post(route('app.design_invitation.edit.blocks.gallery', { card: card.slug }))">
                    <input type="file" id="file" ref="fileInput" @change="requestUploadFile" class="hidden" multiple/>
                    <ButtonComponent class="button button-secondary w-full" type="button" @click="fileInput.click();">
                        Bilder hinzufügen
                    </ButtonComponent>
                    <span v-if="form.errors['block.images']" class="body body-sm block mt-1 text-error">
                        {{ form.errors['block.images'] }}
                    </span>

                    <template v-for="(image, key) in form.block.images">
                        <div v-if="form.errors['block.images.' + key]" class="body body-sm block mt-1 text-error">
                            {{ form.errors['block.images.' + key] }}
                        </div>
                    </template>

                    <template v-for="(image, key) in previews">
                        <div class="relative">
                            <img :src="image" class="mt-6 w-[300px] mx-auto" alt="gallery-photo"/>
                            <div
                                class="button button-primary py-2 text-sm absolute left-1/2 transform -translate-x-1/2 bottom-2"
                                @click="deleteImage(key)">
                                Bild entfernen
                            </div>
                        </div>
                    </template>

                    <template v-if="exists">
                        <ConfirmationDialog ref="deleteDialogRef">
                            <template v-slot:description>
                                <p class="body body-m mb-6">
                                    Falls Ihr Eure Meinung später ändern solltet, könnt Ihr dieses Element auch wieder
                                    aktivieren.
                                </p>
                            </template>
                            <template v-slot:button>
                                <form class="w-full"
                                      @submit.prevent="form.post(route('app.design_invitation.edit.blocks.gallery.delete', { card: card.slug }))">
                                    <ButtonComponent :processing="form.processing"
                                                     class="button button-primary w-full h-full" type="submit">
                                        Bestätigen
                                    </ButtonComponent>
                                </form>
                            </template>
                        </ConfirmationDialog>

                        <button class="hide-block text-base font-bold text-flower-500 mt-8" type="button"
                                @click="triggerDialog">
                            Element entfernen
                        </button>
                    </template>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            {{ exists ? 'Speichern' : 'Hinzufügen' }}
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
