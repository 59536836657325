<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import {Link, useForm} from '@inertiajs/vue3';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";

const {getColorByThemeAndOption, generateRoute, __} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({
    name: null,
});

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.personal.add_guest', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    }), {
        preserveScroll: true,
    });
};
</script>


<template>
    <Modal>
        <template #title>
            {{ __('invitation.feedback.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4">
                    <h2 class="font-montserrat font-semibold text-base">
                        {{ __('invitation.feedback.add_another_guest') }}
                    </h2>

                    <div class="my-8">
                        <input type="text" v-model="form.name"
                               class="font-montserrat font-medium rounded-lg w-full bg-[#F2F1F0] text-[#000000] border-[#A4A9A9] py-3 text-sm placeholder:text-[##919696] focus:ring-[#000000]"
                               :placeholder="__('invitation.feedback.enter_your_name')"/>
                    </div>
                </div>

                <div class="mt-8">
                    <div v-if="$page.props.progress" class="w-full h-[3px]"
                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'backgroundWide')};`">
                        <div
                            :style="{'width': $page.props.progress + '%', 'background-color': getColorByThemeAndOption(card.theme, 'feedback')}"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5 grid grid-cols-2 gap-4">

                        <Link
                            :href="generateRoute('app.invitation.feedback.personal.feedback', props.invitation, {
                                'card': props.card,
                                'invitation': props.invitation.slug
                            })"
                            class="button button-secondary w-full border-none bg-[#F2F1F0] text-[#303C3D]"
                            preserve-scroll>
                            {{ __('invitation.feedback.button.back') }}
                        </Link>

                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :disabled="!form.name"
                                         :style="`background-color: ${getColorByThemeAndOption(card.theme, 'feedback')};`"
                                         type="submit">
                            {{ __('invitation.feedback.button.save') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
