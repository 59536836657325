<template>
    <div>
        <div
            v-if="version === 'detailed'"
            :class="{ 'border-2 bg-flower-50 border-flower-100' : shouldBeChecked, 'border-white border-2': !shouldBeChecked, 'opacity-75' : disabled }"
            class="flex items-center rounded-lg py-3 px-2">
            <input :id="id" :checked="shouldBeChecked" :disabled="disabled" :name="name"
                   :value="value"
                   class="border-3 border-raven-700 mr-2 mt-1 self-start focus:ring-0 text-flower-500 focus:border-flower-500 focus:ring-flower-500"
                   type="checkbox"
                   v-bind="$attrs"
                   @change="updateInput"
            />

            <label :class="{ 'text-error' : error, 'text-raven-500' : disabled }" :for="id"
                   class="headline headline-m cursor-pointer mb-0">
                <slot name="label"/>

                <p v-if="this.$slots.description"
                   :class="{ 'text-flower-700' : shouldBeChecked, 'text-raven-500' : disabled}"
                   class="body body-s mt-1 mb-0">
                    <slot name="description"/>
                </p>
            </label>
        </div>

        <div v-if="version === 'simple'">
            <div class="flex items-center rounded-lg">
                <input :id="id" :checked="shouldBeChecked" :name="name" :value="value"
                       class="text-flower-500 border-3 border-raven-700 mr-2 mt-1 self-center focus:ring-0 checked:border-flower-500"
                       type="radio"
                       @change="updateInput"
                />

                <label :class="{'text-error': error}" :for="id" class="body body-s cursor-pointer">
                    <slot/>
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        name: {
            type: String,
        },
        id: {
            type: String,
        },
        error: {
            type: String,
        },
        value: {
            type: [String, Boolean],
        },
        modelValue: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        version: {
            type: String,
            default: 'detailed',
        }
    },
    emits: ['onUpdateModelValue'],
    computed: {
        shouldBeChecked() {
            return this.modelValue[this.value] !== undefined;
        }
    },
    methods: {
        updateInput() {
            this.$emit('onUpdateModelValue', this.value);
        }
    }
}
</script>
