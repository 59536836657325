<script setup lang="ts">
import {Link} from '@inertiajs/vue3';
import {computed, defineProps, ref} from 'vue';

const props = defineProps({
    class: String,
    href: {
        type: String,
        required: true,
    },
    asLink: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const processing = ref(false);

const start = () => {
    processing.value = true;
    setTimeout(() => {
        processing.value = false;
    }, 500);
};

const buttonClass = computed(() => {
    return props.class;
});
</script>

<template>
    <template v-if="disabled">
        <a :class="buttonClass" class="button button-primary text-raven-500 bg-gray" href="#" v-bind="$attrs">
            <slot/>
        </a>
    </template>

    <template v-else>
        <Link v-if="!asLink" :class="buttonClass" :href="href" class="relative" v-bind="$attrs" @click="start">
      <span v-if="processing && buttonClass.includes('button-primary')"
            class="animate-spin h-3 w-3 text-white absolute left-auto right-auto">
        <svg class="h-3 w-3" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"></path>
        </svg>
      </span>
            <span v-else-if="processing && buttonClass.includes('button-secondary')"
                  class="animate-spin h-3 w-3 text-white absolute left-auto right-auto">
        <svg class="h-3 w-3" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b" stroke-width="4"></circle>
          <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="#893e3e"></path>
        </svg>
      </span>
            <span :class="{ 'invisible': processing }" class="inline-flex">
        <slot/>
      </span>
        </Link>

        <a v-else :class="buttonClass" :href="href" class="relative" target="_blank" v-bind="$attrs" @click="start">
      <span v-if="processing && buttonClass.includes('button-primary')"
            class="animate-spin h-3 w-3 text-white absolute left-auto right-auto">
        <svg class="h-3 w-3" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"></path>
        </svg>
      </span>
            <span v-else-if="processing && buttonClass.includes('button-secondary')"
                  class="animate-spin h-3 w-3 text-white absolute left-auto right-auto">
        <svg class="h-3 w-3" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b" stroke-width="4"></circle>
          <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="#893e3e"></path>
        </svg>
      </span>
            <span :class="{ 'invisible': processing }" class="inline-flex">
        <slot/>
      </span>
        </a>
    </template>
</template>
