<script setup lang="ts">
import {computed, defineEmits, defineProps} from 'vue';

const props = defineProps({
    name: String,
    id: String,
    value: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
    error: String,
});

const emits = defineEmits(['onUpdateModelValue']);

const shouldBeChecked = computed(() => props.modelValue === props.value);

const handleInputChange = () => {
    emits('onUpdateModelValue', props.value);
};
</script>

<template>
    <div class="flex items-center rounded-lg">
        <input :id="id" :checked="shouldBeChecked" :name="name" :value="value"
               class="text-flower-500 border-3 border-raven-700 mr-2 mt-1 self-center focus:ring-0 checked:border-flower-500"
               type="checkbox"
               @change="handleInputChange"
        />

        <label :class="{ 'text-error': error }" :for="id" class="body body-s cursor-pointer">
            <slot/>
        </label>
    </div>
</template>
