<script setup lang="ts">
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import AppHead from '@/Shared/AppHead.vue';
import Layout from '@/Layouts/Default.vue';
import BackLink from '@/Shared/BackLink.vue';
import Card from '@/Shared/Card.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import RadioButtonComponent from '@/Shared/Forms/RadioButtonComponent.vue';
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object,
        required: true,
    },
    invitation: {
        type: Object,
        required: true,
    },
    foods: {
        type: Array,
        required: false,
    },
});

const form = useForm({
    guests: props.invitation.guests,
});

const giveFeedback = (index: number, feedback: boolean | null) => {
    form.guests[index].feedback = feedback;
    if (!feedback) {
        form.guests[index].selected_food = null;
    }
};

const selectFood = (index: number, food: string) => {
    form.guests[index].selected_food = food;
};

const submitForm = () => {
    form.post(route('app.feedback.overwrite', {card: props.card.slug, invitation: props.invitation.slug}));
};
</script>

<template>
    <AppHead title="Rückmeldung anpassen"/>
    <Layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.feedback.show', { card: card.slug, invitation: invitation.slug })">
                    Zurück
                </BackLink>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Rückmeldung anpassen</h1>
                <p class="body body-m mb-6">
                    Hier könnt Ihr die Rückmeldungen (Zu- bzw. Absage oder auch Menüauswahl) für Eure Gäste dieser
                    Einladung anpassen.
                </p>

                <form @submit.prevent="submitForm">
          <span v-if="Object.keys(form.errors).length !== 0" class="body body-m block text-error mb-6">
            Fehler! Du hast eine Angabe vergessen. Bitte prüfe Eure Eingabe noch einmal.
          </span>

                    <div v-for="(guest, index) in form.guests" :key="index" class="mb-6">
                        <h2 class="headline headline-l">{{ guest.name }}</h2>
                        <div class="mt-3">
                            <RadioButtonComponent
                                :id="`guests_${index}_feedback_null`"
                                :error="form.errors[`guests.${index}.feedback`]"
                                :model-value="guest.feedback"
                                :name="`guests_${index}_feedback`"
                                :value="null"
                                @onUpdateModelValue="giveFeedback(index, null)"
                            >
                                <template v-slot:label>Antwort ausstehend</template>
                            </RadioButtonComponent>

                            <RadioButtonComponent
                                :id="`guests_${index}_feedback_yes`"
                                :error="form.errors[`guests.${index}.feedback`]"
                                :model-value="guest.feedback"
                                :name="`guests_${index}_feedback`"
                                :value="true"
                                @onUpdateModelValue="giveFeedback(index, true)"
                            >
                                <template v-slot:label>Zusage</template>
                            </RadioButtonComponent>

                            <RadioButtonComponent
                                :id="`guests_${index}_feedback_no`"
                                :error="form.errors[`guests.${index}.feedback`]"
                                :model-value="guest.feedback"
                                :name="`guests_${index}_feedback`"
                                :value="false"
                                @onUpdateModelValue="giveFeedback(index, false)"
                            >
                                <template v-slot:label>Absage</template>
                            </RadioButtonComponent>
                        </div>

                        <div v-show="card.has_food_block && guest.feedback">
                            <hr class="w-full h-[1px] bg-gray border-0 my-4"/>
                            <h3 class="headline headline-s text-raven-700 ml-2">Menüauswahl</h3>
                            <div v-for="(food, foodIndex) in foods" :key="foodIndex">
                                <RadioButtonComponent
                                    :id="`guests_${index}_food_${foodIndex}`"
                                    :error="form.errors[`guests.${index}.selected_food`]"
                                    :model-value="form.guests[index].selected_food"
                                    :name="`guests_${index}_food_${foodIndex}`"
                                    :value="food"
                                    @onUpdateModelValue="selectFood(index, food)"
                                >
                                    <template v-slot:label>{{ food }}</template>
                                </RadioButtonComponent>
                            </div>
                        </div>
                    </div>

                    <ButtonGroup class="grid grid-cols-1 md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Rückmeldung überschreiben
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </Layout>
</template>
