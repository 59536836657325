<script setup lang="ts">
import {useForm} from '@inertiajs/vue3';
import Layout from "@/Layouts/Default.vue";
import RadioButtonComponent from "@/Shared/Forms/RadioButtonComponent.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import BackLink from "@/Shared/BackLink.vue";
import AppHead from "@/Shared/AppHead.vue";
import {PropType} from "vue";
import {useMixin} from "@/mixins";

const {route, __} = useMixin();

defineProps({
    cards: {
        type: Object as PropType<Array<App.Models.Card>>,
        required: true,
    },
});

const form = useForm({
    card: null,
});

function updateCard(card: string) {
    form.card = card;
}
</script>

<template>
    <AppHead title="Welche Einladung möchtest du duplizieren?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.dashboard.card.new_or_duplicate')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Welche Einladung möchtest du duplizieren?</h1>

                <form class="mt-6" @submit.prevent="form.post(route('app.dashboard.card.duplicate'))">
                    <RadioButtonComponent v-for="card in cards" :key="card.id" :id="`card_${card.id}`"
                                          :error="form.errors.card"
                                          :model-value="form.card" :value="String(card.id)" name="card"
                                          @onUpdateModelValue="updateCard">
                        <template v-slot:label>{{ card.name }}</template>
                        <template v-slot:description>
                            <template v-if="card.is_an_invitation_card">
                                <span>Einladungskarte</span>
                            </template>
                            <template v-if="card.is_an_invitation_card">
                                <template v-if="card.wedding_schedules.length">,</template>
                                <span>
                  {{
                                        card.wedding_schedules.map(schedule => __('invitation.wedding_schedule.' + schedule.type)).join(', ')
                                    }}
                </span>
                            </template>
                        </template>
                    </RadioButtonComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Duplizieren
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
