<script setup lang="ts">
import {computed, defineProps, PropType} from 'vue';
import {Link, useForm, usePage} from '@inertiajs/vue3';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Modal from '@/Pages/App/Exposed/Feedback/Modal.vue';
import {useMixin} from "@/mixins";
import {PageWithSharedProps} from '@/Types/inertia';

const {__, generateRoute, getColorByThemeAndOption} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    food: {
        type: Object as PropType<App.Models.Card.Block>,
        required: true,
    },
    design: {
        type: Object as PropType<App.Models.Design>,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
        required: true,
    },
});

const form = useForm({
    guests: props.invitation.guests,
});

const progress = usePage<PageWithSharedProps>().props.progress;

const guestsWithFeedback = computed(() => {
    return form.guests.filter(guest => guest.feedback === true);
});

const feedbackMissing = computed(() => {
    return guestsWithFeedback.value.some(guest => guest.selected_food === null);
});

const submitForm = () => {
    form.post(generateRoute('app.invitation.feedback.personal.food', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    }), {
        preserveScroll: true,
    });
};

const generateFeedbackRoute = () => {
    return generateRoute('app.invitation.feedback.personal.feedback', props.invitation, {
        'card': props.card,
        'invitation': props.invitation.slug
    });
};
</script>

<template>
    <Modal>
        <template #title>
            {{ __('invitation.feedback.food.headline') }}
        </template>

        <template #default>
            <form @submit.prevent="submitForm">
                <div class="px-4 mb-[30px]">
                    <h2 class="font-montserrat font-semibold text-base">
                        {{ __('invitation.feedback.food.headline') }}
                    </h2>

                    <div class="mt-8">
                        <template v-for="(guest, key) in guestsWithFeedback">
                            <div v-if="guest.feedback === true" class="mb-8">
                                <div class="block mb-3 font-montserrat font-semibold text-sm text-[#303C3D]">
                                    {{ guest.name }}
                                </div>

                                <div>
                                    <select v-model="guest.selected_food"
                                            class="w-full font-montserrat bg-[#F2F1F0] text-[#000000] border-[#A4A9A9] rounded-t-lg text-sm py-3 focus:ring-0">
                                        <option :value="null">{{
                                                __('invitation.feedback.food.choose_your_food')
                                            }}
                                        </option>
                                        <template v-for="item in food.data">
                                            <option :value="item">{{ item }}</option>
                                        </template>
                                    </select>

                                    <input v-model="guest.food_notice"
                                           type="text"
                                           class="w-full bg-[#F2F1F0] text-[#000000] border-[#A4A9A9] text-sm rounded-b-lg border-t-0 py-3 placeholder:text-[##919696] focus:ring-0"
                                           :placeholder="__('invitation.feedback.food.notice')"/>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="mt-12">
                    <div v-if="progress" class="w-full h-[3px]"
                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'backgroundWide') }">
                        <div
                            :style="{ width: `${progress}%`, backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                            class="h-full"></div>
                    </div>

                    <div class="px-4 py-5 grid grid-cols-2 gap-4">
                        <Link :href="generateFeedbackRoute()"
                              class="button button-secondary w-full border-none bg-[#F2F1F0] text-[#303C3D]"
                              preserve-scroll>
                            {{ __('invitation.feedback.button.back') }}
                        </Link>

                        <ButtonComponent :processing="form.processing"
                                         class="button button-primary w-full disabled:bg-[#D9D9D9]"
                                         :disabled="feedbackMissing"
                                         :style="{ backgroundColor: getColorByThemeAndOption(card.theme, 'feedback') }"
                                         type="submit">
                            {{ __('invitation.feedback.button.next') }}
                        </ButtonComponent>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>
