<script setup lang="ts">
import Layout from "@/Layouts/Default.vue";
import {useForm} from '@inertiajs/vue3';
import BackLink from "@/Shared/BackLink.vue";
import ButtonComponent from "@/Shared/Forms/ButtonComponent.vue";
import Card from "@/Shared/Card.vue";
import ButtonGroup from "@/Shared/Forms/ButtonGroup.vue";
import TipTap from "@/Shared/TipTap.vue";
import {onUpdated, PropType} from "vue";
import AppHead from "@/Shared/AppHead.vue";
import {useMixin} from "@/mixins";

const {route} = useMixin();

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    feedbackText: {
        type: String,
        required: true,
    },
    suggestion: {
        type: String,
    },
});

const form = useForm({
    feedback_text: props.feedbackText,
});

const textSuggestionForm = useForm({
    component: 'feedback.text',
    base_text: props.feedbackText,
});

const updateText = (text: string) => {
    form.feedback_text = text;
};

onUpdated(() => {
    if (props.suggestion) {
        updateText(props.suggestion);
    }
});
</script>

<template>
    <AppHead title="Planungshinweis bearbeiten"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.design_invitation.edit', { card: card.slug })"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Planungshinweis bearbeiten</h1>

                <form class="mt-6"
                      @submit.prevent="form.post(route('app.design_invitation.edit.feedback.text', { card: card.slug }))">

                    <div class="flex justify-end mb-4">
                        <form
                            @submit.prevent="textSuggestionForm.post(route('app.design_invitation.edit.feedback.text.text_suggestions.suggestions', { card: card }))">
                            <ButtonComponent :processing="textSuggestionForm.processing"
                                             class="w-auto button button-primary"
                                             type="submit">
                                <div class="flex flex-row">
                                    <img src="../../../../../images/icons/type.svg" class="mr-2" alt="type.svg"/>
                                    Textvorschläge generieren (5s)
                                </div>
                            </ButtonComponent>
                        </form>
                    </div>

                    <TipTap name="feedback_text" :error="form.errors.feedback_text" :model-value="form.feedback_text"
                            @onUpdateModelValue="updateText"/>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Speichern
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
