<script setup lang="ts">
import {defineProps, PropType} from 'vue';
import Layout from '@/Layouts/Default.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import TabNavigation from '@/Shared/TabNavigation.vue';
import GuestListInvitationCard from '@/Pages/App/GuestList/InvitationCard.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    invitations: {
        type: Array as PropType<Array<App.Models.Invitation>>,
        required: true,
    },
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Gäste"/>
    <layout class="bg-flower-50">
        <TabNavigation :card="props.card" current-route="guest_list"/>

        <div v-if="!props.invitations.length" class="md:w-[525px] md:mx-auto">
            <div class="bg-white rounded-lg p-4 shadow-md py-8 px-6">
                <img class="mx-auto mb-8" src="../../../images/invitations-empty-state.svg"/>
                <div>
                    <h2 class="headline headline-xl text-center">
                        Bisher ist Eure Gästeliste leer.
                    </h2>
                </div>
                <div class="w-full flex justify-center mt-4">
                    <LinkComponent :href="route('app.guest_list.create', { card: props.card.slug })"
                                   class="button button-primary">
                        Erste Gruppe erstellen
                    </LinkComponent>
                </div>
            </div>
        </div>

        <div v-if="props.invitations.length" class="md:w-[525px] md:mx-auto">
            <div class="grid grid-cols-1 gap-3 mt-3 md:mt-6">
                <GuestListInvitationCard v-for="invitation in props.invitations" :key="invitation.id" :card="props.card"
                                         :invitation="invitation"/>
            </div>

            <ButtonGroup class="md:mt-10">
                <LinkComponent :href="route('app.guest_list.create', { card: props.card.slug })"
                               class="button button-primary w-full">
                    Gruppe hinzufügen
                </LinkComponent>
            </ButtonGroup>
        </div>
    </layout>
</template>
