<script setup lang="ts">
import {ref} from 'vue';

defineProps({
    ref: {
        type: String,
    }
});

const isVisible = ref(false);
const processing = ref(false);

const open = () => {
    isVisible.value = true;
};

const close = () => {
    processing.value = true;
    setTimeout(() => {
        isVisible.value = false;
        processing.value = false;
    }, 100);
};

defineExpose({
    open
});
</script>

<template>
    <div>
        <transition name="fade">
            <div v-if="isVisible" class="fixed inset-0 z-100 flex justify-center items-center">
                <div class="fixed inset-0 z-75 bg-black opacity-60"/>
                <div class="fixed z-100 flex flex-col items-center justify-center h-full md:left-1/2 md:ml-[-215px]">
                    <div class="bg-white py-8 px-6 mx-4 rounded-lg md:w-[400px]">
                        <h2 class="headline headline-xl">
                            <slot name="title">
                                Seid Ihr Euch sicher?
                            </slot>
                        </h2>

                        <p class="body body-m mb-6">
                            <slot name="description">
                                Bitte beachtet, dass diese Aktion nicht mehr rückgängig gemacht werden kann. Ihr löscht
                                die Daten unwiderruflich.
                            </slot>
                        </p>

                        <div class="w-full grid grid-cols-2 gap-2">
                            <button class="button button-secondary" type="button" @click="close">
                                <svg v-if="processing"
                                     class="animate-spin h-3 w-3 text-white absolute left-auto right-auto"
                                     fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#4b1b1b"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="#893e3e"></path>
                                </svg>
                                <span :class="{ 'invisible' : processing }">
                                    Abbrechen
                                </span>
                            </button>

                            <slot name="button"/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
