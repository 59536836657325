<script setup lang="ts">
import {reactive} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import InputComponent from '@/Shared/Forms/InputComponent.vue';
import BackLink from '@/Shared/BackLink.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import Card from '@/Shared/Card.vue';
import ButtonGroup from '@/Shared/Forms/ButtonGroup.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    ceremony_date: {
        type: String,
    },
});

// Form setup
const form = reactive(useForm({
    ceremony_date: props.ceremony_date,
}));

const {route} = useMixin();

const submitForm = () => {
    form.post(route('app.onboarding.wedding_date'));
};
</script>

<template>
    <AppHead title="Wann wollt Ihr heiraten?"/>
    <layout class="md:bg-flower-50">
        <Card class="md:w-[525px]">
            <template v-slot:outer>
                <BackLink :href="route('app.onboarding.bridal_couple')"/>
            </template>

            <template v-slot:inner>
                <h1 class="hero hero-s">Wann wollt Ihr heiraten?</h1>

                <form class="mt-6" @submit.prevent="submitForm">
                    <InputComponent v-model:value="form.ceremony_date" :error="form.errors.ceremony_date"
                                    name="ceremony_date"
                                    type="date">
                        Datum
                    </InputComponent>

                    <ButtonGroup class="md:mt-10">
                        <ButtonComponent :processing="form.processing" class="button button-primary w-full"
                                         type="submit">
                            Weiter
                        </ButtonComponent>
                    </ButtonGroup>
                </form>
            </template>
        </Card>
    </layout>
</template>
