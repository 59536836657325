<script setup lang="ts">
import {onMounted, PropType} from 'vue';
import Layout from '@/Layouts/Default.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import IconLink from '@/Shared/IconLink.vue';
import TabNavigation from '@/Shared/TabNavigation.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    invitations: {
        type: Array as PropType<Array<App.Models.Invitation>>,
        required: true,
    },
    invitationsSent: {
        type: Array as PropType<Array<App.Models.Invitation>>,
        required: true,
    },
    sendConversionOnce: {
        type: Boolean,
        default: false,
    },
    amountTotal: {
        type: Number,
        default: 0,
    },
});

onMounted(() => {
    if (props.sendConversionOnce && localStorage.getItem('event.purchase') !== 'true') {
        localStorage.setItem('event.purchase', 'true');
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'purchase',
                amount_total: props.amountTotal,
            });
        }
    }
});

const {route} = useMixin();
</script>

<template>
    <AppHead title="Versand"/>
    <layout class="bg-flower-50">
        <TabNavigation :card="props.card" current-route="shipping"/>

        <div v-if="!props.invitations.length && !props.invitationsSent.length" class="md:w-[525px] md:mx-auto">
            <div class="rounded-lg p-4 shadow-md bg-white py-8 px-6 mt-3">
                <img class="mx-auto mb-8" src="../../../../images/invitations-empty-state.svg"/>
                <div>
                    <h2 class="headline headline-xl text-center">
                        Bisher ist Eure Gästeliste leer.
                    </h2>
                </div>
                <div class="w-full flex justify-center mt-4">
                    <LinkComponent :href="route('app.guest_list.create', { card: props.card.slug })"
                                   class="button button-primary">
                        Erste Gruppe erstellen
                    </LinkComponent>
                </div>
            </div>
        </div>

        <div v-if="props.invitations.length" class="grid grid-cols-1 gap-3 md:w-[525px] md:mx-auto">
            <div v-for="(invitation, index) in props.invitations"
                 class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center">
                <ul class="m-0 w-full">
                    <li v-for="guest in invitation.guests.filter(x => !x.is_additional_guest)"
                        class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                        {{ guest.name }}
                    </li>
                    <li v-if="invitation.additional_guests"
                        class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                        ggf. Begleitung
                    </li>
                </ul>

                <div class="inline-flex ml-8">
                    <IconLink
                        :href="route('app.shipping.invitation.mark_invitation_as_sent', { card: props.card.slug, invitation : invitation.slug })"
                        class="w-[24px] h-[24px]">
                        <template v-slot:icon>
                            <img src="../../../../images/icons/mail.svg"/>
                        </template>
                    </IconLink>
                </div>
            </div>
        </div>

        <template v-if="props.invitationsSent.length">
            <div :class="{ 'mt-4' : !props.invitations.length }" class="mb-4 mt-8">
                <hr v-if="props.invitations.length > 0" class="w-full h-[2px] bg-flower-100 mb-6 border-0"/>
                <h2 class="font-body font-bold text-center text-base text-[#6f7f9f]">
                    {{ props.invitations.length ? 'Bereits versendete Einladungen' : 'Alle Einladungen versendet' }}
                </h2>
            </div>

            <div class="grid grid-cols-1 gap-3 md:w-[525px] md:mx-auto">
                <div v-for="(invitation, index) in props.invitationsSent"
                     class="bg-white rounded-lg p-4 shadow-md flex flex-nowrap items-center">
                    <ul class="m-0 w-full mr-8">
                        <li v-for="guest in invitation.guests.filter(x => !x.is_additional_guest)"
                            class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            {{ guest.name }}
                        </li>
                        <li v-if="invitation.additional_guests"
                            class="headline headline-s pb-3 mb-3 px-1 list-none border-b-2 border-flower-100 last:pb-0 last:mb-0 last:border-0">
                            ggf. Begleitung
                        </li>
                    </ul>

                    <IconLink
                        :href="route('app.shipping.invitation.mark_invitation_as_sent', { card: props.card.slug, invitation : invitation.slug })"
                        class="w-[32px]">
                        <template v-slot:icon>
                            <img class="w-[32px]" src="../../../../images/icons/mail--checked.svg"/>
                        </template>
                    </IconLink>
                </div>
            </div>
        </template>
    </layout>
</template>
